import { Card, CardActions, CardContent, CardHeader, Chip, Typography } from "@mui/material";
import React from "react";
import { User } from "../api/interfaces";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import ConfirmButton from "./ConfirmButton";

interface Props {
    user: User;
    deleteButtonText?: string;
    onDelete?: () => void;
}

export default function UserCard(props: Props) {
    const navigate = useNavigate();

    return (
        <Card
            sx={{ height: "100%", cursor: "pointer" }}
            onClick={() => {
                navigate(`/users/${props.user.id}`);
            }}
        >
            <CardHeader
                title={props.user.name}
                subheader={
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "5px",
                        }}
                    >
                        {props.user.admin && <Chip size="small" color="primary" label="admin" />}
                        {!props.user.active && <Chip size="small" color="secondary" label="Descativado" />}
                    </Box>
                }
            />
            <CardContent>
                <Typography variant="body1">{props.user.email}</Typography>
            </CardContent>

            {props.onDelete && (
                <CardActions>
                    <React.Fragment>
                        <ConfirmButton
                            onDelete={() => {
                                props.onDelete!();
                            }}
                        >
                            {props.deleteButtonText || "Quitar"}
                        </ConfirmButton>
                    </React.Fragment>
                </CardActions>
            )}
        </Card>
    );
}
