import { Alert, Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../api/client";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import { useAuth } from "../UseAuth";
import { User } from "../api/interfaces";

export default function UpdateLogo() {
    const auth = useAuth()!;
    const [logo, setLogo] = useState<Blob | null>(null);

    useEffect(() => {
        if (auth.user) {
            api.download_logo()
                .then((blob) => {
                    setLogo(blob);
                })
                .catch(() => {});
        }
    }, [auth.user]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={`Subir logo de la empresa`}
                            titleTypographyProps={{ variant: "h3" }}
                        ></CardHeader>
                        <CardContent>
                            <Alert severity="info" sx={{ marginBottom: "1rem" }}>
                                El logo no debería contener márgenes en blanco y tener un fondo transparente.
                            </Alert>
                            <CustomForm
                                fields={{
                                    logo: {
                                        type: CustomFieldType.File,
                                        label: "Imagen PNG*",
                                        accept: "image/png",
                                    },
                                }}
                                initialValues={{
                                    logo: null as File | null,
                                }}
                                schema={Yup.object().shape({
                                    logo: Yup.mixed().nullable().required("Requerido"),
                                })}
                                submitText="Subir"
                                onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                    api.upload_logo(values as any)
                                        .then(
                                            () => {
                                                resetForm();
                                                setMessage({
                                                    message: "Logo actualizado",
                                                    type: "success",
                                                });
                                                api.download_logo()
                                                    .then((blob) => {
                                                        setLogo(blob);
                                                    })
                                                    .catch(() => {});
                                            },
                                            (err) => {
                                                if (err.field_errors !== null) {
                                                    api.parse_field_errors(err.field_errors, setFieldError);
                                                }
                                                setMessage({
                                                    message: err.error,
                                                    type: "error",
                                                });
                                            },
                                        )
                                        .finally(() => setSubmitting(false));
                                }}
                            />
                            {!logo && (
                                <Alert severity="warning" sx={{ marginTop: 2 }}>
                                    No hay un logo subido actualmente.
                                </Alert>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                {logo && (
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4" gutterBottom>
                                    Logo actual
                                </Typography>

                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                height: "100%",
                                            }}
                                        >
                                            <img style={{ width: "100%" }} src={URL.createObjectURL(logo)}></img>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
