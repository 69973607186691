import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Link as RouterLink } from "react-router-dom";
import {
    Badge,
    Box,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Popover,
    Stack,
    SwipeableDrawer,
    Typography,
    Link,
} from "@mui/material";
import { ListSubheader } from "@mui/material";
import { User, UserNotification } from "../api/interfaces";
import api, { QUERY_LIMIT } from "../api/client";
import logoBlue from "../images/logo_blue.webp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { grey } from "@mui/material/colors";
import { isDesktop } from "react-device-detect";
import { useAuth } from "../UseAuth";

interface NavBarState {
    drawerOpen: boolean;
    menuAnchor: null | HTMLElement;
}

interface ListItem {
    name: string;
    link: string;
}

export default function NavBar() {
    const auth = useAuth()!;
    // Correct only if isSuperUser is false.
    const user = auth.user as User | null;

    const [state, setState] = React.useState<NavBarState>({
        drawerOpen: false,
        menuAnchor: null,
    });
    //const [companyLogo, setCompanyLogo] = useState<Blob | null>(null);
    const [notifMenu, setNotifMenu] = useState<HTMLElement | null>(null);
    const [notifPage, setNotifPage] = useState(0);
    const [notifications, setNotifications] = useState<UserNotification[]>([]);
    /*const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 50,
    });*/

    /*
    useEffect(() => {
        if (props.authed) {
            props.api
                .download_logo()
                .then((blob) => {
                    setCompanyLogo(blob);
                })
                .catch(() => {});
        }
    }, [props.api, props.authed]);
    */

    useEffect(() => {
        if (auth.user && !auth.isSuperUser) {
            api.get_notifications(notifPage)
                .then((res) => {
                    if (notifPage === 0) setNotifications(res.result.data);
                    else setNotifications((c) => c.concat(res.result.data));
                    // TODO: limit and timeout?
                    if (res.result.data.length >= QUERY_LIMIT) setNotifPage((p) => p + 1);
                })
                .catch(() => {});
        }
    }, [auth.isSuperUser, auth.user, notifPage]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setState({
            ...state,
            menuAnchor: event.currentTarget,
        });
    };

    const handleClickNotif = (event: React.MouseEvent<HTMLButtonElement>) => {
        setNotifMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
        setState({
            ...state,
            menuAnchor: null,
        });
    };

    const toggleDrawer = (drawerOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        setState({ ...state, drawerOpen });
    };

    const listUser = [
        {
            name: "Crear una solicitud de firma",
            link: "/sign",
        },
        {
            name: "Documentos enviados",
            link: "/docs",
        },
    ];

    const listNavbarMobile = [
        {
            name: "Inicio",
            link: "/?view=inicio",
        },
        {
            name: "Producto",
            link: "/faqs",
        },
        {
            name: "Tarifas",
            link: "/buy",
        },
        {
            name: "Nosotros",
            link: "/aboutus",
        },
        {
            name: "Contactar",
            link: "/contact",
        },
        {
            name: "Blog",
            link: "/blog",
        },
    ];

    const bottomListUser = [
        {
            name: "Configuración",
            link: "/menu/config",
        },
    ];

    const listSuperUser = [
        {
            name: "Blog",
            link: "/superuser/blog",
        },
        {
            name: "Registrar Empresa",
            link: "/superuser/register",
        },
        {
            name: "Ver empresas",
            link: "/superuser/companies",
        },
        {
            name: "Editar Precios",
            link: "/superuser/pricing",
        },
        {
            name: "Notificar usuarios",
            link: "/superuser/notifications",
        },
    ];

    const listAdmin = [
        {
            name: "Comprar Créditos",
            link: "/shop",
        },
        {
            name: "Ver Créditos",
            link: "/credits",
        },
        {
            name: "Auditoria",
            link: "/menu/audit",
        },
    ];

    let finalList: ListItem[] = [];

    if (auth.user != null) {
        if (auth.isSuperUser) finalList = listSuperUser;
        else {
            finalList = listUser;
        }
    } else {
        finalList = listNavbarMobile;
    }

    const list = () => (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <ListSubheader sx={{ display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "1rem" }}>
                <img width="70%" src={logoBlue}></img>
            </ListSubheader>

            <List>
                {finalList.map((x) => (
                    <ListItem button key={x.name} component={RouterLink} to={x.link}>
                        <ListItemText primary={x.name} />
                    </ListItem>
                ))}
            </List>
            {user && !auth.isSuperUser && (
                <React.Fragment>
                    <Divider />
                    <List>
                        {user.admin &&
                            listAdmin.map((x) => (
                                <ListItem button key={x.name} component={RouterLink} to={x.link}>
                                    <ListItemText primary={x.name} />
                                </ListItem>
                            ))}

                        {bottomListUser.map((x) => (
                            <ListItem button key={x.name} component={RouterLink} to={x.link}>
                                <ListItemText primary={x.name} />
                            </ListItem>
                        ))}
                    </List>
                </React.Fragment>
            )}
        </Box>
    );

    return (
        <Box sx={{ flexGrow: 1, marginBottom: "0rem" }}>
            <AppBar
                /*elevation={0}*/
                sx={{
                    position: "fixed" /*transform: trigger ? "translateY(-110%)" : "translateY(0)",*/,
                    /*transition: "transform .5s",*/
                    backgroundColor: "#ffffff",
                    color: (theme) => theme.palette.primary.main,
                    boxShadow: 0,
                }}
            >
                <Toolbar>
                    {(auth.user || !isDesktop) && (
                        <React.Fragment>
                            <IconButton
                                edge="start"
                                sx={{ marginRight: (theme) => theme.spacing(2) }}
                                color="inherit"
                                aria-label="menu"
                                onClick={toggleDrawer(true)}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>

                            <SwipeableDrawer
                                anchor="left"
                                open={state.drawerOpen}
                                onClose={toggleDrawer(false)}
                                onOpen={toggleDrawer(true)}
                            >
                                {list()}
                            </SwipeableDrawer>
                        </React.Fragment>
                    )}

                    <Box sx={{ flexGrow: 1, textDecoration: "none", height: 50 }}>
                        <Box
                            sx={{
                                display: "flex",
                                textDecoration: "none",
                                height: "40px",
                                cursor: "pointer",
                            }}
                        >
                            <Link component={RouterLink} to="/?view=inicio">
                                <img
                                    src={logoBlue}
                                    alt="Miray Sign"
                                    style={{ marginRight: "30px", height: "50px" }}
                                ></img>
                            </Link>
                            {isDesktop && !auth.user && (
                                <Stack direction="row" spacing={2} sx={{ height: "50px" }}>
                                    <Button component={RouterLink} to="/?view=inicio" color="inherit">
                                        <b>Inicio</b>
                                    </Button>
                                    <Button component={RouterLink} to="/faqs" color="inherit">
                                        <b>Producto</b>
                                    </Button>
                                    <Button component={RouterLink} to="/buy" color="inherit">
                                        <b>Tarifas</b>
                                    </Button>
                                    <Button component={RouterLink} to="/aboutus" color="inherit">
                                        <b>Nosotros</b>
                                    </Button>
                                    <Button component={RouterLink} to="/contact" color="inherit">
                                        <b>Contactar</b>
                                    </Button>
                                    <Button component={RouterLink} to="/blog" color="inherit">
                                        <b>Blog</b>
                                    </Button>
                                </Stack>
                            )}
                        </Box>
                    </Box>

                    {!auth.user && (
                        <Button
                            component={RouterLink}
                            to="/login"
                            color="inherit"
                            sx={{ backgroundColor: "#ff7f00", color: "#FFFFFF" }}
                        >
                            Mi cuenta
                        </Button>
                    )}
                    {auth.user && (
                        <div>
                            {!auth.isSuperUser && (
                                <IconButton
                                    size="large"
                                    aria-label="show new notifications"
                                    color="inherit"
                                    onClick={handleClickNotif}
                                >
                                    <Badge
                                        badgeContent={
                                            notifications.filter((x) => !x.seen && !x.global).length || undefined
                                        }
                                        color="error"
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            )}
                            <Button
                                color="inherit"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                            >
                                {auth.user && auth.user.name}
                            </Button>

                            <Popover
                                anchorEl={notifMenu}
                                open={Boolean(notifMenu)}
                                onClose={() => setNotifMenu(null)}
                                keepMounted
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                            >
                                <List
                                    subheader={<ListSubheader component="div">Notificaciones</ListSubheader>}
                                    sx={{
                                        width: "300px",
                                        bgcolor: "background.paper",
                                        overflow: "auto",
                                        maxHeight: "50vh",
                                    }}
                                >
                                    <ListItemButton
                                        sx={{
                                            backgroundColor: "#25398e",
                                            color: "white",
                                            ":hover": {
                                                backgroundColor: "#ee7f04",
                                                color: "black",
                                            },
                                        }}
                                        onClick={() => {
                                            api.mark_all_notification_seen().then(() => {
                                                setNotifications((notis) => {
                                                    return notis.map((y) => ({
                                                        ...y,
                                                        seen: true,
                                                    }));
                                                });
                                            });
                                        }}
                                    >
                                        <Box>
                                            <Typography>Marcar todas como leídas.</Typography>
                                        </Box>
                                    </ListItemButton>
                                    {notifications
                                        .filter((x) => !x.global)
                                        .map((x) => (
                                            <ListItemButton
                                                key={x.id}
                                                onClick={() => {
                                                    if (!x.seen)
                                                        api.mark_notification_seen(x.id).then(() => {
                                                            setNotifications((notis) => {
                                                                return notis.map((y) =>
                                                                    y.id === x.id
                                                                        ? {
                                                                              ...y,
                                                                              seen: true,
                                                                          }
                                                                        : y,
                                                                );
                                                            });
                                                        });
                                                }}
                                            >
                                                <div>
                                                    {x.seen ? (
                                                        <Typography color={grey[600]}>{x.message}</Typography>
                                                    ) : (
                                                        <Typography>{x.message}</Typography>
                                                    )}
                                                    <Typography color={grey[600]} variant="caption">
                                                        {new Date(x.created_at).toLocaleString()}
                                                    </Typography>
                                                </div>
                                            </ListItemButton>
                                        ))}
                                </List>
                            </Popover>
                            <Menu
                                anchorEl={state.menuAnchor}
                                open={Boolean(state.menuAnchor)}
                                onClose={handleMenuClose}
                                keepMounted
                            >
                                {auth.user && !auth.isSuperUser && (
                                    <MenuItem
                                        onClick={handleMenuClose}
                                        component={RouterLink}
                                        to={`/users/${auth.user.id}`}
                                    >
                                        Perfil
                                    </MenuItem>
                                )}

                                {auth.user && !auth.isSuperUser && (
                                    <MenuItem
                                        onClick={handleMenuClose}
                                        component={RouterLink}
                                        to={`/account/change_pass`}
                                    >
                                        Cambiar contraseña
                                    </MenuItem>
                                )}

                                {auth.user && !auth.isSuperUser && (
                                    <MenuItem onClick={handleMenuClose} component={RouterLink} to={`/account/config`}>
                                        Configuración
                                    </MenuItem>
                                )}

                                <MenuItem
                                    onClick={() => {
                                        auth.logout();
                                        handleMenuClose();
                                    }}
                                >
                                    Salir
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
}
