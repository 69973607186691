import { Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import * as Yup from "yup";
import { ApiError, User } from "../api/interfaces";
import api from "../api/client";
import { useAuth } from "../UseAuth";
import { Navigate } from "react-router-dom";

export default function UserConfig() {
    const auth = useAuth()!;

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    const user = auth.user as User;

    return (
        <Container>
            <Card>
                <CardContent>
                    <Typography variant="h3">Configuración de usuario</Typography>
                    <CustomForm
                        fields={{
                            active: {
                                type: CustomFieldType.Switch,
                                label: "Enviar emails no esenciales",
                            },
                        }}
                        schema={Yup.object().shape({
                            active: Yup.boolean().required("Requerido."),
                        })}
                        initialValues={{
                            active: user.send_status_email,
                        }}
                        submitText="Guardar"
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.edit_send_emails(values.active)
                                .then(
                                    () => {
                                        setMessage({
                                            message: `Configuración guardada.`,
                                            type: "success",
                                        });
                                        resetForm();
                                        setTimeout(() => {
                                            auth.updateUser();
                                        }, 1000);
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    );
}
