import {
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    CardHeader,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

type CookieRow = {
    name: string;
    purpose: string;
    domain: string;
    expires: string;
    provider: string;
};

export default function CookiePolicy() {
    const needed_cookies: CookieRow[] = [
        {
            name: "actix-session",
            purpose: "Esta cookie és utilizada para saber si el usuario ha iniciadio sesión.",
            domain: "sign.mirayconsulting.com",
            expires: "7 días",
            provider: "",
        },
        {
            name: "cookie_consent",
            purpose: "Esta cookie és utilizada para saber si se han aceptado las cookies.",
            domain: "sign.mirayconsulting.com",
            expires: "1 año",
            provider: "",
        },
    ];
    const perfomance_cookies: CookieRow[] = [];
    const directed_cookies: CookieRow[] = [];

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Helmet>
                <title>Cookies - Miray Sign</title>
                <meta name="description" content="Información sobre las cookies." />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Política de Cookies" titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                ¿Qué son las cookies?
                            </Typography>
                            <Typography variant="body1">
                                Las cookies y tecnologías similares son documentos de texto muy pequeños o fragmentos de
                                código que a menudo contienen un código de identificación único. Cuando usted visita un
                                sitio web o utiliza una aplicación móvil, una computadora le pide permiso a su
                                computadora o dispositivo móvil para guardar este archivo en su computadora o
                                dispositivo móvil y obtener acceso a la información. La información recopilada a través
                                de cookies y tecnologías similares puede incluir la fecha y hora de la visita y la forma
                                en que usted utiliza un sitio web o una aplicación móvil en particular. Las cookies
                                aseguran que podamos ver cómo se utiliza nuestro sitio web y cómo podemos mejorarlo.{" "}
                            </Typography>
                            <Typography variant="h4" gutterBottom>
                                ¿Qué tipo de cookies utilizamos?
                            </Typography>
                            <Typography variant="body1">
                                Según quien las gestiona:
                                <ul>
                                    <li>
                                        Cookies propias: Son aquellas cookies que se envían al equipo terminal del
                                        usuario desde un equipo o dominio gestionado por el propio editor y desde el que
                                        se presta el servicio solicitado por el usuario.
                                    </li>
                                    <li>
                                        Cookies de tercero: Son aquellas cookies que se envían al equipo terminal del
                                        usuario desde un equipo o dominio que no es gestionado por el editor, sino por
                                        otra entidad que trata los datos obtenidos través de las cookies.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="body1">
                                Según el tiempo de actividad:
                                <ul>
                                    <li>
                                        Cookies de sesión: Este tipo de cookies están diseñadas para recabar y almacenar
                                        datos mientras el usuario accede a una página web.
                                    </li>
                                    <li>
                                        Cookies de persistentes: Este tipo de cookies están diseñadas para recabar y
                                        almacenar datos permaneciendo en dispositivo del usuario.
                                    </li>
                                </ul>
                            </Typography>
                            <Typography variant="body1">
                                Según la finalidad:
                                <ul>
                                    <li>
                                        Cookies necesarias: Estas cookies son necesarias para que el sitio web funcione
                                        correctamente. Algunas de las siguientes acciones se pueden realizar utilizando
                                        estas cookies: Guardar sus preferencias de cookies para este sitio web - Guardar
                                        preferencias de idioma, etc.
                                    </li>
                                    <li>
                                        Cookies de rendimiento: Estas cookies se utilizan para recopilar información
                                        estadística sobre el uso de nuestro sitio web, también llamadas cookies
                                        analíticas. Utilizamos estos datos para el rendimiento y la optimización del
                                        sitio web.
                                    </li>
                                    <li>
                                        Cookies dirigidas: Estas cookies son establecidas por socios publicitarios
                                        externos y se utilizan para crear perfiles y realizar un seguimiento de los
                                        datos en varios sitios web. Si usted acepta estas cookies, podemos mostrar
                                        nuestros anuncios en otros sitios web en función de su perfil de usuario y
                                        preferencias. Estas cookies también guardan datos sobre cuántos visitantes han
                                        visto o hecho clic en nuestros anuncios con el fin de optimizar las campañas
                                        publicitarias.
                                    </li>
                                </ul>
                            </Typography>

                            <Typography variant="h4" gutterBottom>
                                Las cookies que utilizamos en nuestro sitio web
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Necessarias
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Propósito</TableCell>
                                            <TableCell>Nombre del dominio</TableCell>
                                            <TableCell>Caducidad</TableCell>
                                            <TableCell>Proveedor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {needed_cookies.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.purpose}</TableCell>
                                                <TableCell>{row.domain}</TableCell>
                                                <TableCell>{row.expires}</TableCell>
                                                <TableCell>{row.provider}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <Typography variant="h5" gutterBottom>
                                Rendimiento
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Propósito</TableCell>
                                            <TableCell>Nombre del dominio</TableCell>
                                            <TableCell>Caducidad</TableCell>
                                            <TableCell>Proveedor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {perfomance_cookies.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.purpose}</TableCell>
                                                <TableCell>{row.domain}</TableCell>
                                                <TableCell>{row.expires}</TableCell>
                                                <TableCell>{row.provider}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <br />
                            <Typography variant="h5" gutterBottom>
                                Dirigidas
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nombre</TableCell>
                                            <TableCell>Propósito</TableCell>
                                            <TableCell>Nombre del dominio</TableCell>
                                            <TableCell>Caducidad</TableCell>
                                            <TableCell>Proveedor</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {directed_cookies.map((row, i) => (
                                            <TableRow key={i}>
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell>{row.purpose}</TableCell>
                                                <TableCell>{row.domain}</TableCell>
                                                <TableCell>{row.expires}</TableCell>
                                                <TableCell>{row.provider}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <br />

                            <Typography variant="h4" gutterBottom>
                                ¿Cómo puedo desactivar o eliminar las cookies?
                            </Typography>
                            <Typography variant="body1">
                                Usted puede optar por no utilizar todas las cookies, excepto las necesarias. En la
                                configuración del navegador, puede cambiar la configuración para asegurarse de que se
                                bloqueen las cookies. La mayoría de los navegadores le ofrecen una explicación sobre
                                cómo hacerlo en la llamada &apos;función de ayuda&apos;. Sin embargo, si bloquea las
                                cookies, Miray Sign no garantiza el correcto funcionamiento de los servicios prestados
                                en este sitio.
                            </Typography>
                            <Typography variant="body1">
                                Los pasos para acceder al menú de configuración de las cookies de los navegadores
                                principales son:
                            </Typography>
                            <Typography variant="body1">
                                <ul>
                                    <li>
                                        Internet Explorer: Herramientas → Opciones de Internet → Privacidad →
                                        Configuración
                                    </li>
                                    <li>
                                        Google Chrome: Configuración → Mostrar opciones avanzadas → Privacidad →
                                        Configuración de contenido
                                    </li>
                                    <li>
                                        Firefox: Herramientas → Opciones → Privacidad → Historial → Configuración
                                        Personalizada
                                    </li>
                                    <li>Safari: Preferencias → Seguridad</li>
                                </ul>
                            </Typography>
                            <Typography variant="body1">
                                Para más información al respecto consulte la ayuda de su navegador.
                            </Typography>

                            <br />

                            <Typography variant="h4" gutterBottom>
                                Actualizaciones y cambios en la política de cookies
                            </Typography>
                            <Typography variant="body1">
                                Miray Sign puede modificar esta Política de Cookies en función de exigencias
                                legislativas, por ello se aconseja a los Usuarios que visiten a de manera periódica la
                                presente Política.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
