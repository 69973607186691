import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import React, { useState } from "react";

interface Props {
    reversible?: boolean;
    onDelete?: () => void;
    children?: React.ReactNode;
}

export default function ConfirmButton(props: Props) {
    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <React.Fragment>
            <Button
                color="secondary"
                onClick={(e) => {
                    e.stopPropagation();
                    setDialogOpen(true);
                }}
            >
                {props.children}
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">¿Está seguro de que quiere hacer esta acción?</DialogTitle>
                {!props.reversible && (
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Esta accion no es reversible.
                        </DialogContentText>
                    </DialogContent>
                )}

                <DialogActions>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            setDialogOpen(false);
                        }}
                        color="primary"
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            props.onDelete!();
                            setDialogOpen(false);
                        }}
                        color="primary"
                        autoFocus
                    >
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
