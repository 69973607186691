import React from "react";
import { AgendaGroup, ApiError } from "../../api/interfaces";
import { AccordionForm, CustomFieldType } from "./CustomForm";
import * as Yup from "yup";
import api from "../../api/client";

type Props = {
    group: AgendaGroup;
    onEdited: (group: AgendaGroup) => void;
};

export default function AgendaEditGroupForm(props: Props) {
    const group = props.group;

    return (
        <AccordionForm
            title="Editar grupo"
            fields={{
                name: {
                    type: CustomFieldType.Text,
                    label: "Nombre*",
                },
                description: {
                    type: CustomFieldType.Text,
                    label: "Descripción",
                    multiline: true,
                },
            }}
            initialValues={{
                name: group.name,
                description: group.description || "",
            }}
            schema={Yup.object().shape({
                name: Yup.string().required("Requerido"),
                description: Yup.string().optional(),
            })}
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.update_group(group.id, values)
                    .then(
                        (res) => {
                            resetForm();
                            setMessage({
                                message: `Grupo actualizado`,
                                type: "success",
                            });
                            props.onEdited(res.result);
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            submitText="Editar"
        />
    );
}
