import React from "react";
import { AccordionForm, CustomFields, CustomFieldType } from "./forms/CustomForm";
import * as Yup from "yup";
import structuredClone from "@ungap/structured-clone";

export interface Query {
    name: string;
    type: string;
    label: string;
}

enum CheckBoxValue {
    False,
    True,
    Indeterminate,
}

interface Props {
    onSearch: (values: Record<string, any>, setSubmitting: (x: boolean) => void) => void;
    queries: Query[];
}

export default function SearchCard(props: Props) {
    const initialValues: Record<string, any> = {
        search: "",
    };
    const fields_update: CustomFields = {
        search: {
            type: CustomFieldType.Text,
            label: "Buscar",
            xs: 12,
        },
    };

    for (const x in props.queries) {
        const query = props.queries[x];

        if (query.type === "boolean") {
            initialValues[query.name] = true;
            fields_update[query.name] = {
                type: CustomFieldType.Switch,
                label: query.label,
                xs: 3,
            };
        } else if (query.type === "string") {
            initialValues[query.name] = "";
            fields_update[query.name] = {
                type: CustomFieldType.Text,
                label: query.label,
                xs: 12,
            };
        } else if (query.type === "number") {
            fields_update[query.name] = {
                type: CustomFieldType.Number,
                label: query.label,
                xs: 12,
            };
            initialValues[query.name] = 0;
        } else if (query.type === "checkbox") {
            initialValues[query.name] = 2;
            fields_update[query.name] = {
                type: CustomFieldType.CheckBox,
                label: query.label,
                xs: 3,
            };
        }
    }

    return (
        <AccordionForm
            fields={fields_update}
            title="Buscar"
            initialValues={initialValues}
            schema={Yup.object().shape({})}
            submitText="Buscar"
            onSubmit={(values, setSubmitting) => {
                // convert checkbox values to booleans
                const clonedValues = structuredClone(values);
                console.log(clonedValues);
                for (const name in clonedValues) {
                    if (fields_update[name].type === CustomFieldType.CheckBox) {
                        if (clonedValues[name] === CheckBoxValue.Indeterminate) delete clonedValues[name];
                        else clonedValues[name] = clonedValues[name] === CheckBoxValue.True;
                    }
                }
                props.onSearch(clonedValues, setSubmitting);
            }}
        />
    );
}
