import { Card, CardContent, Container, Slide, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../api/client";
import { User } from "../api/interfaces";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useAuth } from "../UseAuth";
import { Navigate } from "react-router-dom";
import { countries } from "../countries";
import LinkedInTag from "react-linkedin-insight";

export default function Facturacion() {
    const auth = useAuth()!;
    const [billing, setBilling] = useState<any>(null);

    useEffect(() => {
        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.billing_info().then(
                (res) => {
                    setBilling(res.result);
                },
                () => {
                    setBilling(null);
                },
            );
        }
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    const user = auth.user as User;

    return (
        <Container>
            <Slide in={true} direction="left">
                <Card>
                    <CardContent>
                        <Typography variant="h2" gutterBottom>
                            Facturación
                        </Typography>
                        <CustomForm
                            fields={{
                                name: {
                                    type: CustomFieldType.Text,
                                    label: "Empresa / Nombre y apellido*",
                                    sm: 6,
                                },
                                email: {
                                    type: CustomFieldType.Email,
                                    label: "Email*",
                                    sm: 6,
                                },
                                company_cif: {
                                    type: CustomFieldType.Text,
                                    label: "CIF/DNI*",
                                    disabled: billing !== null,
                                },
                                country: {
                                    type: CustomFieldType.Select,
                                    options: countries.map((x) => ({
                                        label: (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                }}
                                            >
                                                <img
                                                    loading="lazy"
                                                    style={{
                                                        marginRight: 2,
                                                    }}
                                                    src={`https://flagcdn.com/${x.alpha2}.svg`}
                                                    width="30"
                                                />
                                                {x.name}
                                            </Box>
                                        ),
                                        value: x.alpha2,
                                    })),
                                    label: "País*",
                                    sm: 3,
                                },
                                city: {
                                    type: CustomFieldType.Text,
                                    label: "Ciudad*",
                                    sm: 6,
                                },
                                postal_code: {
                                    type: CustomFieldType.Text,
                                    label: "Código postal*",
                                    sm: 3,
                                },
                                address_line1: {
                                    type: CustomFieldType.Text,
                                    label: "Dirección 1*",
                                },
                                address_line2: {
                                    type: CustomFieldType.Text,
                                    label: "Dirección 2",
                                },
                            }}
                            initialValues={
                                billing == null
                                    ? {
                                          email: user.email,
                                          name: user.name || "",
                                          company_cif: "",
                                          city: "",
                                          address_line1: "",
                                          terms: false,
                                          postal_code: "",
                                          country: "es",
                                      }
                                    : {
                                          email: billing.email,
                                          name: billing.name || "",
                                          company_cif: billing.tax_ids ? billing.tax_ids.data[0].value : "",
                                          city: billing.address.city,
                                          address_line1: billing.address.line1,
                                          terms: false,
                                          postal_code: billing.address.postal_code,
                                          country: billing.address.country.toLowerCase(),
                                      }
                            }
                            schema={Yup.object().shape({
                                name: Yup.string().required("Requerido."),
                                company_cif: Yup.string()
                                    .required("Requerido.")
                                    .test({
                                        name: "dni_test",
                                        test: (value: any) => {
                                            if (!value || value === "") return true;
                                            const DNI_REGEX = /^(\d{8})([A-Z])$/;
                                            const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
                                            const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
                                            if (DNI_REGEX.test(value)) {
                                                const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                                                const letter = dni_letters.charAt(parseInt(value, 10) % 23);
                                                return letter == value.charAt(8);
                                            }
                                            if (CIF_REGEX.test(value)) {
                                                return true;
                                            }
                                            if (NIE_REGEX.test(value)) {
                                                return true;
                                            }
                                            return false;
                                        },
                                        message: "No es un CIF/DNI válido",
                                    }),
                                postal_code: Yup.string().required("Requerido."),
                                country: Yup.string().required("Requerido."),
                                city: Yup.string().required("Requerido."),
                                address_line1: Yup.string().required("Requerido."),
                                email: Yup.string().email("Tiene que ser un email válido.").required("Requerido."),
                            })}
                            onSubmit={(values, setSubmitting) => {
                                api.billing(values).then(() => {
                                    setSubmitting(false);
                                    if (process.env.REACT_APP_ENVIROMENT !== "development")
                                        LinkedInTag.track("6422508");
                                    api.billing_info().then(
                                        (res) => {
                                            setBilling(res.result);
                                        },
                                        () => {
                                            setBilling(null);
                                        },
                                    );
                                });
                            }}
                            submitText="Actualizar"
                        />
                    </CardContent>
                </Card>
            </Slide>
        </Container>
    );
}
