import { Uuid } from "./client";

// Common api error interface.
export { ApiError } from "./error";

export enum FieldError {
    Required = "required",
    AlreadyExists = "already_exists",
    DateTooRecent = "date_too_recent",
    DateTooEarly = "date_too_early",
}

export type FieldErrors = Record<string, FieldError>;

/*
export interface ApiError {
    code: number;
    field_errors: FieldErrors | null;
    error: string;
}
*/

// Api response with a single data object.
export interface ApiSingleResponse<T> {
    code: number;
    result: T;
}

// Api response containing a list of data.
export interface ApiListResponse<T> {
    code: number;
    result: {
        page: number;
        data: Array<T>;
    };
}

export interface User {
    id: Uuid;
    email: string;
    name: string;
    admin: boolean;
    active: boolean;
    company_id: Uuid;
    created_at: string;
    send_status_email: boolean;
}

export interface UserStats {
    documents_sent: number;
    departments_in: string[];
}

export interface SuperUser {
    id: Uuid;
    email: string;
    name: string;
    created_at: string;
}

export interface Company {
    id: Uuid;
    name: string;
    active: boolean;
    description: string | null;
    website: string | null;
    created_at: string;
    customer_data_edited: boolean;
    logo_edited: boolean;
    email_edited: boolean;
}

export interface CompanyQuery {
    search?: string;
    name?: boolean;
    description?: boolean;
    page: number;
}

export interface EditBalance {
    amount: number;
    expires: Date;
}

export interface UserQuery {
    search?: string;
    department_id?: Uuid;
    company_id?: Uuid;
    description?: boolean;
    active?: boolean;
}

export interface DepartmentQuery {
    search?: string;
    company_id?: Uuid;
    description?: boolean;
    name?: boolean;
}

export interface BasicQuery {
    company_id?: Uuid;
    page?: number; // todo: remove
}

export interface Department {
    id: string;
    name: string;
    description: string | null;
    company_id: Uuid;
    created_at: string;
}

export interface SignDocument {
    id: string;
    title: string;
    file_name: string;
    company_id: Uuid;
    department_id: Uuid;
    user_id: Uuid | null;
    transaction_id: Uuid | null;
    is_signed: boolean;
    is_via_email: string | null;
    comment: string;
    created_at: string;
    signed_at: string | null;
    canceled_at: string | null;
}

export interface SignDocumentClient {
    document_id: Uuid;
    idx: number;
    name: string;
    phone: string;
    company_name: string;
    dni: string | null;
    dni_type: number | null;
    email: string | null;
    signed: boolean;
    signed_at: string;
}

export interface SignDocumentQuery {
    search?: string;
    company_id?: Uuid;
    department_id?: Uuid;
    user_id?: Uuid;
    title?: boolean;
    file_name?: boolean;
    comment?: boolean;
    page: number;
}

export interface UserAction {
    id: string;
    user_id: Uuid;
    name: string;
    data: string;
    created_at: string;
}

export interface MonthInfo {
    quantity: number;
    month: number;
    year: number;
}

export interface CompanyReportSettings {
    email: string;
    daily: boolean;
    weekly: boolean;
    monthly: boolean;
    yearly: boolean;
}

export interface CompanyStatistic {
    value: number;
    month: Date;
}

export interface GeneralCompanyStats {
    users: number;
    documents: number;
    total_signed_documents: number;
    total_signatures: number;
    total_canceled: number;
}

export enum SmtpRelayMode {
    Tls = "tls",
    StartTls = "starttls",
    None = "none",
}

export interface CompanySMTPSettings {
    domain: string;
    secure_mode: SmtpRelayMode;
    port: number;
    username: string;
    password: string;
    from_email: string;
}

export interface SignProgress {
    sign_request_created: boolean;
    emails_sent: boolean[];
    clients_signed: boolean[];
}

export interface CreditLog {
    id: string;
    balance_before: number;
    balance: number;
    hold_balance_before: number;
    hold_balance: number;
    document_id: Uuid | null;
    client_idx: Uuid | null;
    credit_balance_id: Uuid | null;
    reason: string;
    created_at: string;
}

export interface Price {
    id: Uuid;
    title: string;
    description: string | null;
    quantity: number;
    price: number;
}

export interface UserNotification {
    id: Uuid;
    user_id: Uuid;
    message: string;
    global: boolean;
    seen: boolean;
    created_at: string;
}

export interface BlogPost {
    id: Uuid;
    title: string;
    description: string;
    slug: string;
    data: string;
    created_at: string;
}

export interface AgendaGroup {
    id: Uuid;
    parent_id: Uuid | null;
    company_id: Uuid;
    name: string;
    description: string | null;
    created_at: string;
    clients?: [AgendaClient];
    groups?: [AgendaGroup];
}

export interface AgendaClient {
    id: Uuid;
    group_id: Uuid;
    company_id: Uuid;
    name: string;
    phone: string;
    email: string;
    dni: string | null;
    dni_type: number | null;
    company_name: string | null;
}

export interface NewAgendaGroup {
    name: string;
    description?: string;
    parent_id?: string;
}

export interface NewAgendaClient {
    name: string;
    phone: string;
    email: string;
    dni?: string;
    dni_type?: number;
    company_name?: string;
}

export interface Balance {
    id: Uuid;
    total_credits: number;
    credits: number;
    held_credits: number;
    expires: string;
    created_at: string;
}

export interface SignPayload {
    title: string;
    department_id: Uuid;
    balance_id: Uuid;
    clients: any[];
    comment: string;
    pdf: File;
    send_method: number;
}

export interface AddClientsCsv {
    clients: File;
}

export interface GeneralStats {
    user_count: number;
    company_count: number;
    department_count: number;
    document_count: number;
    cache_size: number;
    cache_hits: number;
    cache_misses: number;
}
