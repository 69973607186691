import {
    Card,
    CardContent,
    Typography,
    Paper,
    Grid,
    Button,
    Box,
    Zoom,
    Slide,
    Grow,
    Divider,
    Avatar,
    Container,
    IconButton,
    Rating,
    Tabs,
    Tab,
} from "@mui/material";
import React, { createRef, useEffect, useMemo } from "react";
import fondo from "../images/fondo2.webp";
import automatizaciondeprocesos from "../images/automatizaciondeprocesos.webp";
import multiplataforma from "../images/multiplataforma.webp";
import garantialegal from "../images/garantialegal.webp";
import ventajasimg from "../images/ventajas.webp";
import eficaciasostenible from "../images/eficaciasostenible.webp";
import Enviar from "../images/Enviar.webp";
import Administrar from "../images/Administra.webp";
import Firmar from "../images/Firmar.webp";
import CheckCircle from "@mui/icons-material/CheckCircle";
import { isMobile } from "react-device-detect";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import LinkedInTag from "react-linkedin-insight";
import trustservice from "../images/trustservice.webp";
import { useAuth } from "../UseAuth";
import FeatureList, { Feature } from "../components/FeatureList";
import { SxProps } from "@mui/system";
import Carousel from "react-material-ui-carousel";
import { isDesktop } from "react-device-detect";
import { Helmet } from "react-helmet-async";

const headerStyle: SxProps = {
    fontSize: {
        xs: "1.2rem",
        "@media (min-width:600px)": "2.5rem",
        md: "2.6rem",
    },
    fontWeight: 400,
    textAlign: "center",
    marginTop: "2rem",
    color: "primary.main",
} as const;

function Testimonio(props: {
    item: {
        name: React.ReactNode;
        description: React.ReactNode;
        date: React.ReactNode;
    };
}) {
    return (
        <Card sx={{ height: 250, backgroundColor: "primary.main", color: "#ffffff", borderRadius: 5, flex: "1 1 0px" }}>
            <CardContent>
                <Rating name="half-rating-read" value={5} precision={0.5} readOnly />
                <Typography variant="h5" textOverflow="ellipsis" overflow="hidden" paragraph align="left">
                    <b>{props.item.name}</b>
                </Typography>
                <Typography
                    variant="body1"
                    height="100px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    paragraph
                    align="left"
                >
                    {props.item.description}
                </Typography>
                <Typography variant="caption" textOverflow="ellipsis" overflow="hidden" paragraph align="left">
                    {props.item.date}
                </Typography>
            </CardContent>
        </Card>
    );
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const testimonios = [
    {
        name: "Reducción de costes",
        description:
            "Sign nos a permitido bajar los costes de documentación y ahorrar en tiempo. Nos han realizado la integración vía API con nuestro software CRM y a sido rápido, fácil y efectivo.",
        date: "",
    },
    {
        name: "Facilidad",
        description:
            "Nos han realizado la integración vía API con nuestro software CRM y a sido rápido, fácil y efectivo.",
        date: "",
    },
    {
        name: "Muy buena plataforma",
        description: "Muy buena plataforma para gestionar tus cuentas y proyectos. 100% recomendable!",
        date: "",
    },
    {
        name: "Aplicación muy completa",
        description: "Aplicación muy completa, intuitiva y con un servicio de atención al excepcional",
        date: "",
    },

    {
        name: "Súper intuitiva",
        description: "Súper intuitiva. Genial",
        date: "",
    },
    {
        name: "Resolución eficaz y excelente disposición",
        description:
            "Vengo de otra plataforma en la que la atención era presentando incidencias y con una resolución demorada y cansina y al cambiarme a Miraysign he comprobado que lo que necesitaba -atención en tiempo real, resolución eficaz y excelente disposición para con el cliente- son una realidad. Espero que todo siga por este camino con una gestión ligera, rápida y cercana. Muchas gracias!",
        date: "",
    },
    {
        name: "Muy bien",
        description: "Es una herramienta muy completa y me ha ayudado a gestionarlo todo de mejor manera",
        date: "",
    },
    {
        name: "Muy satisfecho con la solución y todo…",
        description:
            "Muy satisfecho con la solución y todo lo que ofrece, el sistema de soporte es fantástico resolviendo bastante rápido.",
        date: "",
    },
];

const checkStyle: SxProps = {
    color: "white",
    fontSize: {
        xs: "1.2rem",
        "@media (min-width:600px)": "1.3rem",
        md: "1.5rem",
    },
    fontWeight: 700,
} as const;

export default function Home() {
    const auth = useAuth()!;
    const [searchParams, setSearchParams] = useSearchParams();
    const [tab, setTab] = React.useState(0);
    const [lastTabClick, setLastTabClick] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        setLastTabClick(new Date().getTime());
    };

    useEffect(() => {
        LinkedInTag.track("6422284");
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setTab((t) => (t + 1) % 3);
        }, 5000);

        return () => {
            clearInterval(interval);
        };
    }, [lastTabClick]);

    const automatizacionRef = createRef<HTMLDivElement>();
    const multiplataformaRef = createRef<HTMLDivElement>();
    const garantiaRef = createRef<HTMLDivElement>();
    const eficaciaRef = createRef<HTMLDivElement>();
    const inicioRef = createRef<HTMLDivElement>();

    const processedTestimonios = useMemo(() => {
        const newTestimonios: {
            name: string;
            description: string;
            date: string;
        }[][] = [];

        if (!isMobile) {
            for (let i = 0; i < testimonios.length; i += 4) {
                newTestimonios.push(testimonios.slice(i, i + 4));
            }
        } else {
            for (let i = 0; i < testimonios.length; i += 1) {
                newTestimonios.push(testimonios.slice(i, i + 1));
            }
        }

        return newTestimonios;
    }, []);

    useEffect(() => {
        const view = searchParams.get("view");
        if (view === "inicio" && inicioRef.current) {
            inicioRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
            setSearchParams({});
        }
    }, [searchParams, setSearchParams, inicioRef]);

    if (auth.user) return null;

    return (
        <div>
            <Helmet>
                <title>Miray Sign</title>
                <meta name="description" content="El servicio más sencillo para recoger firmas de tus usuarios." />
            </Helmet>
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: `url(${fondo})`,
                    backgroundSize: "cover",
                    marginTop: "-1.5rem",
                }}
            >
                <Container>
                    <Grid item xs={12} md={12} container spacing={0} ref={inicioRef}>
                        <Paper
                            sx={{
                                marginTop: "5rem",
                                marginBottom: "5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                                flexGrow: 1,
                                backgroundColor: "transparent",
                            }}
                            elevation={0}
                        >
                            <Grid item xs={12} md={8}>
                                <Typography
                                    variant={isMobile ? "h1" : "h1"}
                                    sx={{
                                        color: "white",
                                        fontSize: {
                                            xs: "2rem",
                                            "@media (min-width:600px)": "2.5rem",
                                            md: "3rem",
                                        },
                                        fontWeight: 1000,
                                    }}
                                    gutterBottom
                                >
                                    <b>Firma desde cualquier parte a cualquier hora</b>
                                </Typography>

                                <Grid item xs={12} md={12}>
                                    <Zoom in={true} timeout={500}>
                                        <Typography variant="h5" sx={checkStyle} paragraph>
                                            <CheckCircle sx={{ fill: "white" }} /> Firma electrónicamente de la forma
                                            más segura
                                        </Typography>
                                    </Zoom>
                                    <Zoom in={true} timeout={700}>
                                        <Typography variant="h5" sx={checkStyle} paragraph>
                                            <CheckCircle sx={{ fill: "white" }} /> Hasta 6 firmantes en el mismo
                                            documento
                                        </Typography>
                                    </Zoom>
                                    <Zoom in={true} timeout={900}>
                                        <Typography variant="h5" sx={checkStyle} paragraph>
                                            <CheckCircle sx={{ fill: "white" }} /> 4 evidencias en una sola firma
                                        </Typography>
                                    </Zoom>
                                    <Zoom in={true} timeout={1100}>
                                        <Typography variant="h5" sx={checkStyle} paragraph>
                                            <CheckCircle sx={{ fill: "white" }} /> Plena seguridad jurídica
                                        </Typography>
                                    </Zoom>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Slide direction="up" in={true} timeout={500}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ color: "white" }}
                                            size="large"
                                            component={RouterLink}
                                            to={`/freetest`}
                                        >
                                            <b>Prueba gratuita</b>
                                        </Button>
                                    </Slide>
                                    <Slide direction="up" in={true} timeout={500}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            sx={{ marginLeft: "0.5rem", color: "white" }}
                                            size="large"
                                            component={RouterLink}
                                            to={`/buy`}
                                        >
                                            <b>Comprar</b>
                                        </Button>
                                    </Slide>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} md={6}></Grid>
                        </Paper>
                    </Grid>
                </Container>
            </Box>
            <Divider
                sx={{
                    backgroundColor: "rgba(237, 239, 243, 0.7)",
                }}
            >
                <Grow in={true} style={{ transformOrigin: "1 1 1" }} timeout={1500}>
                    <Typography align="left" variant="h3" sx={headerStyle}>
                        ¿Cómo funciona?
                    </Typography>
                </Grow>
            </Divider>
            {isDesktop && (
                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "rgba(237, 239, 243, 0.7)",
                        alignItems: "stretch",
                        justifyContent: "center",
                    }}
                >
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={tab}
                        onChange={handleTabChange}
                        aria-label="Vertical tabs example"
                        sx={{
                            borderRight: 1,
                            borderColor: "divider",
                            display: "flex",
                            "& .MuiTabs-flexContainer": {
                                height: "100%",
                            },
                        }}
                        indicatorColor="secondary"
                    >
                        <Tab
                            sx={{ flexGrow: 1 }}
                            label={
                                <React.Fragment>
                                    <Typography sx={{ fontSize: "130%" }}>
                                        <b>Enviar</b>
                                    </Typography>
                                    <Typography sx={{ textTransform: "none", textAlign: "left" }}>
                                        Enviar y recibir documentos nunca a sido tan fácil, seguro y rápido.
                                    </Typography>
                                </React.Fragment>
                            }
                            {...a11yProps(1)}
                        />
                        <Tab
                            sx={{ flexGrow: 1 }}
                            label={
                                <React.Fragment>
                                    <Typography sx={{ fontSize: "130%" }}>Firmar</Typography>
                                    <Typography sx={{ textTransform: "none", textAlign: "left" }}>
                                        Cualquier documento puede ser firmado online, desde cualquier dispositivo.
                                    </Typography>
                                </React.Fragment>
                            }
                            {...a11yProps(2)}
                        />
                        <Tab
                            sx={{ flexGrow: 1 }}
                            label={
                                <React.Fragment>
                                    <Typography sx={{ fontSize: "130%" }}>Administrar</Typography>
                                    <Typography sx={{ textTransform: "none", textAlign: "left" }}>
                                        Monitorea el proceso de firma fácilmente y en tiempo real.
                                    </Typography>
                                </React.Fragment>
                            }
                            {...a11yProps(0)}
                        />
                    </Tabs>
                    <div>
                        <TabPanel value={tab} index={0}>
                            <img src={Enviar} style={{ width: "100%", maxHeight: "400px" }} />
                        </TabPanel>
                        <TabPanel value={tab} index={1}>
                            <img src={Firmar} style={{ width: "100%", maxHeight: "400px" }} />
                        </TabPanel>
                        <TabPanel value={tab} index={2}>
                            <img src={Administrar} style={{ width: "100%", maxHeight: "400px" }} />
                        </TabPanel>
                    </div>
                </Box>
            )}
            {isMobile && (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                        backgroundColor: "rgba(237, 239, 243, 0.7)",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ borderLeft: "4px solid #243a8d", paddingLeft: "5px" }}>
                            <Typography sx={{ fontSize: "120%" }}>
                                <b>Enviar</b>
                            </Typography>
                            <Typography>
                                Enviar y recibir documentos nunca a sido tan fácil, seguro y rápido.
                            </Typography>
                        </Box>
                        <img src={Enviar} style={{ width: "80%", margin: "auto" }} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ borderLeft: "4px solid #243a8d", paddingLeft: "5px" }}>
                            <Typography sx={{ fontSize: "120%" }}>
                                <b>Firmar</b>
                            </Typography>
                            <Typography>
                                Cualquier documento puede ser firmado online, desde cualquier dispositivo.
                            </Typography>
                        </Box>
                        <img src={Firmar} style={{ width: "80%", margin: "auto" }} />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <Box sx={{ borderLeft: "4px solid #243a8d", paddingLeft: "5px" }}>
                            <Typography sx={{ fontSize: "120%" }}>
                                <b>Administrar</b>
                            </Typography>
                            <Typography>Monitorea el proceso de firma fácilmente y en tiempo real.</Typography>
                        </Box>
                        <img src={Administrar} style={{ width: "80%", margin: "auto" }} />
                    </Box>
                </Box>
            )}
            <Box sx={{ backgroundColor: "rgba(237, 239, 243, 0.7)" }}>
                <Divider>
                    <Grow in={true} style={{ transformOrigin: "1 1 1" }} timeout={1500}>
                        <Typography align="left" variant="h3" sx={headerStyle}>
                            CARACTERÍSTICAS
                        </Typography>
                    </Grow>
                </Divider>
                <Box
                    sx={{
                        fontSize: {
                            xs: "1.5rem",
                            "@media (min-width:600px)": "2.5rem",
                            md: "2.6rem",
                        },
                        fontWeight: 400,
                        textAlign: "center",
                        color: "#243a8d",
                    }}
                >
                    <Box sx={{ display: "inline-table", "& button": { m: 2 }, height: "100px" }}>
                        <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={2000}>
                            <IconButton
                                style={{ transform: "scale(1.5)" }}
                                color="primary"
                                onClick={() => {
                                    if (automatizacionRef && automatizacionRef.current)
                                        automatizacionRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "start",
                                        });
                                }}
                            >
                                <Avatar src={automatizaciondeprocesos} />
                            </IconButton>
                        </Grow>
                        <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={2500}>
                            <IconButton
                                style={{ transform: "scale(1.5)" }}
                                color="primary"
                                onClick={() => {
                                    if (multiplataformaRef && multiplataformaRef.current)
                                        multiplataformaRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "start",
                                        });
                                }}
                            >
                                <Avatar src={multiplataforma} />
                            </IconButton>
                        </Grow>
                        <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={3000}>
                            <IconButton
                                style={{ transform: "scale(1.5)" }}
                                color="primary"
                                onClick={() => {
                                    if (garantiaRef && garantiaRef.current)
                                        garantiaRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "start",
                                        });
                                }}
                            >
                                <Avatar src={garantialegal} />
                            </IconButton>
                        </Grow>
                        <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={3500}>
                            <IconButton
                                style={{ transform: "scale(1.5)" }}
                                color="primary"
                                onClick={() => {
                                    if (eficaciaRef && eficaciaRef.current)
                                        eficaciaRef.current.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                            inline: "start",
                                        });
                                }}
                            >
                                <Avatar src={eficaciasostenible} />
                            </IconButton>
                        </Grow>
                    </Box>
                </Box>
            </Box>
            <FeatureList>
                <Feature title="AUTOMATIZACIÓN DE PROCESOS" ref={automatizacionRef}>
                    Con ella podremos firmar documentos con trazado igual que firmábamos tradicionalmente en papel y
                    certificando la evidencia con un código OTP único que se envía al firmante.
                </Feature>
                <Feature title="MULTIPLATAFORMA" ref={multiplataformaRef}>
                    Desde cualquier dispositivo y sistema operativo. Puede ser utilizado desde PCs, tablet o smartphone,
                    ofreciendo una experiencia usuario fácil e intuitiva sin necesidad de instalar ninguna aplicación
                    adicional.
                </Feature>
                <Feature title="GARANTÍA LEGAL" ref={garantiaRef}>
                    Certifica que tu empresa cumpla con todas las principales normas y leyes nacionales e
                    internacionales de seguridad y cumplimiento (eIDAS y GDPR) gracias a nuestro Prestador Cualificado
                    de servicios de confianza.
                </Feature>
                <Feature title="EFICACIA SOSTENIBLE" ref={eficaciaRef}>
                    Contratos 100% online, de forma ágil y sin necesidad de gastar papel.
                </Feature>
            </FeatureList>
            <Grid sx={{ backgroundColor: "secondary.main" }}>
                <Grid item xs={12}>
                    <Grow in={true} style={{ transformOrigin: "1 1 1" }} timeout={1500}>
                        <Divider>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontSize: {
                                        xs: "1.5rem",
                                        "@media (min-width:600px)": "2.5rem",
                                        md: "2.6rem",
                                    },
                                    fontWeight: 400,
                                    textAlign: "center",
                                    marginTop: "2rem",
                                    color: "white",
                                }}
                                paragraph
                                align="center"
                            >
                                VENTAJAS
                            </Typography>
                        </Divider>
                    </Grow>
                </Grid>
                <Grid
                    sx={{
                        display: "block",
                        margin: "auto",
                        padding: "20px",
                        maxHeight: "500px",
                        maxWidth: "1000px",
                    }}
                >
                    <img style={{ width: "100%" }} src={ventajasimg}></img>
                </Grid>
            </Grid>
            <Box sx={{ marginTop: 1 }}>
                <Grow in={true} style={{ transformOrigin: "1 1 1" }} timeout={1500}>
                    <Divider>
                        <Typography variant="h3" sx={headerStyle} paragraph align="center">
                            TESTIMONIOS
                        </Typography>
                    </Divider>
                </Grow>
                <Grid justifyContent="center" container spacing={3} marginTop="30px">
                    <Grid>
                        <Typography variant="body1" paragraph align="center">
                            <b>Excelente</b>
                        </Typography>
                    </Grid>
                    <Grid>
                        <Rating name="half-rating-read" value={4.5} precision={0.5} readOnly />
                    </Grid>
                </Grid>
                <Typography variant="h5" paragraph align="center" color="primary.main">
                    Muchas empresas ya recomiendan Miraysign
                </Typography>
                <Box sx={{ margin: 4 }}>
                    <Carousel autoPlay={false}>
                        {processedTestimonios.map((testimonio, index) => {
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        gap: 2,
                                        padding: 2,
                                    }}
                                >
                                    {testimonio.map((item, index2) => (
                                        <Testimonio key={(index + 1) * index2} item={item} />
                                    ))}
                                </Box>
                            );
                        })}
                    </Carousel>
                </Box>
            </Box>
            <Box>
                <Container>
                    <Grid container spacing={3} marginTop="50px" marginBottom="100px">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h4" align="left" color="secondary.main">
                                <b>Prestador de servicios cualificados por la UE</b>
                            </Typography>
                            <Typography variant="h5" align="left" marginTop="20px">
                                Cumplimos con todos los estándares de calidad y normativa de confianza, para poder
                                ofrecer los mejores servicios.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} textAlign="center" marginTop="20px">
                            <img style={{ width: "45%", maxWidth: 280 }} src={trustservice}></img>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            *
            <Box
                display="flex"
                sx={{
                    background: "#243a8d", //"linear-gradient(92deg, #243a8d 25%, #3558dc 113%)",
                    marginBottom: "-20px", // negative margin to offset the global bottom margin and look "connected"
                }}
            >
                <Container>
                    <Typography variant="h4" align="center" color="white" marginTop="70px">
                        Con Miray Sign firmarás muchos documentos, pero poderlo probar antes sabemos que es importante.
                    </Typography>
                    <Typography variant="body1" align="center" color="white" marginTop="30px">
                        Ponte en contacto con nosotros y prueba Miray Sign completamente gratis.
                    </Typography>
                    <Grid textAlign="center" marginBottom="70px" marginTop="30px">
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ color: "white" }}
                            size="large"
                            component={RouterLink}
                            to={`/freetest`}
                        >
                            <b>PEDIR PRUEBA GRATUITA</b>
                        </Button>
                    </Grid>
                </Container>
            </Box>
        </div>
    );
}
