import { Container, Grid, Card, CardHeader, CardContent, Skeleton, Box } from "@mui/material";
import MUIRichTextEditor from "mui-rte";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import api from "../api/client";
import { BlogPost } from "../api/interfaces";

export default function BlogEntry() {
    const { slug } = useParams();
    const [post, setPost] = useState<BlogPost | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let active = true;

        if (slug) {
            api.get_blog_post(slug).then((res) => {
                if (active) {
                    setPost(res.result);
                    setLoading(false);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [slug]);

    if (loading) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Helmet>
                    <title>Blog - Miray Sign</title>
                </Helmet>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={400} />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    if (!loading && !post) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Helmet>
                    <title>Blog - Miray Sign</title>
                </Helmet>
                <Card>
                    <CardContent>
                        <CardHeader
                            title="Esta página no existe."
                            titleTypographyProps={{ variant: "h3" }}
                        ></CardHeader>
                    </CardContent>
                </Card>
            </Container>
        );
    }

    if (!post) return null;

    return (
        <Container sx={{ flexGrow: 1 }} maxWidth="md">
            <Helmet>
                <title>{post.title} - Blog - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title={post.title}
                            titleTypographyProps={{
                                variant: "h1",
                                align: "center",
                                sx: {
                                    fontSize: "calc(1vw + 4vh)",
                                },
                            }}
                        ></CardHeader>
                        <CardContent>
                            <Box sx={{ margin: "-16px", marginBottom: 1 }}>
                                <img style={{ width: "100%" }} src={api.get_blog_post_cover(post.id)}></img>
                            </Box>
                            <MUIRichTextEditor readOnly defaultValue={post.data} toolbar={false} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=3728532&conversionId=6422516&fmt=gif"
            />
        </Container>
    );
}
