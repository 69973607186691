import { useEffect, useRef, useState } from "react";
import { QUERY_LIMIT } from "../api/client";
import { ApiListResponse } from "../api/interfaces";
import { useAuth } from "../UseAuth";

function usePaginate<T, Q>(
    initialPage: number,
    initialQuery: Q,
    getItems: (query: Q, page: number) => Promise<ApiListResponse<T>>,
    requireAuthed = false,
) {
    const auth = useRef(useAuth()!);
    const refGetItems = useRef(getItems);
    const [page, setPage] = useState(initialPage);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState<T[]>([]);
    const [query, setQuery] = useState<Q>(initialQuery);

    const reset = () => {
        setItems([]);
        setPage(0);
        setHasMore(true);
        if (!auth.current.loading && (!requireAuthed || auth.current.user)) {
            refGetItems.current(query, page).then((res) => {
                setItems((i) => i.concat(res.result.data));
                setHasMore(res.result.data.length >= QUERY_LIMIT);
            }, console.error);
        }
    };
    const nextPage = () => setPage((p) => p + 1);

    useEffect(() => {
        let active = true;
        if (!auth.current.loading && (!requireAuthed || auth.current.user)) {
            refGetItems.current(query, page).then((res) => {
                if (active) {
                    setItems((i) => i.concat(res.result.data));
                    setHasMore(res.result.data.length >= QUERY_LIMIT);
                }
            }, console.error);
        }
        return () => {
            active = false;
        };
    }, [page, requireAuthed, query]);

    return {
        items,
        nextPage,
        hasMore,
        reset,
        setQuery,
    };
}

export default usePaginate;
