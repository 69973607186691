import { Card, CardContent, Container, Typography, Grid } from "@mui/material";
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
} from "@mui/lab";
import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import api, { QUERY_LIMIT } from "../api/client";
import { CreditLog, User } from "../api/interfaces";
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, LineChart, Tooltip, Line } from "recharts";
import { useAuth } from "../UseAuth";
import { Navigate } from "react-router-dom";
import { format } from "date-fns";
import { Helmet } from "react-helmet-async";

export default function CreditLogs() {
    const auth = useAuth()!;
    const [items, setItems] = useState<CreditLog[]>([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.list_credits_log({
                page,
            }).then((res) => {
                if (!active) return;
                if (page == 0) setItems(res.result.data);
                else setItems((items) => items.concat(res.result.data));
                setHasMore(res.result.data.length >= QUERY_LIMIT);
            });
        }
        return () => {
            active = false;
        };
    }, [page, auth.user, auth.isSuperUser]);

    const graphItems = useMemo(() => items.slice().reverse(), [items]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Registro de créditos - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4">Registro de uso de créditos</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardContent sx={{ overflowX: "auto" }}>
                            {graphItems.length > 0 && (
                                <ResponsiveContainer height={400}>
                                    <LineChart
                                        data={graphItems.map((x) => ({
                                            time: new Date(x.created_at),
                                            cantidad: x.balance,
                                        }))}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="time"
                                            label={{ value: "Tiempo", position: "insideLeft", dy: 20 }}
                                            tickFormatter={(date) => {
                                                return format(new Date(date), "HH:MM dd/MM/yy");
                                            }}
                                        />
                                        <YAxis
                                            type="number"
                                            domain={["dataMin", "dataMax"]}
                                            label={{ value: "Cantidad", position: "insideLeft", angle: -90, dx: -10 }}
                                        />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="cantidad" stroke="#1565c0" />
                                    </LineChart>
                                </ResponsiveContainer>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <InfiniteScroll
                        style={{ overflow: "hidden", padding: "5px" }}
                        dataLength={items.length}
                        next={() => {
                            setPage((page) => page + 1);
                        }}
                        hasMore={hasMore}
                        loader={<React.Fragment></React.Fragment>}
                    >
                        <Grid container spacing={2}>
                            <Timeline position="alternate">
                                {items.map((item, i) => (
                                    <TimelineItem key={item.id}>
                                        <TimelineOppositeContent>
                                            <Typography color="textSecondary">
                                                {new Date(item.created_at).toLocaleString()}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot />
                                            {i < items.length - 1 && <TimelineConnector />}
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            <Typography color="primary">{item.reason}</Typography>
                                            <Typography>
                                                Créditos: {item.balance_before} ➡️ {item.balance}
                                            </Typography>
                                            <Typography>
                                                Créditos en espera: {item.hold_balance_before} ➡️ {item.hold_balance}
                                            </Typography>
                                            <Typography>Cartera: {item.credit_balance_id}</Typography>
                                        </TimelineContent>
                                    </TimelineItem>
                                ))}
                            </Timeline>
                        </Grid>
                    </InfiniteScroll>
                </Grid>
            </Grid>
        </Container>
    );
}
