import React from "react";
import api from "../../api/client";
import { ApiError } from "../../api/error";
import { AccordionForm, CustomFieldType } from "./CustomForm";
import * as Yup from "yup";
import { User } from "../../api/interfaces";

type Props = {
    department_id: string;
    users: User[];
    onAdd: (user: User) => void;
};

export default function AddUserToDepartmentForm(props: Props) {
    return (
        <AccordionForm
            title="Añadir un usuario"
            fields={{
                user: {
                    label: "Usuario",
                    type: CustomFieldType.Select,
                    options: props.users.map((user) => ({ label: user.name, value: user.id })),
                    sm: 12,
                    xs: 12,
                },
            }}
            schema={Yup.object().shape({
                user: Yup.string().required("Requerido"),
            })}
            initialValues={{
                user: "",
            }}
            submitText="Añadir"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.assign_user_department(props.department_id, values.user!)
                    .then(
                        (res) => {
                            props.onAdd(res.result);
                            setMessage({
                                message: `Usuario actualizado`,
                                type: "success",
                            });
                            resetForm();
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        />
    );
}
