import React from "react";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

export default function SendTestMailForm() {
    return (
        <CustomForm
            fields={{
                email: {
                    type: CustomFieldType.Email,
                    label: "Email",
                },
            }}
            initialValues={{
                email: "",
            }}
            schema={Yup.object().shape({
                email: Yup.string().email("Tiene que ser un email válido").required("Requerido"),
            })}
            submitText="Enviar email de prueba"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.send_test_email(values.email)
                    .then(
                        () => {
                            resetForm();
                            setMessage({
                                message: "Email Enviado",
                                type: "success",
                            });
                        },
                        (err) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error || "Error al enviar el email",
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
