import { Card, CardContent, Container, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import { ApiError, Department, User, UserQuery } from "../api/interfaces";
import { useEffect } from "react";
import UserCard from "../components/UserCard";
import SearchCard from "../components/SearchCard";
import * as Yup from "yup";
import ItemList from "../components/ItemList";
import { AccordionForm, CustomFieldType } from "../components/forms/CustomForm";
import api from "../api/client";
import { useAuth } from "../UseAuth";
import { Navigate } from "react-router-dom";
import usePaginate from "../components/UsePage";

export default function Users() {
    const auth = useAuth()!;
    const [deps, setDeps] = useState<Department[]>([]);
    const {
        items: users,
        nextPage,
        hasMore,
        reset: resetUsers,
        setQuery,
    } = usePaginate<User, UserQuery>(0, {}, (query, page) => api.list_users(query, page), true);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            // TODO: improve page
            api.list_departments({}, 0).then((res) => {
                if (!active) return;
                setDeps(res.result.data);
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    const user = auth.user as User;

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Usuarios
                            </Typography>
                            <Typography>Aquí puedes buscar, registrar o borrar usuarios.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {user.admin && (
                    <Grid item xs={12}>
                        <AccordionForm
                            title="Registrar un usuario"
                            fields={{
                                name: {
                                    label: "Nombre",
                                    type: CustomFieldType.Text,
                                },
                                email: {
                                    label: "Email",
                                    type: CustomFieldType.Email,
                                },
                                department_id: {
                                    label: "Departamento (opcional)",
                                    type: CustomFieldType.Select,
                                    options: deps.map((dep) => ({
                                        value: dep.id,
                                        label: dep.name,
                                    })),
                                },
                                admin: {
                                    label: "Admin",
                                    type: CustomFieldType.Switch,
                                },
                            }}
                            schema={Yup.object().shape({
                                name: Yup.string().required("Requerido"),
                                email: Yup.string().email("Email inválido").required("Requerido"),
                                admin: Yup.boolean(),
                                department_id: Yup.string().nullable().optional(),
                            })}
                            initialValues={{
                                name: "",
                                email: "",
                                admin: false,
                                department_id: "",
                            }}
                            submitText="Registrar Usuario"
                            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                api.create_account(values.name, values.email, values.admin)
                                    .then(
                                        (res) => {
                                            setMessage({
                                                message: `Usuario ${res.result.name} registrado, se ha enviado un email con la información para iniciar sesión`,
                                                type: "success",
                                            });
                                            resetUsers();
                                            resetForm();
                                            // Set department if set
                                            if (values.department_id !== null) {
                                                api.assign_user_department(values.department_id, res.result.id)
                                                    .then(
                                                        () => {},
                                                        (err: ApiError) => {
                                                            if (err.field_errors !== null) {
                                                                api.parse_field_errors(err.field_errors, setFieldError);
                                                            }
                                                            setMessage({
                                                                message: err.error,
                                                                type: "error",
                                                            });
                                                        },
                                                    )
                                                    .finally(() => {
                                                        setSubmitting(false);
                                                    });
                                            }
                                        },
                                        (err: ApiError) => {
                                            if (err.field_errors !== null) {
                                                api.parse_field_errors(err.field_errors, setFieldError);
                                            }
                                            setMessage({
                                                message: err.error,
                                                type: "error",
                                            });
                                        },
                                    )
                                    .finally(() => setSubmitting(false));
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <SearchCard
                        onSearch={(values, setSubmitting) => {
                            setQuery(values);
                            resetUsers();
                            setSubmitting(false);
                        }}
                        queries={[
                            {
                                name: "name",
                                type: "boolean",
                                label: "Nombre",
                            },
                            {
                                name: "email",
                                type: "boolean",
                                label: "Email",
                            },
                            {
                                name: "active",
                                type: "boolean",
                                label: "Activo",
                            },
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ItemList
                        items={users}
                        hasMore={hasMore}
                        onLoadMoreItems={() => {
                            nextPage();
                        }}
                        renderItem={(item: User) => <UserCard user={item} />}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
