import { Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../api/client";
import { Navigate } from "react-router-dom";
import { User } from "../api/interfaces";
import { useAuth } from "../UseAuth";
import DownloadReportsForm from "../components/forms/DownloadReportsForm";
import UpdateReportsIntervalForm from "../components/forms/UpdateReportsIntervalForm";

export default function UpdateReportsSettings() {
    const auth = useAuth()!;

    const [settings, setSettings] = useState({
        email: "",
        daily: false,
        weekly: false,
        monthly: false,
        yearly: false,
    });

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.get_reports_settings().then(
                (report_settings) => {
                    if (active) setSettings(report_settings.result);
                },
                () => {
                    // do nothing on error
                },
            );
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`Descargar reporte`} titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <DownloadReportsForm />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`Configurar reportes`} titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <Typography>Aquí puede configurar el email y la frecuencia de los reportes.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <UpdateReportsIntervalForm onUpdate={(s) => setSettings(s)} settings={settings} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
