import { Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { ApiError } from "../../api/interfaces";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../UseAuth";

export default function Notifications() {
    const auth = useAuth()!;

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container>
            <Card>
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Crear una notificación global.
                    </Typography>

                    <CustomForm
                        fields={{
                            message: {
                                label: "Mensaje",
                                type: CustomFieldType.Text,
                                multiline: true,
                                rows: 4,
                            },
                        }}
                        initialValues={{
                            message: "",
                        }}
                        submitText="Crear notificación global"
                        schema={Yup.object().shape({
                            message: Yup.string().required("Requerido"),
                        })}
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.create_global_notification(values.message)
                                .then(
                                    (res) => {
                                        setMessage({
                                            message: `Se ha notificado a ${res.result.total} usuarios.`,
                                            type: "success",
                                        });
                                        resetForm();
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    );
}
