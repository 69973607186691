import { Card, CardHeader, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Company } from "../api/interfaces";
import { useNavigate } from "react-router-dom";

type Props = {
    company: Company;
};

export default function CompanyCard(props: Props) {
    const company = props.company;
    const navigate = useNavigate();

    return (
        <Card
            sx={{ height: "100%", cursor: "pointer" }}
            onClick={() => {
                navigate(`/superuser/companies/${company.id}`);
            }}
        >
            <CardHeader
                title={company.name}
                titleTypographyProps={{ variant: "h4" }}
                subheader={
                    <React.Fragment>
                        <Box marginBottom="5px">{new Date(company.created_at).toLocaleString()}</Box>
                    </React.Fragment>
                }
            ></CardHeader>
            <CardContent>
                <Typography>{company.description}</Typography>
            </CardContent>
        </Card>
    );
}
