import React, { useEffect, useMemo, useState } from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography,
} from "@mui/material";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import logoBlue from "../images/logo_blue.webp";
import api from "../api/client";
import { GeneralStats, User, UserAction } from "../api/interfaces";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { eachDayOfInterval, format, isSameDay, sub } from "date-fns";

function UserActionListItem({ action }: { action: UserAction }) {
    const [user, setUser] = useState<User | null>(null);

    useEffect(() => {
        let active = true;

        api.get_user(action.user_id).then((res) => {
            if (active) setUser(res.result);
        });

        return () => {
            active = false;
        };
    }, [action.user_id]);

    return (
        <ListItemText
            primary={<React.Fragment>Usuario: {(user && user.name) || action.user_id}</React.Fragment>}
            secondary={
                <React.Fragment>
                    <span>{new Date(action.created_at).toLocaleString()}</span>
                    <br />
                    <span>Actividad: {action.name}</span>
                    <br />
                    <span>{action.data}</span>
                </React.Fragment>
            }
        />
    );
}

export default function AdminHome() {
    const auth = useAuth()!;
    const render = auth.user && auth.isSuperUser;

    const [generalStats, setGeneralStats] = useState<GeneralStats | null>(null);
    const [userActions, setUserActions] = useState<UserAction[]>([]);
    const [userActionsGraph, setUserActionsGraph] = useState<
        {
            type: string;
            date: Date;
        }[]
    >([]);

    useEffect(() => {
        let active = true;

        if (render) {
            setUserActions([]);

            api.list_all_actions_admin((res) => {
                if (!active) return;
                setUserActions((x) => x.concat(res.result.data));
            }, 0);
        }
        return () => {
            active = false;
        };
    }, [render]);

    useEffect(() => {
        let active = true;

        if (render) {
            setUserActionsGraph([]);

            api.list_all_actions_admin((res) => {
                if (!active) return;
                setUserActionsGraph((x) =>
                    x.concat(res.result.data.map((x) => ({ type: x.name, date: new Date(x.created_at) }))),
                );
            }, 200); // max 10k actions
        }
        return () => {
            active = false;
        };
    }, [render]);

    useEffect(() => {
        let active = true;

        if (render) {
            api.general_stats().then((res) => {
                if (active) setGeneralStats(res.result);
            });
        }
        return () => {
            active = false;
        };
    }, [render]);

    const actionsGraphData = useMemo(() => {
        const today = new Date();
        const before1Month = sub(today, {
            months: 1,
        });

        const days = eachDayOfInterval({
            start: before1Month,
            end: today,
        });

        return days.map((day) => {
            const quantity = userActionsGraph.filter((action) => isSameDay(action.date, day)).length;

            return {
                day,
                quantity,
            };
        });
    }, [userActionsGraph]);

    if (!render) {
        return null;
    }

    return (
        <Container>
            <Helmet>
                <title>Administración | Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent sx={{ height: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="h3"
                                        gutterBottom
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                        }}
                                    >
                                        <img width="100%" src={logoBlue} alt="Miray Sign"></img>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                        }}
                                        align="center"
                                    >
                                        El servicio más sencillo para recoger firmas de tus usuarios
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{ maxHeight: "300px", overflowY: "auto" }}>
                        <CardContent sx={{ height: "100%" }}>
                            <Typography
                                variant="h5"
                                fontWeight="bold"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100%",
                                }}
                                align="center"
                            >
                                Actividad reciente
                            </Typography>
                            <List>
                                {userActions.map((action) => (
                                    <ListItem key={action.id}>
                                        <UserActionListItem action={action} key={action.id} />
                                    </ListItem>
                                ))}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{ height: "100%" }}>
                        <CardHeader title="Estadísticas Generales" titleTypographyProps={{ variant: "h4" }} />
                        <CardContent>
                            {generalStats && (
                                <ul>
                                    <li>
                                        <Typography>Empresas: {generalStats.company_count}</Typography>
                                    </li>
                                    <li>
                                        <Typography>Usuarios: {generalStats.user_count}</Typography>
                                    </li>

                                    <li>
                                        <Typography>Departamentos: {generalStats.department_count}</Typography>
                                    </li>
                                    <li>
                                        <Typography>Documentos: {generalStats.document_count}</Typography>
                                    </li>
                                </ul>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Card>
                        <CardHeader title="Actividad reciente" titleTypographyProps={{ variant: "h4" }} />
                        <CardContent sx={{ overflowX: "auto" }}>
                            <ResponsiveContainer height={300}>
                                <LineChart data={actionsGraphData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="day"
                                        tickFormatter={(date) => {
                                            return format(new Date(date), "dd/MM/yyyy");
                                        }}
                                    />
                                    <YAxis />
                                    <Tooltip labelFormatter={(value) => format(new Date(value), "dd/MM/yyyy")} />
                                    <Legend />
                                    <Line type="monotone" dataKey="quantity" stroke="#8884d8" activeDot={{ r: 4 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Card sx={{ height: "100%" }}>
                        <CardHeader title="Información del backend" titleTypographyProps={{ variant: "h4" }} />
                        <CardContent>
                            {generalStats && (
                                <ul>
                                    <li>
                                        <Typography>Auth Cache size: {generalStats.cache_size}</Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            Auth Cache hits: {generalStats.cache_hits} (
                                            {(
                                                (generalStats.cache_hits /
                                                    (generalStats.cache_hits + generalStats.cache_misses)) *
                                                100
                                            ).toFixed(2)}
                                            %)
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography>
                                            Auth Cache misses: {generalStats.cache_misses} (
                                            {(
                                                (generalStats.cache_misses /
                                                    (generalStats.cache_hits + generalStats.cache_misses)) *
                                                100
                                            ).toFixed(2)}
                                            %)
                                        </Typography>
                                    </li>
                                </ul>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
