import { Container, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Navigate, useParams } from "react-router-dom";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import RecoverPasswordForm from "../components/forms/RecoverPasswordForm";

export default function ChangePassword() {
    const auth = useAuth()!;

    const { token } = useParams();

    if (auth.user) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Recuperar cuenta - Miray Sign</title>
            </Helmet>
            <Card
                sx={{
                    maxWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    minWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    alignContent: "center",
                }}
            >
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Recuperar cuenta
                    </Typography>

                    <RecoverPasswordForm token={token!} />
                </CardContent>
            </Card>
        </Container>
    );
}
