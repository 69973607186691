import React from "react";
import { AgendaGroup, ApiError } from "../../api/interfaces";
import { AccordionForm, CustomFieldType } from "./CustomForm";
import * as Yup from "yup";
import api from "../../api/client";

type Props = {
    onNewGroup: (group: AgendaGroup) => void;
    parent_id?: string;
};

export default function AgendaGroupForm(props: Props) {
    return (
        <AccordionForm
            title={props.parent_id ? "Añadir subgrupo" : "Añadir grupo"}
            fields={{
                name: {
                    type: CustomFieldType.Text,
                    label: "Nombre*",
                },
                description: {
                    type: CustomFieldType.Text,
                    label: "Descripción",
                    multiline: true,
                },
            }}
            initialValues={{
                name: "",
                description: "",
            }}
            schema={Yup.object().shape({
                name: Yup.string().required("Requerido"),
                description: Yup.string().optional(),
            })}
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.create_group({
                    ...values,
                    parent_id: props.parent_id,
                })
                    .then(
                        (res) => {
                            resetForm();
                            setMessage({
                                message: `Grupo creado`,
                                type: "success",
                            });
                            props.onNewGroup(res.result);
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            submitText="Crear"
        />
    );
}
