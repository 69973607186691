import React from "react";
import api from "../../api/client";
import { ApiError } from "../../api/error";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

export default function RecoverPasswordForm(props: { token: string }) {
    return (
        <CustomForm
            fields={{
                password: {
                    type: CustomFieldType.Password,
                    label: "Contraseña",
                },
                password_repeat: {
                    type: CustomFieldType.Password,
                    label: "Repetir contraseña",
                },
            }}
            submitText="Cambiar"
            initialValues={{
                password: "",
                password_repeat: "",
            }}
            schema={Yup.object().shape({
                password: Yup.string().min(3, "Debe tener almenos 3 carácteres").required("Requerido"),
                password_repeat: Yup.string()
                    .oneOf([Yup.ref("password"), null], "La contraseña no concuerda")
                    .required("Requerido"),
            })}
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.recover(props.token, values.password)
                    .then(
                        () => {
                            resetForm();
                            setMessage({
                                message: "Contraseña cambiada",
                                type: "success",
                            });
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error || "Error interno del servidor",
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
