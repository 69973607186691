import { Container, Grid, Card, CardContent, Typography, Box, CardHeader, CardActions } from "@mui/material";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Department, User } from "../api/interfaces";
import UserCard from "../components/UserCard";
import api, { QUERY_LIMIT } from "../api/client";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import AddUserToDepartmentForm from "../components/forms/AddUserToDepartmentForm";
import ConfirmButton from "../components/ConfirmButton";

export default function DepartmentDetail() {
    const auth = useAuth()!;
    const [loading, setloading] = useState(true);
    const [department, setDepartment] = useState<Department | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [usersPage, setUsersPage] = useState(0);
    const [allUsersPage, setAllUsersPage] = useState(0);
    const [allUsers, setAllUsers] = useState<User[]>([]);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser && id !== undefined) {
            api.get_department(id).then((res) => {
                if (!active) return;
                setDepartment(res.result);
            });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;
        api.list_users({}, allUsersPage).then((res) => {
            setloading(false);
            if (!active) return;

            if (allUsersPage === 0) setAllUsers(res.result.data);
            else setAllUsers((currentItems) => currentItems.concat(res.result.data));
            if (res.result.data.length >= QUERY_LIMIT) setAllUsersPage((p) => p + 1);
        });

        return () => {
            active = false;
        };
    }, [id, allUsersPage]);

    useEffect(() => {
        let active = true;
        api.list_users(
            {
                department_id: id,
            },
            usersPage,
        ).then((res) => {
            setloading(false);
            if (!active) return;

            if (usersPage === 0) setUsers(res.result.data);
            else setUsers((currentItems) => currentItems.concat(res.result.data));
            if (res.result.data.length >= QUERY_LIMIT) setUsersPage((p) => p + 1);
        });

        return () => {
            active = false;
        };
    }, [id, usersPage]);

    const isAdmin = auth.isSuperUser ? true : (auth.user as User).admin;

    if (!auth.user || auth.isSuperUser || id === undefined) {
        return <Navigate to="/login" />;
    }

    const user = auth.user as User;

    if (loading) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="60%" />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {department !== null && (
                    <Grid item xs={12}>
                        <Helmet>
                            <title>{department?.name} - Departamentos - Miray Sign</title>
                        </Helmet>
                        <Card>
                            <CardHeader
                                title={`Departamento ${department.name}`}
                                titleTypographyProps={{ variant: "h3" }}
                                subheader={
                                    <React.Fragment>
                                        <Box marginBottom="5px">{new Date(department.created_at).toLocaleString()}</Box>
                                    </React.Fragment>
                                }
                            ></CardHeader>
                            <CardContent>
                                <Typography variant="body1">{department.description}</Typography>
                            </CardContent>
                            <CardActions>
                                {isAdmin && (
                                    <React.Fragment>
                                        <ConfirmButton
                                            onDelete={() => {
                                                api.remove_department(id).then(() => {
                                                    navigate("/deps", { replace: true });
                                                });
                                            }}
                                        >
                                            Eliminar
                                        </ConfirmButton>
                                    </React.Fragment>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                )}

                {user.admin && (
                    <Grid item xs={12}>
                        <AddUserToDepartmentForm
                            department_id={id}
                            users={allUsers}
                            onAdd={(user) => {
                                setUsers(users.concat(user));
                            }}
                        />
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Usuarios
                            </Typography>
                            Total: {users.length}
                        </CardContent>
                    </Card>
                </Grid>
                {users.map((user) => (
                    <Grid item xs={12} sm={6} md={4} key={user.id}>
                        {user.admin && department && (
                            <UserCard
                                user={user}
                                onDelete={() => {
                                    api.remove_user_department(department.id, user.id).then(() => {
                                        setUsers(users.filter((x) => x.id !== user.id));
                                    });
                                }}
                            />
                        )}
                        {!user.admin && <UserCard user={user} />}
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
