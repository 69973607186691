import {
    Breadcrumbs,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    Skeleton,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import api from "../api/client";
import { AgendaClient, AgendaGroup, ApiError } from "../api/interfaces";
import { AccordionForm, CustomFieldType } from "../components/forms/CustomForm";
import { useAuth } from "../UseAuth";
import * as Yup from "yup";
import { Link as RouterLink } from "react-router-dom";
import PHONE_PREFIXES from "../phone_prefixes";
import structuredClone from "@ungap/structured-clone";
import ConfirmButton from "../components/ConfirmButton";
import { Helmet } from "react-helmet-async";

/**
 * Finds the used prefix for the given phone number and splits them.
 */
export function find_prefix(phone: string) {
    // Since we know most clients are spanish we search for it first.
    if (phone.startsWith(PHONE_PREFIXES.countries[201].dial_code)) {
        return {
            prefix: PHONE_PREFIXES.countries[201].dial_code,
            phone: phone.slice(PHONE_PREFIXES.countries[201].dial_code.length),
        };
    }

    for (const i in PHONE_PREFIXES.countries) {
        if (phone.startsWith(PHONE_PREFIXES.countries[i].dial_code)) {
            return {
                prefix: PHONE_PREFIXES.countries[i].dial_code,
                phone: phone.slice(PHONE_PREFIXES.countries[i].dial_code.length),
            };
        }
    }
}

export default function AgendaClientDetail() {
    const auth = useAuth()!;
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<AgendaGroup | null>(null);
    const [parentGroups, setParentGroups] = useState<AgendaGroup[]>([]);
    const [contact, setContact] = useState<AgendaClient | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser && id) {
            api.client_detail(id).then((res) => {
                if (!active) return;

                setContact(res.result);
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, id]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser && contact) {
            api.group_detail(contact.group_id).then((res) => {
                if (!active) return;

                setGroup(res.result);
                setParentGroups([]);
                setLoading(false);
                if (res.result.parent_id) {
                    api.find_all_parent_groups(res.result.parent_id).then((res) => {
                        setParentGroups(res);
                    });
                }
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, contact]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="60%" />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    if (!group || !contact) return null;

    const name_surname = contact.name.split(" ");
    const phone_data = find_prefix(contact.phone)!;

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>{contact.name} - Agenda - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Contacto en la agenda: {contact.name}
                            </Typography>
                            <Breadcrumbs aria-label="breadcrumb">
                                {parentGroups.map((x) => (
                                    <Link
                                        underline="hover"
                                        color="inherit"
                                        key={x.id}
                                        component={RouterLink}
                                        to={`/agenda/groups/${x.id}`}
                                    >
                                        {x.name}
                                    </Link>
                                ))}
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    component={RouterLink}
                                    to={`/agenda/groups/${group.id}`}
                                >
                                    {group.name}
                                </Link>
                            </Breadcrumbs>
                        </CardContent>
                        <CardActions>
                            <ConfirmButton
                                onDelete={() => {
                                    api.delete_client(contact.id).then(() => {
                                        navigate("/agenda", { replace: true });
                                    });
                                }}
                            >
                                Eliminar
                            </ConfirmButton>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AccordionForm
                        defaultExpanded
                        title="Editar contacto"
                        fields={{
                            name: {
                                type: CustomFieldType.Text,
                                label: "Nombre*",
                                sm: 6,
                            },
                            surname: {
                                type: CustomFieldType.Text,
                                label: "Apellido*",
                                sm: 6,
                            },
                            company_name: {
                                type: CustomFieldType.Text,
                                label: "Empresa",
                            },
                            phone_prefix: {
                                type: CustomFieldType.Select,
                                label: "Prefijo",
                                options: PHONE_PREFIXES.countries.map((x) => ({
                                    label: `${x.dial_code} - ${x.name_es}`,
                                    value: x.dial_code,
                                })),
                                sm: 4,
                                xs: 12,
                            },
                            phone: {
                                type: CustomFieldType.Text,
                                label: "Teléfono móvil*",
                                sm: 8,
                                xs: 12,
                            },
                            email: {
                                type: CustomFieldType.Email,
                                label: "Email*",
                            },
                            dni_type: {
                                type: CustomFieldType.Select,
                                label: "Tipo de documento identificativo",
                                sm: 4,
                                xs: 12,
                                options: [
                                    {
                                        label: "DNI",
                                        value: 0,
                                    },
                                    {
                                        label: "Otro",
                                        value: 1,
                                    },
                                ],
                            },
                            dni: {
                                type: CustomFieldType.Text,
                                label: "Valor del documento identificativo",
                                xs: 12,
                                sm: 8,
                            },
                        }}
                        initialValues={{
                            name: name_surname.length > 0 ? name_surname[0] : "",
                            surname: name_surname.length > 1 ? name_surname[1] : "",
                            email: contact.email,
                            company_name: contact.company_name || "",
                            dni: contact.dni || "",
                            dni_type: contact.dni_type || 0,
                            phone: phone_data?.phone,
                            phone_prefix: phone_data?.prefix,
                        }}
                        schema={Yup.object().shape({
                            name: Yup.string().required("Requerido"),
                            surname: Yup.string().required("Requerido"),
                            phone: Yup.string()
                                .test({
                                    name: "phone_correct_test",
                                    test: (value: any) => {
                                        return /^\d{9,10}$/.test(value);
                                    },
                                    message: "No es un teléfono válido",
                                })
                                .required("Requerido"),
                            email: Yup.string().email("No es un email válido").required("Requerido"),
                            company_name: Yup.string().optional(),
                            dni: Yup.string()
                                .optional()
                                .when(["dni_type"], {
                                    is: (dni_type: number) => dni_type === 0,
                                    then: Yup.string()
                                        .optional()
                                        .test({
                                            name: "dni_test",
                                            test: (value: any) => {
                                                if (!value || value === "") return true;
                                                const DNI_REGEX = /^(\d{8})([A-Z])$/;
                                                if (!DNI_REGEX.test(value)) return false;
                                                const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                                                const letter = dni_letters.charAt(parseInt(value, 10) % 23);
                                                return letter == value.charAt(8);
                                            },
                                            message: "No es un DNI válido",
                                        }),
                                }),
                            dni_type: Yup.number().min(0).max(1),
                        })}
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            const client = structuredClone(values);
                            client.name += " " + client.surname;
                            client.phone = client.phone_prefix + client.phone;

                            api.update_client(contact.id, client)
                                .then(
                                    (res) => {
                                        resetForm();
                                        setMessage({
                                            message: `Contacto editado.`,
                                            type: "success",
                                        });
                                        setContact(res.result);
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                        submitText="Editar"
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
