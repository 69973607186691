import React from "react";
import { ApiError, Balance } from "../../api/interfaces";
import * as Yup from "yup";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import api from "../../api/client";

export default function EditBalanceForm(props: { balance: Balance; onUpdate: (balance: Balance) => void }) {
    const balance = props.balance;

    return (
        <CustomForm
            fields={{
                amount: {
                    label: "Créditos",
                    type: CustomFieldType.Number,
                    sm: 6,
                },
                expires: {
                    label: "Expiran",
                    type: CustomFieldType.DatePicker,
                    sm: 6,
                },
            }}
            schema={Yup.object().shape({
                amount: Yup.number().required("Requerido"),
                expires: Yup.date().required("Requerido"),
            })}
            initialValues={{
                amount: balance.credits,
                expires: new Date(balance.expires),
            }}
            submitText="Editar"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.edit_balance(balance.id, values)
                    .then(
                        (res) => {
                            setMessage({
                                message: `Créditos actualizados`,
                                type: "success",
                            });
                            resetForm();
                            props.onUpdate(res.result);
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
        />
    );
}
