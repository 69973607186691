import React from "react";
import api from "../../api/client";
import { ApiError } from "../../api/error";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

export default function ForgotPasswordForm() {
    return (
        <CustomForm
            fields={{
                email: {
                    label: "Email del usuario",
                    type: CustomFieldType.Email,
                },
            }}
            initialValues={{
                email: "",
            }}
            submitText="Recuperar"
            schema={Yup.object().shape({
                email: Yup.string().email("Tiene que ser un email válido").required("Requerido"),
            })}
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.request_recover(values.email)
                    .then(
                        () => {
                            resetForm();
                            setMessage({
                                message: "Se ha enviado un email con los detalles para recuperar la cuenta",
                                type: "success",
                            });
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error || "Error interno del servidor",
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
