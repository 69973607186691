import { Container, Grid, Card, CardContent, Typography, CardHeader } from "@mui/material";
import React from "react";
import eficiencia from "../images/eficiencia.webp";
import medio_ambiente from "../images/medio_ambiente.webp";
import calidad from "../images/calidad.webp";
import proximidad from "../images/proximidad.webp";
import liderazgo from "../images/liderazgo.webp";
import aboutus from "../images/aboutus.webp";
import { ScrollToTopOnMount } from "../components/ScrollToTopOnMount";
import { Helmet } from "react-helmet-async";

export default function Us() {
    return (
        <Container sx={{ flexGrow: 1 }}>
            <ScrollToTopOnMount />
            <Helmet>
                <title>Sobre nosotros - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="" titleTypographyProps={{ variant: "h3" }}></CardHeader>

                        <CardContent>
                            <Grid container spacing={2} textAlign="center">
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h3" gutterBottom>
                                        <b>Sobre Miray Sign y Miray Consulting</b>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <img style={{ width: "100%" }} src={aboutus}></img>
                                </Grid>
                            </Grid>
                            <br />
                            <Typography variant="h5" gutterBottom color="#243a8d">
                                <b>
                                    Miray Sign es una plataforma de firma electrónica diseñada y desarrollada por Miray
                                    Consulting.
                                </b>
                            </Typography>
                            <Typography variant="body1">
                                Somos especialistas en la implantación y consultoría en el sector de la tecnología.
                                Nuestra compañía centra su actividad en facilitar la implementación de las nuevas
                                tecnologías de comunicaciones a todas las empresas.
                            </Typography>
                            <br />
                            <Typography variant="body1">
                                Desde el comienzo, nuestra filosofía de actuación reside en la capacidad de innovación y
                                servicio de las personas que conforman nuestro equipo, con el fin de obtener resultados
                                óptimos, generadores de valor para nuestros clientes.
                                <br />
                                <br />
                                El trabajo en equipo y la rápida adaptabilidad a los cambios en tecnología son
                                características fundamentales de Miray Consulting, así como nuestra amplia experiencia
                                en el sector de la tecnología. Esto ha servido para consolidarnos en uno de los primeros
                                lugares en el sector de las telecomunicaciones, disfrutando de un elevado grado de
                                confianza por parte de nuestros clientes.
                            </Typography>
                            <br />
                            <br />
                            <Grid container spacing={2} alignItems="stretch" textAlign="center" justifyContent="center">
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <Typography margin="10px" variant="h5" gutterBottom color="#ef7f01">
                                            Liderazgo
                                        </Typography>
                                        <img style={{ width: "60%", maxWidth: 280 }} src={liderazgo}></img>
                                        <Typography margin="10px" variant="body1">
                                            Buscamos ser la mejor empresa en el sector de las telecomunicaciones y para
                                            ello debemos esforzarnos cada día, y debemos ser muy perfeccionistas con
                                            cada proyecto.
                                        </Typography>
                                    </Card>
                                </Grid>
                                <br />
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <Typography margin="10px" variant="h5" gutterBottom color="#ef7f01">
                                            Proximidad
                                        </Typography>
                                        <img style={{ width: "60%", maxWidth: 280 }} src={proximidad}></img>
                                        <Typography margin="10px" variant="body1">
                                            Buscamos que el cliente colabore con nosotros para que el proyecto se adapte
                                            de la mejor forma posible a sus necesidades reales.
                                        </Typography>
                                    </Card>
                                </Grid>
                                <br />
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <Typography margin="10px" variant="h5" gutterBottom color="#ef7f01">
                                            Calidad
                                        </Typography>
                                        <img style={{ width: "60%", maxWidth: 280 }} src={calidad}></img>
                                        <Typography margin="10px" variant="body1">
                                            Exigimos a nosotros y a nuestros partners la mejor calidad en todos sus
                                            productos, y nos exigimos a nosotros mismos el mayor esfuerzo para obtener
                                            los mejores resultados.
                                        </Typography>
                                    </Card>
                                </Grid>
                                <br />
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <Typography margin="10px" variant="h5" gutterBottom color="#ef7f01">
                                            Medio ambiente
                                        </Typography>
                                        <img style={{ width: "60%", maxWidth: 280 }} src={medio_ambiente}></img>
                                        <Typography margin="10px" variant="body1">
                                            El compromiso con nuestro planeta nos mueve a soluciones de alta eficiencia
                                            energética y respetuosas con el medio ambiente.
                                        </Typography>
                                    </Card>
                                </Grid>
                                <br />
                                <Grid item xs={12} md={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <Typography margin="10px" variant="h5" gutterBottom color="#ef7f01">
                                            Eficiencia
                                        </Typography>
                                        <img style={{ width: "60%", maxWidth: 280 }} src={eficiencia}></img>
                                        <Typography margin="10px" variant="body1">
                                            Buscamos que todos los esfuerzos realizados se traduzcan en resultados,
                                            tanto para nuestros clientes, como para nuestra empresa.
                                        </Typography>
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
