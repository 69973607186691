import axios from "axios";
import {
    ApiListResponse,
    ApiSingleResponse,
    BlogPost,
    Company,
    CompanyQuery,
    CompanyReportSettings,
    CompanySMTPSettings,
    CompanyStatistic,
    CreditLog,
    BasicQuery,
    Department,
    DepartmentQuery,
    EditBalance,
    FieldError,
    FieldErrors,
    MonthInfo,
    Price,
    SignDocument,
    SignDocumentClient,
    SignDocumentQuery,
    SignProgress,
    SuperUser,
    User,
    UserAction,
    UserNotification,
    UserQuery,
    AgendaGroup,
    AgendaClient,
    NewAgendaGroup,
    NewAgendaClient,
    GeneralCompanyStats,
    Balance,
    SignPayload,
    AddClientsCsv,
    GeneralStats,
    UserStats,
} from "./interfaces";
import queryString from "query-string";
import { ApiError } from "./error";
import { AxiosCacheInstance, CacheRequestConfig, setupCache } from "axios-cache-interceptor";

export type OnResponse<T> = (res: ApiListResponse<T>) => void;
export type Uuid = string;

export class Api {
    private client: AxiosCacheInstance;
    private token_date: number;
    private endpoint: string;

    constructor() {
        if (process.env.REACT_APP_API_ENDPOINT) {
            this.endpoint = process.env.REACT_APP_API_ENDPOINT;
        } else if (process.env.NODE_ENV === "production") {
            this.endpoint = "https://api.sign.mirayconsulting.com";
        } else {
            this.endpoint = "http://localhost:9787";
        }
        this.client = setupCache(
            axios.create({
                baseURL: this.endpoint,
                timeout: 1000 * 10,
                withCredentials: true,
            }),
            {
                ttl: 1000 * 2, // 2 seconds
            },
        );

        this.client.defaults.headers!.post["Content-Type"] = "application/json";

        const token = localStorage.getItem("token");
        const token_date = localStorage.getItem("token_date");

        if (token !== null) {
            this.set_token(token);
        }

        if (token_date !== null) {
            this.token_date = Number.parseInt(token_date);
        } else {
            this.token_date = 0;
        }
    }

    getBaseUrl() {
        return this.client.defaults.baseURL;
    }

    async post<T>(url: string, data?: any, config?: CacheRequestConfig) {
        return this.client.post<ApiSingleResponse<T>>(url, data, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async post_list<T>(url: string, data?: any, config?: CacheRequestConfig) {
        return this.client.post<ApiListResponse<T>>(url, data, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async put<T>(url: string, data?: any, config?: CacheRequestConfig) {
        return this.client.put<ApiSingleResponse<T>>(url, data, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async get<T>(url: string, query?: Record<string, any>, config?: CacheRequestConfig) {
        const url_query = url + (query ? `?${queryString.stringify(query)}` : "");
        return this.client.get<ApiSingleResponse<T>>(url_query, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async get_list<T>(url: string, query?: Record<string, any>, config?: CacheRequestConfig) {
        const url_query = url + (query ? `?${queryString.stringify(query)}` : "");
        return this.client.get<ApiListResponse<T>>(url_query, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async get_list_paginated<T>(url: string, query: Record<string, any>, page: number, config?: CacheRequestConfig) {
        // page should always be positive.
        console.assert(page >= 0, { page });

        return this.get_list<T>(url, { ...query, page }, config);
    }

    async get_list_all<T>(
        url: string,
        query: Record<string, any>,
        onRes: OnResponse<T>,
        maxPage?: number,
        config?: CacheRequestConfig,
    ) {
        let page = 0;
        let hasMore = true;

        while (hasMore) {
            const res = await this.get_list<T>(url, { ...query, page }, config);
            hasMore = res.result.data.length >= QUERY_LIMIT;
            onRes(res);
            page++;

            if (maxPage !== undefined && page >= maxPage) return;
        }
    }

    async delete<T = null>(url: string, config?: CacheRequestConfig) {
        return this.client.delete<ApiSingleResponse<T>>(url, config).then(
            (res) => {
                return res.data;
            },
            (err) => {
                if (err.response && err.response.data) throw new ApiError(err.response.data);
                else {
                    console.error(err);
                    throw new ApiError({
                        code: 500,
                        field_errors: null,
                        error: "Unexpected error",
                    });
                }
            },
        );
    }

    async contact(payload: {
        name: string;
        company: string;
        comment: string;
        email: string;
        phone: string;
        phone_prefix: string;
    }) {
        return this.post<null>("/account/contact", {
            ...payload,
            phone: payload.phone_prefix + payload.phone,
        });
    }

    async freetest(payload: {
        name: string;
        company: string;
        comment: string;
        email: string;
        phone: string;
        phone_prefix: string;
    }) {
        return this.post<null>("/account/freetest", {
            ...payload,
            phone: payload.phone_prefix + payload.phone,
        });
    }

    async checkout(price_id: Uuid) {
        return this.post<{ message: string; invoice_url: string; invoice_id: string }>(
            "/payment/checkout_invoice",
            {
                price_id,
            },
            {
                timeout: 1000 * 20,
            },
        );
    }

    async invoice_status(invoice_id: Uuid) {
        return this.post<{ paid: boolean } & Record<string, any>>(`/payment/invoice/${invoice_id}`);
    }

    async billing_info() {
        return this.post<Record<string, any>>(`/payment/customer`);
    }

    async billing(payload: {
        company_cif: string;
        country: string;
        city: string;
        postal_code: string;
        address_line1: string;
        address_line2?: string;
        name: string;
        email: string;
    }) {
        return this.post<{ url: string }>("/payment/billing", payload);
    }

    async login(email: string, password: string, superuser = false) {
        const payload = {
            email,
            password,
            superuser,
        };

        return this.post<SuperUser | User>("/auth/login", payload);
    }

    async refresh() {
        return this.post<{ access_token: string }>("/auth/refresh").then((data) => {
            this.set_token(data.result.access_token);
            this.token_date = Date.now();
            this.save_token(data.result.access_token);
            return data;
        });
    }

    async me() {
        return this.get<SuperUser | User>("/users/me");
    }

    async me_company() {
        return this.get<Company>("/users/me/company");
    }

    async logout() {
        return this.post<null>("/auth/logout").then((data) => {
            this.clear_token();
            return data;
        });
    }

    async create_account(name: string, email: string, admin: boolean, company_id?: Uuid) {
        const payload = {
            name,
            email,
            admin,
            company_id,
        };

        return this.post<User>("/account/create", payload);
    }

    async create_company_account(company_name: string, name: string, email: string, password?: string) {
        const payload = {
            company: company_name,
            name,
            email,
            password,
        };

        return this.post<User>("/account/register_company", payload);
    }

    async create_company(name: string, description: string | null, website: string | null) {
        const payload = {
            name,
            description,
            website,
        };

        return this.post<Company>("/superadmin/company", payload);
    }

    async set_company_active(id: string, active: boolean) {
        const payload = {
            active,
        };

        return this.post<Company>(`/superadmin/company/${id}/active`, payload);
    }

    async create_department(name: string, description: string, company_id?: Uuid) {
        const payload = {
            name,
            description,
            company_id,
        };

        return this.post<Department>("/deps", payload);
    }

    async change_pass(password: string) {
        const payload = {
            password,
        };

        return this.post<null>("/account/change_pass", payload);
    }

    async recover(token: string, password: string) {
        const payload = {
            password,
        };

        return this.post<null>(`/account/recover/${token}`, payload);
    }

    async request_recover(email: string) {
        const payload = {
            email,
        };

        return this.post<null>("/account/request_password", payload);
    }

    async assign_user_department(dep_id: Uuid, user_id: Uuid) {
        const payload = {
            user_id,
        };

        return this.post<User>(`/deps/${dep_id}/users`, payload);
    }

    async remove_user_department(dep_id: Uuid, user_id: Uuid) {
        return this.delete<string>(`/deps/${dep_id}/users/${user_id}`);
    }

    async list_companies(query: CompanyQuery) {
        return this.get_list<Company>("/superadmin/company", query);
    }

    async list_prices() {
        return this.get_list<Price>("/pricing");
    }

    async get_price(id: string) {
        return this.get<Price>(`/pricing/${id}`);
    }

    async create_price(title: string, description: string | null, quantity: number, price: number) {
        return this.post<Price>("/superadmin/pricing", {
            title,
            description,
            quantity,
            price,
        });
    }

    async update_price(id: string, title: string, description: string | null, quantity: number, price: number) {
        return this.put<Price>(`/superadmin/pricing/${id}`, {
            title,
            description,
            quantity,
            price,
        });
    }

    async delete_price(id: string) {
        return this.delete<null>(`/superadmin/pricing/${id}`);
    }

    async list_credits_log(query: BasicQuery) {
        return this.get_list<CreditLog>("/company/credits/log", query);
    }

    async list_users(query: UserQuery, page: number) {
        return this.get_list_paginated<User>("/users", query, page);
    }

    async list_user_deps(id: string) {
        return this.get_list<Department>(`/users/${id}/deps`);
    }

    async list_departments(query: DepartmentQuery, page: number) {
        return this.get_list_paginated<Department>("/deps", query, page);
    }

    async list_documents(query: SignDocumentQuery) {
        return this.get_list<SignDocument>("/docs", query);
    }

    async list_document_clients(id: string) {
        return this.get_list<SignDocumentClient>(`/docs/${id}/clients`);
    }

    async list_actions(user_id: Uuid, page: number) {
        return this.get_list<UserAction>(`/users/${user_id}/actions`, { page });
    }

    async list_actions_admin(page: number) {
        return this.get_list_paginated<UserAction>(`/superadmin/user_actions`, {}, page);
    }

    async list_all_actions_admin(onRes: OnResponse<UserAction>, maxPage?: number) {
        return this.get_list_all<UserAction>(`/superadmin/user_actions`, {}, onRes, maxPage);
    }

    async get_company(id: string) {
        return this.get<Company>(`/superadmin/company/${id}`);
    }

    async get_department(id: string) {
        return this.get<Department>(`/deps/${id}`);
    }

    async remove_department(id: string) {
        return this.delete<string>(`/deps/${id}`);
    }

    async get_user(id: string) {
        return this.get<User>(`/users/${id}`);
    }

    async get_user_stats(id: string) {
        return this.get<UserStats>(`/users/${id}/stats`);
    }

    async delete_user(id: string) {
        return this.delete(`/users/${id}`);
    }

    async get_document(id: string) {
        return this.get<SignDocument>(`/docs/${id}`);
    }

    async sent_docs_by_month(id: string) {
        return this.get<any>(`/superadmin/company/${id}/docs/sent`).then((data) => {
            const monthInfos: MonthInfo[] = [];

            for (const x of data.result) {
                const quantity = x[0];
                const dateRaw = x[1];
                const date = new Date(dateRaw);
                monthInfos.push({
                    quantity,
                    month: date.getMonth(),
                    year: date.getFullYear(),
                });
            }
            return monthInfos;
        });
    }

    async signed_docs_by_month(id: string) {
        return this.get<any>(`/superadmin/company/${id}/docs/signed`).then((data) => {
            const monthInfos: MonthInfo[] = [];

            for (const x of data.result) {
                const quantity = x[0];
                const dateRaw = x[1];
                const date = new Date(dateRaw);
                monthInfos.push({
                    quantity,
                    month: date.getMonth(),
                    year: date.getFullYear(),
                });
            }
            return monthInfos;
        });
    }

    async sent_docs_by_month_user() {
        return this.get<any>(`/docs/sent`).then((data) => {
            const monthInfos: MonthInfo[] = [];

            for (const x of data.result) {
                const quantity: number = x[0];
                const dateRaw: string = x[1];
                const date = new Date(dateRaw);
                monthInfos.push({
                    quantity,
                    month: date.getMonth(),
                    year: date.getFullYear(),
                });
            }
            return monthInfos;
        });
    }

    async signed_docs_by_month_user() {
        return this.get<any>(`/docs/signed`).then(
            (data) => {
                const monthInfos: MonthInfo[] = [];

                for (const x of data.result) {
                    const quantity = x[0];
                    const dateRaw = x[1];
                    const date = new Date(dateRaw);
                    monthInfos.push({
                        quantity,
                        month: date.getMonth(),
                        year: date.getFullYear(),
                    });
                }
                return monthInfos;
            },
            (err) => {
                throw err.response.data as ApiError;
            },
        );
    }

    async create_sign_mobile(payload: SignPayload, config?: CacheRequestConfig) {
        const file = payload.pdf;
        const json_data = JSON.stringify(payload);
        const formData = new FormData();
        formData.append("data", json_data);
        formData.append("pdf", file);
        return this.post<SignDocument>("/sign/sms", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async create_sign_email(payload: SignPayload, config?: CacheRequestConfig) {
        const file = payload.pdf;
        const json_data = JSON.stringify(payload);
        const formData = new FormData();
        formData.append("data", json_data);
        formData.append("pdf", file);
        return this.post<SignDocument>("/sign/email", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async general_stats() {
        return this.get<GeneralStats>(`/superadmin/general_stats`);
    }

    async download_report(year: number, month: number) {
        return this.client
            .post<Blob>(
                `/superadmin/generate_report`,
                {
                    year,
                    month,
                },
                {
                    timeout: 1000 * 60,
                    responseType: "blob",
                },
            )
            .then(
                (res) => {
                    const blob = new Blob([res.data], { type: res.data.type });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    const fileName = `MiraySign-Report-${year}-${month}.pdf`;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                },
                (err) => {
                    throw new ApiError(err.response.data);
                },
            );
    }

    async download_document(id: string, path: string, file_name: string) {
        return this.client
            .get<Blob>(`/docs/${id}/${path}`, {
                timeout: 1000 * 60,
                responseType: "blob",
            })
            .then(
                (res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", file_name); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (err) => {
                    throw new ApiError(err.response.data);
                },
            );
    }

    async download_document_original(id: string, file_name: string) {
        return this.download_document(id, "file", file_name);
    }

    async download_document_signed(id: string, file_name: string) {
        return this.download_document(id, "signed", file_name);
    }

    async download_document_evidence(id: string, file_name: string) {
        return this.download_document(id, "evidence", file_name);
    }

    async toggle_user_active(id: string) {
        return this.post<User>(`/users/${id}/toggle_active`);
    }

    async upload_logo(payload: { logo: File }, config?: CacheRequestConfig) {
        const file = payload.logo;
        const formData = new FormData();
        formData.append("logo", file);
        return this.post<null>("/company/logo", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async download_logo() {
        return this.client
            .get<Blob>(`/company/logo`, {
                timeout: 1000,
                responseType: "blob",
            })
            .then(
                (res) => {
                    const file = new Blob([res.data], { type: "image/png" });
                    return file;
                },
                (err) => {
                    throw new ApiError(err.response.data);
                },
            );
    }

    async update_reports_settings(payload: CompanyReportSettings) {
        return this.post<CompanyReportSettings>(`/company/reports/settings`, payload);
    }

    async get_reports_settings() {
        return this.get<CompanyReportSettings>(`/company/reports/settings`);
    }

    async get_smtp_settings() {
        return this.get<CompanySMTPSettings>(`/company/smtp`);
    }

    async update_smtp_settings(payload: CompanySMTPSettings) {
        return this.post<CompanySMTPSettings>(`/company/smtp`, payload);
    }

    async send_test_email(email: string) {
        return this.post<null>(`/company/smtp/test`, {
            email,
        });
    }

    async edit_send_emails(active: boolean) {
        return this.post<User>(`/account/send_emails`, { active });
    }

    async edit_balance(balance_id: Uuid, payload: EditBalance) {
        return this.post<Balance>(`/superadmin/balances/${balance_id}`, payload);
    }

    async add_balance(company_id: Uuid, payload: EditBalance) {
        return this.post<Balance>(`/superadmin/company/${company_id}/balances`, payload);
    }

    async get_document_statistics(company_id?: Uuid, config?: CacheRequestConfig) {
        return this.post<CompanyStatistic[]>(
            `/company/document_statistics`,
            {
                company_id,
            },
            {
                ...config,
            },
        );
    }

    async get_statistics(config?: CacheRequestConfig) {
        return this.get<GeneralCompanyStats>(`/company/statistics`, config);
    }

    async get_balances(
        query: {
            min_credits?: number;
            expired?: boolean;
            company_id?: Uuid;
        },
        page: number,
        config?: CacheRequestConfig,
    ) {
        return this.get_list_paginated<Balance>(`/company/balances`, query, page, config);
    }

    async list_all_balances(
        query: {
            min_credits?: number;
            expired?: boolean;
            company_id?: Uuid;
        },
        onRes: OnResponse<Balance>,
    ) {
        return this.get_list_all<Balance>(`/company/balances`, query, onRes);
    }

    async get_doc_progress(id: Uuid) {
        return this.get<SignProgress>(`/docs/${id}/progress`);
    }

    async cancel_sign(doc_id: Uuid) {
        return this.post<SignDocument>(`/sign/cancel/${doc_id}`);
    }

    async create_global_notification(message: string) {
        return this.post<{ total: number }>(`/notification`, { message });
    }

    async get_notifications(page: number) {
        return this.get_list<UserNotification>(`/notification`, { page });
    }

    async mark_notification_seen(id: Uuid) {
        return this.post<number>(`/notification/${id}/seen`);
    }

    async mark_all_notification_seen() {
        return this.post<number>(`/notification/all/seen`);
    }

    async download_custom_report(from: Date, to: Date, config?: CacheRequestConfig) {
        return this.client
            .post<Blob>(
                `/company/reports/download`,
                {
                    from,
                    to,
                },
                {
                    timeout: 10000,
                    responseType: "blob",
                    ...config,
                },
            )
            .then(
                (res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }));
                    const link = document.createElement("a");
                    link.href = url;
                    const contentDisposition = res.headers["content-disposition"];
                    let fileName = "unknown";
                    if (contentDisposition) {
                        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                        if (fileNameMatch && fileNameMatch.length === 2) fileName = fileNameMatch[1];
                    }
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                },
                (err) => {
                    throw new ApiError(err.response.data);
                },
            );
    }

    async publish_blog(payload: { title: string; slug: string; data: string; description: string }) {
        return this.post<BlogPost>(`/blog`, payload);
    }

    async update_blog_post(id: Uuid, payload: { title: string; slug: string; data: string; description: string }) {
        return this.post<BlogPost>(`/blog/${id}`, payload);
    }

    async delete_blog_post(id: Uuid) {
        return this.delete(`/blog/${id}`);
    }

    async upload_blog_image(id: Uuid, payload: { image: File }, config?: CacheRequestConfig) {
        const file = payload.image;
        const formData = new FormData();
        formData.append("image", file);
        return this.post<BlogPost>(`/blog/${id}/image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async download_blog_image(id: Uuid) {
        return this.client
            .get<Blob>(`/blog/${id}/image`, {
                timeout: 2000,
                responseType: "blob",
            })
            .then(
                (res) => {
                    const file = new Blob([res.data], { type: "image/png" });
                    return file;
                },
                (err) => {
                    throw new ApiError(err.response.data);
                },
            );
    }

    async upload_blog_content(file: File, config?: CacheRequestConfig) {
        const formData = new FormData();
        formData.append("file", file);
        return this.post<string>(`/blog/content`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async get_blog_posts() {
        return this.get_list<BlogPost>(`/blog`);
    }

    async get_blog_post(slug: string) {
        return this.get<BlogPost>(`/blog/${slug}`);
    }

    get_blog_post_cover(id: Uuid) {
        return `${this.getBaseUrl()}/blog/${id}/image`;
    }

    async list_groups(query: { all?: boolean }, page: number) {
        return this.get_list_paginated<AgendaGroup>(`/agenda/groups`, query, page);
    }

    async list_groups_all(query: { all?: boolean }, onRes: OnResponse<AgendaGroup>) {
        return this.get_list_all<AgendaGroup>(`/agenda/groups`, query, onRes);
    }

    async find_all_parent_groups(parent_id: string) {
        const groups = [];

        let current_id = parent_id;

        while (true) {
            const res = await api.group_detail(current_id);
            const group = res.result;
            groups.push(group);
            if (!group.parent_id) break;
            current_id = group.parent_id;
        }

        groups.reverse();

        return groups;
    }

    async list_clients(query: BasicQuery) {
        return this.get_list<AgendaClient>(`/agenda/clients`, query);
    }

    async list_all_clients(query: BasicQuery, onRes: OnResponse<AgendaClient>) {
        return this.get_list_all<AgendaClient>(`/agenda/clients`, query, onRes);
    }

    async group_detail(id: Uuid) {
        return this.get<AgendaGroup>(`/agenda/groups/${id}`);
    }

    async delete_group(id: Uuid) {
        return this.delete(`/agenda/groups/${id}`);
    }

    async group_subgroups(id: Uuid, query: BasicQuery) {
        return this.get_list<AgendaGroup>(`/agenda/groups/${id}/groups`, query);
    }

    async group_subgroups_all(id: Uuid, onRes: OnResponse<AgendaGroup>) {
        return this.get_list_all<AgendaGroup>(`/agenda/groups/${id}/groups`, {}, onRes);
    }

    async group_clients(id: Uuid, page: number) {
        return this.get_list_paginated<AgendaClient>(`/agenda/groups/${id}/clients`, {}, page);
    }

    async group_clients_all(id: Uuid, onRes: OnResponse<AgendaClient>) {
        return this.get_list_all<AgendaClient>(`/agenda/groups/${id}/clients`, {}, onRes);
    }

    async create_group(payload: NewAgendaGroup) {
        return this.post<AgendaGroup>(`/agenda/groups`, payload);
    }

    async update_group(id: Uuid, payload: NewAgendaGroup) {
        return this.post<AgendaGroup>(`/agenda/groups/${id}`, payload);
    }

    async client_detail(id: Uuid) {
        return this.get<AgendaClient>(`/agenda/clients/${id}`);
    }

    async create_client(groupId: Uuid, payload: NewAgendaGroup) {
        return this.post<AgendaClient>(`/agenda/groups/${groupId}/clients`, payload);
    }

    async add_clients_csv(groupId: Uuid, payload: AddClientsCsv, config?: CacheRequestConfig) {
        const file = payload.clients;
        const formData = new FormData();
        formData.append("clients", file);
        return this.post<null>(`/agenda/groups/${groupId}/clients_csv`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
            timeout: 1000 * 60,
            ...config,
        });
    }

    async update_client(clientId: Uuid, payload: NewAgendaClient) {
        return this.post<AgendaClient>(`/agenda/clients/${clientId}`, payload);
    }

    async delete_client(clientId: Uuid) {
        return this.delete<AgendaClient>(`/agenda/clients/${clientId}`);
    }

    needs_refresh() {
        const now = Date.now();
        const diff_min = (now - this.token_date) / 1000 / 60;
        return !this.has_token() || diff_min >= 10.0;
    }

    // Set the bearer token.
    set_token(token: string) {
        this.client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    save_token(token: string) {
        localStorage.setItem("token", token);
        localStorage.setItem("token_date", this.token_date.toString());
    }

    has_token() {
        return "Authorization" in this.client.defaults.headers.common;
    }

    clear_token() {
        localStorage.removeItem("token");
        localStorage.removeItem("token_date");
        delete this.client.defaults.headers.common["Authorization"];
    }

    parse_field_errors(field_errors: FieldErrors, setFieldError: (field: string, message: string | undefined) => void) {
        for (const field in field_errors) {
            const value = field_errors[field];

            switch (value) {
                case FieldError.Required: {
                    setFieldError(field, "Requerido");
                    break;
                }
                case FieldError.AlreadyExists: {
                    setFieldError(field, "Ya existe");
                    break;
                }
            }
        }
    }
}

const api = new Api();

export default api;

// The max number of objects returned by endpoints. Use this to know if a new page needs to be loaded.
export const QUERY_LIMIT = 50;
