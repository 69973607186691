import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    Container,
    Divider,
    Grid,
    Grow,
    MobileStepper,
    Paper,
    SxProps,
    Typography,
    useTheme,
} from "@mui/material";
import React from "react";
import { isDesktop, isMobile } from "react-device-detect";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import mockup1 from "../images/mockup1.webp";
import mockup2 from "../images/mockup2.webp";
import mockup3 from "../images/mockup3.webp";
import mockup4 from "../images/mockup4.webp";
import mockup5 from "../images/mockup5.webp";
import mockup6 from "../images/mockup6.webp";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const headerStyle: SxProps = {
    fontSize: {
        xs: "1.2rem",
        "@media (min-width:600px)": "2.5rem",
        md: "2.6rem",
    },
    fontWeight: 400,
    textAlign: "center",
    marginTop: "2rem",
    color: "primary.main",
} as const;

const images = [
    {
        label: "",
        title: "CREA UNA SOLICITUD DE FIRMA VÍA MÓVIL O EMAIL",
        text: "Rellenar los datos de la persona firmante, si su documento debe ser firmado por más de una persona puede añadir hasta 6 firmantes.",
        imgPath: mockup1,
    },
    {
        label: "",
        title: "FIRMA ELECTRÓNICAMENTE TU DOCUMENTO",
        text: "Una vez enviada la solicitud de firma, los firmantes recibirán un email o SMS donde podrán iniciar el proceso de firma digital.",
        imgPath: mockup2,
    },
    {
        label: "",
        title: "FIRMA ELECTRÓNICAMENTE TU DOCUMENTO",
        text: "Una vez enviada la solicitud de firma, los firmantes recibirán un email o SMS donde podrán iniciar el proceso de firma digital.",
        imgPath: mockup3,
    },
    {
        label: "",
        title: "INTRODUCE EL OTP DE FIRMA",
        text: "El usuario recibirá un SMS con el código de confirmación para completar el proceso de firma.",
        imgPath: mockup4,
    },
    {
        label: "",
        title: "OBTÉN TU DOCUMENTO FIRMADO",
        text: "El usuario recibirá un email con el documento firmado.",
        imgPath: mockup5,
    },
    {
        label: "",
        title: "DESCARGA EL CERTIFICADO DE EVIDENCIA",
        text: "Uanataca es un Prestador de Servicios de Confianza Cualificado de acuerdo al Reglamento de la Unión Europea no 910/2014 relativo a la identificación electrónica y los servicios de confianza para las transacciones electrónicas (eIDAS).",
        imgPath: mockup6,
    },
];

export default function FAQS() {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = images.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    return (
        <Container>
            <Card>
                <Grid item xs={12}>
                    <Grow in={true} style={{ transformOrigin: "1 1 1" }} timeout={1500}>
                        <Divider>
                            <Typography variant="h3" sx={headerStyle} paragraph align="center">
                                FIRMAR ELECTRÓNICAMENTE
                                <br /> EN 5 PASOS
                            </Typography>
                        </Divider>
                    </Grow>
                </Grid>
                <Container sx={{ flexGrow: 1 }}>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            margin: "auto",
                            display: "flex",
                            height: 0,
                            pl: 2,
                            bgcolor: "background.default",
                        }}
                    >
                        <Typography>{images[activeStep].label}</Typography>
                    </Paper>
                    <AutoPlaySwipeableViews
                        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                        index={activeStep}
                        onChangeIndex={handleStepChange}
                        enableMouseEvents
                        interval={5500}
                    >
                        {images.map((step, index) => (
                            <Grid
                                item
                                xs="auto"
                                key={step.label}
                                sx={{
                                    textAlign: "center",
                                }}
                            >
                                {Math.abs(activeStep - index) <= 2 ? (
                                    <Box
                                        sx={{
                                            height: { xs: 600, sm: "auto" },
                                            display: "block",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Typography
                                            variant="h4"
                                            sx={{
                                                color: "secondary.main",
                                                marginTop: "2.5rem",
                                                fontSize: "1.5rem",
                                            }}
                                        >
                                            {step.title}
                                        </Typography>
                                        <br />
                                        <Typography
                                            align="center"
                                            variant="body1"
                                            sx={{
                                                marginBottom: "1rem",
                                                marginTop: "1rem",
                                                fontSize: "1.2rem",
                                            }}
                                        >
                                            {step.text}
                                        </Typography>
                                        <br />
                                        {isDesktop && (
                                            <Grid sx={{ width: "45%" }} component="img" src={step.imgPath}></Grid>
                                        )}
                                        {isMobile && (
                                            <Grid sx={{ width: "100%" }} component="img" src={step.imgPath}></Grid>
                                        )}
                                    </Box>
                                ) : null}
                            </Grid>
                        ))}
                    </AutoPlaySwipeableViews>
                    <MobileStepper
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        nextButton={
                            <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                                Siguiente
                                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                                Anterior
                            </Button>
                        }
                    />
                </Container>
            </Card>
        </Container>
    );
}
