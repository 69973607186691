import {
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    Box,
    CardHeader,
    FormControlLabel,
    CardActions,
    SelectChangeEvent,
    Select,
    MenuItem,
} from "@mui/material";
import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ApiError, Balance, Company, CompanyStatistic, Department, User } from "../../api/interfaces";
import UserCard from "../../components/UserCard";
import * as Yup from "yup";
import { Navigate, useParams } from "react-router-dom";
import { Switch as MaterialSwitch } from "@mui/material";
import { AccordionForm, CustomFieldType } from "../../components/forms/CustomForm";
import api, { QUERY_LIMIT } from "../../api/client";
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Legend, Bar, Tooltip, BarChart } from "recharts";
import { useAuth } from "../../UseAuth";
import EditBalanceForm from "./EditBalanceForm";

export default function CompanyDetail() {
    const auth = useAuth()!;

    const { id } = useParams();

    const [company, setCompany] = useState<Company | null>(null);
    const [users, setUsers] = useState<User[]>([]);
    const [usersPage, setUsersPage] = useState(0);
    const [depsPage, setDepsPage] = useState(0);
    const [deps, setDeps] = useState<Department[]>([]);
    const [statistics, setStatistics] = useState<CompanyStatistic[]>([]);
    const [balances, setBalances] = useState<Balance[]>([]);
    const [selectedBalance, setSelectedBalance] = useState<Balance | null>(null);

    useEffect(() => {
        let active = true;

        if (auth.user && auth.isSuperUser) {
            api.get_company(id!).then((res) => {
                if (!active) return;
                setCompany(res.result);
            });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;
        if (auth.user && auth.isSuperUser && company) {
            api.list_all_balances({ min_credits: 0, expired: true, company_id: company.id }, (res) => {
                if (active) {
                    setBalances((i) => {
                        const bals = i.concat(res.result.data);
                        if (bals.length > 0) {
                            setSelectedBalance(bals[0]);
                        }
                        return bals;
                    });
                }
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, company]);

    useEffect(() => {
        if (company) {
            api.get_document_statistics(company.id)
                .then((res) => {
                    setStatistics(res.result);
                })
                .catch(() => {});
        }
    }, [company]);

    useEffect(() => {
        let active = true;

        api.list_users(
            {
                company_id: id,
            },
            usersPage,
        ).then((res) => {
            if (!active) return;

            if (usersPage === 0) setUsers(res.result.data);
            else setUsers((currentItems) => currentItems.concat(res.result.data));
            if (res.result.data.length >= QUERY_LIMIT) setUsersPage((p) => p + 1);
        });

        return () => {
            active = false;
        };
    }, [id, usersPage]);

    useEffect(() => {
        let active = true;

        api.list_departments(
            {
                company_id: id,
            },
            depsPage,
        ).then((res) => {
            if (!active) return;
            setDeps((deps) => [...deps, ...res.result.data]);

            if (depsPage === 0) setDeps(res.result.data);
            else setDeps((currentItems) => currentItems.concat(res.result.data));
            if (res.result.data.length >= QUERY_LIMIT) setDepsPage((p) => p + 1);
        });

        return () => {
            active = false;
        };
    }, [id, depsPage]);

    const totalCredits = useMemo(() => {
        return balances
            .filter((x) => {
                const date = new Date(x.expires);
                return date >= new Date();
            })
            .map((x) => x.credits)
            .reduce((a, b) => a + b, 0);
    }, [balances]);

    const totalHeldCredits = useMemo(() => {
        return balances.map((x) => x.held_credits).reduce((a, b) => a + b, 0);
    }, [balances]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    const dateIn15Days = new Date();
    dateIn15Days.setDate(dateIn15Days.getDate() + 15);

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {company && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={`Empresa ${company.name}`}
                                titleTypographyProps={{ variant: "h3" }}
                                subheader={
                                    <React.Fragment>
                                        <Box marginBottom="5px">{new Date(company.created_at).toLocaleString()}</Box>
                                    </React.Fragment>
                                }
                            ></CardHeader>
                            <CardContent>
                                {company.description !== null && company.description !== "" && (
                                    <Typography variant="body1" gutterBottom>
                                        {company.description}
                                    </Typography>
                                )}
                                <Typography variant="body1" gutterBottom>
                                    <p>Crédito restante: {totalCredits}</p>
                                    <p>Créditos en espera: {totalHeldCredits}</p>
                                </Typography>
                            </CardContent>

                            <CardActions>
                                {company && (
                                    <FormControlLabel
                                        label="Activada"
                                        control={
                                            <MaterialSwitch
                                                checked={company.active}
                                                onChange={() => {
                                                    api.set_company_active(company.id, !company.active).then((res) => {
                                                        setCompany(res.result);
                                                    });
                                                }}
                                            />
                                        }
                                    ></FormControlLabel>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <AccordionForm
                        title="Registrar Usuario"
                        fields={{
                            name: {
                                label: "Nombre",
                                type: CustomFieldType.Text,
                            },
                            email: {
                                label: "Email",
                                type: CustomFieldType.Email,
                            },
                            department_id: {
                                label: "Departamento (opcional)",
                                type: CustomFieldType.Select,
                                options: deps.map((dep) => ({
                                    value: dep.id,
                                    label: dep.name,
                                })),
                            },
                            admin: {
                                label: "Admin",
                                type: CustomFieldType.Switch,
                            },
                        }}
                        schema={Yup.object().shape({
                            name: Yup.string().required("Requerido"),
                            email: Yup.string().email("Email inválido").required("Requerido"),
                            department_id: Yup.string().nullable().optional(),
                        })}
                        initialValues={{
                            name: "",
                            email: "",
                            admin: false,
                            department_id: null as string | null,
                        }}
                        submitText="Registrar Usuario"
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.create_account(values.name, values.email, values.admin, id)
                                .then(
                                    (res) => {
                                        setMessage({
                                            message: `Usuario ${res.result.name} registrado, se ha enviado un email con la información para iniciar sesión`,
                                            type: "success",
                                        });
                                        setUsers([...users, res.result]);
                                        resetForm();
                                        if (values.department_id !== null) {
                                            api.assign_user_department(values.department_id, res.result.id)
                                                .then(
                                                    () => {},
                                                    (err: ApiError) => {
                                                        if (err.field_errors !== null) {
                                                            api.parse_field_errors(err.field_errors, setFieldError);
                                                        }
                                                        setMessage({
                                                            message: err.error,
                                                            type: "error",
                                                        });
                                                    },
                                                )
                                                .finally(() => {
                                                    setSubmitting(false);
                                                });
                                        }
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AccordionForm
                        title="Crear un departamento"
                        fields={{
                            name: {
                                label: "Nombre",
                                type: CustomFieldType.Text,
                            },
                            description: {
                                label: "Descripción",
                                type: CustomFieldType.Text,
                            },
                        }}
                        schema={Yup.object().shape({
                            name: Yup.string().required("Requerido"),
                            description: Yup.string(),
                        })}
                        initialValues={{
                            name: "",
                            description: "",
                        }}
                        submitText="Crear"
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.create_department(values.name, values.description, id)
                                .then(
                                    (res) => {
                                        setMessage({
                                            message: `Departamento creado`,
                                            type: "success",
                                        });
                                        setDeps(deps.concat(res.result));
                                        resetForm();
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AccordionForm
                        title="Añadir usuario a un departamento"
                        fields={{
                            user: {
                                label: "Usuario",
                                type: CustomFieldType.Select,
                                options: users.map((user) => ({ label: user.name, value: user.id })),
                            },
                            department: {
                                label: "Departamento",
                                type: CustomFieldType.Select,
                                options: deps.map((dep) => ({ label: dep.name, value: dep.id })),
                            },
                        }}
                        schema={Yup.object().shape({
                            department: Yup.string().required("Requerido"),
                            user: Yup.string().required("Requerido"),
                        })}
                        initialValues={{
                            department: "",
                            user: "",
                        }}
                        submitText="Añadir"
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.assign_user_department(values.department!, values.user!)
                                .then(
                                    () => {
                                        setMessage({
                                            message: `Usuario actualizado`,
                                            type: "success",
                                        });
                                        resetForm();
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => {
                                    setSubmitting(false);
                                });
                        }}
                    />
                </Grid>

                {company && (
                    <Grid item xs={12}>
                        <AccordionForm
                            title="Crear una cartera"
                            fields={{
                                amount: {
                                    label: "Créditos",
                                    type: CustomFieldType.Number,
                                },
                                expires: {
                                    label: "Expira",
                                    type: CustomFieldType.DatePicker,
                                },
                            }}
                            schema={Yup.object().shape({
                                amount: Yup.number().required("Requerido"),
                                expires: Yup.date().required("Requerido"),
                            })}
                            initialValues={{
                                amount: 5,
                                expires: dateIn15Days,
                            }}
                            submitText="Crear"
                            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                api.add_balance(company.id, values)
                                    .then(
                                        (res) => {
                                            setMessage({
                                                message: `Cartera creada`,
                                                type: "success",
                                            });
                                            setBalances((i) => i.concat(res.result));
                                            resetForm();
                                        },
                                        (err: ApiError) => {
                                            if (err.field_errors !== null) {
                                                api.parse_field_errors(err.field_errors, setFieldError);
                                            }
                                            setMessage({
                                                message: err.error,
                                                type: "error",
                                            });
                                        },
                                    )
                                    .finally(() => setSubmitting(false));
                            }}
                        />
                    </Grid>
                )}

                {company && (
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4" gutterBottom>
                                    Selecciona una cartera para editarla
                                </Typography>
                                <Select
                                    sx={{ marginBottom: "1em" }}
                                    value={selectedBalance ? selectedBalance.id : ""}
                                    onChange={(event: SelectChangeEvent<string>) => {
                                        const balance = balances.find((x) => x.id === event.target.value);
                                        if (balance) setSelectedBalance(balance);
                                    }}
                                    style={{ minWidth: "25%" }}
                                >
                                    {balances.map((x) => (
                                        <MenuItem key={x.id} value={x.id}>
                                            Créditos: {x.credits}, expira el {new Date(x.expires).toLocaleString()}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {selectedBalance && (
                                    <EditBalanceForm
                                        balance={selectedBalance}
                                        onUpdate={function (balance: Balance): void {
                                            setBalances((bals) =>
                                                bals.map((x) => {
                                                    if (x.id == balance.id) return balance;
                                                    return x;
                                                }),
                                            );
                                        }}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                )}

                {users.map((user) => (
                    <Grid item xs={12} sm={4} key={user.id}>
                        <UserCard user={user} />
                    </Grid>
                ))}

                {statistics && statistics.length > 0 && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Estadísticas" titleTypographyProps={{ variant: "h4" }} />
                            <CardContent sx={{ overflowX: "auto" }}>
                                <ResponsiveContainer height={300}>
                                    <BarChart data={statistics.map((x) => ({ Mes: x.month, Firmas: x.value }))}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="Mes" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Firmas" fill="#1565c0" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
