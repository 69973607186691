import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Department } from "../api/interfaces";

interface Props {
    dep: Department;
    deleteButtonText?: string;
}

export default function DepartmentCard(props: Props) {
    const navigate = useNavigate();

    return (
        <Card
            sx={{ height: "100%", cursor: "pointer" }}
            onClick={() => {
                navigate(`/deps/${props.dep.id}`);
            }}
        >
            <CardHeader
                title={props.dep.name}
                subheader={"Creado " + new Date(props.dep.created_at).toLocaleString()}
            ></CardHeader>
            <CardContent>
                <Typography>{props.dep.description}</Typography>
            </CardContent>
        </Card>
    );
}
