import { Container, Grid, Card, CardContent, Typography, Chip, Box, CardHeader, CardActions } from "@mui/material";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import api from "../api/client";
import { User, UserStats } from "../api/interfaces";
import ConfirmButton from "../components/ConfirmButton";
import { useAuth } from "../UseAuth";

export default function UserDetail() {
    const auth = useAuth()!;
    const [user, setUser] = useState<User | null>(null);
    const [stats, setStats] = useState<UserStats | null>(null);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        let active = true;

        if (auth.user) {
            api.get_user(id!)
                .then((res) => {
                    if (!active) return;
                    setUser(res.result);
                })
                .then(() => {
                    api.get_user_stats(id!).then((res) => {
                        if (!active) return;
                        setStats(res.result);
                    });
                });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user]);

    if (!auth.user) {
        return <Navigate to="/login" />;
    }

    const isAdmin = auth.isSuperUser ? true : (auth.user as User).admin;

    if (user === null) {
        return (
            <Container sx={{ flexGrow: 1, marginTop: "5rem" }}>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="60%" />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {user !== null && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader
                                title={`Usuario ${user.name}`}
                                titleTypographyProps={{ variant: "h3" }}
                                subheader={
                                    <React.Fragment>
                                        <Box marginBottom="5px">
                                            Usuario creado el {new Date(user.created_at).toLocaleString()}
                                        </Box>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexWrap: "wrap",
                                                "& > *": {
                                                    margin: (theme) => theme.spacing(0.5),
                                                },
                                            }}
                                        >
                                            {user.admin && <Chip color="primary" label="admin" />}
                                        </Box>
                                    </React.Fragment>
                                }
                            ></CardHeader>
                            <CardContent>
                                <Typography variant="body1">
                                    <b>Email: </b>
                                    {user.email}
                                </Typography>
                                <Typography variant="body1">
                                    <b>Activo: </b>
                                    {user.active ? "Sí" : "No"}
                                </Typography>
                                {stats && (
                                    <Typography variant="body1">
                                        <b>Departamentos: </b>
                                        {stats.departments_in.join(", ")}
                                    </Typography>
                                )}
                            </CardContent>
                            <CardActions>
                                {isAdmin && (
                                    <React.Fragment>
                                        <ConfirmButton
                                            reversible
                                            onDelete={() => {
                                                api.toggle_user_active(user.id).then(() => {
                                                    setUser({
                                                        ...user,
                                                        active: !user.active,
                                                    });
                                                });
                                            }}
                                        >
                                            {user.active && "Desactivar"}
                                            {!user.active && "Activar"}
                                        </ConfirmButton>
                                        <ConfirmButton
                                            onDelete={() => {
                                                api.delete_user(user.id).then(() => {
                                                    navigate("/users", { replace: true });
                                                });
                                            }}
                                        >
                                            Eliminar Permanentemente
                                        </ConfirmButton>
                                    </React.Fragment>
                                )}
                            </CardActions>
                        </Card>
                    </Grid>
                )}
                {stats && (
                    <React.Fragment>
                        <Grid item xs={12} md={6} sm={6}>
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom align="center">
                                        Documentos enviados
                                    </Typography>
                                    <Typography variant="h4" fontWeight="bold" align="center" color="#3f51b5">
                                        {stats.documents_sent}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
        </Container>
    );
}
