import { CardContent, Card, Container } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";

export default function TermsAndConditions() {
    return (
        <Container sx={{ flexGrow: 1 }}>
            <Helmet>
                <title>Términos y condiciones - Miray Sign</title>
                <meta
                    name="description"
                    content="TÉRMINOS Y CONDICIONES DEL SERVICIO DE FIRMA ONE-SHOT DE MIRAY CONSULTING"
                />
            </Helmet>
            <Card>
                <CardContent>
                    <p>
                        <strong>
                            <u>TÉRMINOS Y CONDICIONES DEL SERVICIO DE FIRMA ONE-SHOT DE MIRAY CONSULTING</u>
                        </strong>
                    </p>
                    <ol type="1">
                        <li>
                            <p>
                                <strong>OBJETO</strong>
                            </p>
                            <ol type="1">
                                <li>
                                    <p>
                                        Miray Consulting S.L., en adelante “MIRAY”, como Prestador de Servicios de firma
                                        electrónica, provista de NIF nºB64242613 y con domicilio fiscal en Calle
                                        Berruguete nº66, 08035 de Barcelona, España, establece las siguientes
                                        condiciones generales de uso respecto del servicio de Firma electrónica.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Uanataca S.A, en adelante “UANATACA”, como Prestador de Servicios de
                                        Certificación y de Confianza, provista de NIF nºA66721499 y con domicilio fiscal
                                        en Riera de Can Todà nº24, 08024 de Barcelona, España, establece las siguientes
                                        condiciones generales de uso respecto del servicio de Firma electrónica.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Estos términos y condiciones rigen el uso del servicio de Firma electrónica de
                                        MIRAY y en uso del servicio de certificación y confianza de UANATACA en lo
                                        sucesivo “el SERVICIO”, el cual se compone de la variante:
                                    </p>
                                    <ul>
                                        <li>
                                            <p>
                                                Firma electrónica, basada en la firma con un código de un solo uso, en
                                                firma manuscrita y con evidencia electrónica.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <strong>ALCANCE</strong>
                            </p>
                            <ol type="1">
                                <li>
                                    <p>
                                        Estos términos condiciones del SERVICIO se aplican a todos los clientes,
                                        usuarios y terceros que adquieran el servicio de Firma electrónica de MIRAY, ya
                                        sea contratando directamente con MIRAY o a través de un tercero, en adelante
                                        denominados indistintamente “el CLIENTE” o “CLIENTES”.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Al contratar y/o utilizar el SERVICIO, el CLIENTE acepta y está obligado a
                                        cumplir estos términos y condiciones.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <strong>CONDICIONES DE USO</strong>
                            </p>
                            <ol type="1">
                                <li>
                                    <p>
                                        El SERVICIO será usado para la realización de firmas electrónicas de acuerdo con
                                        la modalidad escogida. Dicho procedimiento de uso responde al siguiente esquema
                                        de actuación:
                                    </p>
                                </li>
                            </ol>
                            <ol type="a">
                                <li>
                                    <p>Firma electrónica</p>
                                    <ul>
                                        <li>
                                            <p>
                                                A través del servicio se le envía una solicitud de firma de un documento
                                                a uno o más firmantes, los cuales reciben un enlace URL por SMS o email
                                                para visualizar el documento a firmar. Una vez abierto el enlace los
                                                firmantes ven el documento y aceptan los términos y condiciones del
                                                servicio y a través de un código OTP enviado por SMS a su número de
                                                teléfono móvil, firman el documento de forma manuscrita y recibiendo una
                                                evidencia electrónica.
                                            </p>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <strong>LÍMITES DE USO Y OBLIGACIONES DE LOS CLIENTES</strong>
                            </p>
                            <ol type="1">
                                <li>
                                    <p>
                                        El CLIENTE deberá utilizar el SERVICIO exclusivamente para el envío de
                                        documentación y firma de documentos de acuerdo con la modalidad escogida.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE queda obligado a usar de manera correcta EL SERVICIO, sin contravenir
                                        lo dispuesto en las presentes condiciones de uso así como en la legislación
                                        aplicable, siendo totalmente responsable de cualquier acto u omisión que realice
                                        como consecuencia del uso contraviniendo las mismas.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE no podrá enviar documentación, archivos, o cualesquiera medios
                                        disponibles que puedan infringir un daño a terceros a través del uso del
                                        SERVICIO. De la misma manera, queda vetado el envío de documentación y/o
                                        archivos que, puedan afectar al rendimiento o a la propia prestación del
                                        SERVICIO de MIRAY.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE debe enviar documentos que puedan ser firmados mediante el SERVICIO
                                        de MIRAY, de tal manera que será responsable de la documentación que envía al
                                        SERVICIO, eximiendo a MIRAY de cualquier error sobre la subida de documentos de
                                        forma errónea, falsa o fraudulenta, recayendo bajo su responsabilidad cualquier
                                        daño que pueda infringir a MIRAY o a Terceros.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE del SERVICIO no podrá realizar actos de ingeniería inversa sobre el
                                        mismo, siendo responsable ante MIRAY de dichos actos.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE actuará de manera diligente en el caso de encontrar un fallo o
                                        vulnerabilidad en el SERVICIO, y no se aprovechará dicha vulnerabilidad para
                                        realizar actos ilícitos, debiendo avisar a MIRAY en la mayor brevedad posible.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Comunicar a MIRAY si otro tercero de mala fe está utilizando el SERVICIO en
                                        nombre del Cliente para realizar actos ilícitos o dañar el SERVICIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        El CLIENTE se obliga, entre otros, a: (I) no enviar al SERVICIO para firmar
                                        electrónicamente cualquier tipo de documento que incurra en cualquier tipo de
                                        conducta ilícita; (II) no enviar al SERVICIO para firmar de manera electrónica
                                        cualquier documento o material que pueda infringir los derechos de propiedad
                                        intelectual e industrial o cualquier otro tipo de derecho de terceros,
                                        incluyendo aquellos derechos reconocidos en leyes de patentes, marcas, derechos
                                        de autor o secretos industriales; (III) no enviar al SERVICIO para su firma
                                        electrónica cualquier documento que contenga o incluya cualquier tipo de virus,
                                        troyanos, gusanos, time bombs, o cualquier otro tipo de programa pernicioso;
                                        (IV) no incumplir cualquier ley, reglamento o regulación relativa a la
                                        transmisión de datos técnicos o de software exportados desde el territorio de la
                                        Unión Europea o cualquier otro territorio aduanero de acuerdo al domicilio del
                                        CLIENTE, o por cualquier otro criterio que resulte aplicable; (V) no interferir
                                        o interrumpir cualquier red a través del uso del SERVICIO, o infringir
                                        regulaciones, políticas, procedimientos, términos o condiciones con el uso de
                                        dicha red; (VI) no intentar conseguir acceso no autorizado a documentos o
                                        cualquier otro material enviado al SERVICIO por cualquier otro usuario,
                                        ordenador, sistema o red conectada o relacionada con el SERVICIO mediante el uso
                                        de extracción de contraseñas o cualquier otro medio; (VII) y no interferir o
                                        interrumpir de forma alguna el uso del SERVICIO por cualquier otro usuario.
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p>
                                <strong>LIMITES A LA RESPONSABILIDAD DE MIRAY y UANATACA</strong>
                            </p>
                            <ol type="1">
                                <li>
                                    <p>
                                        Se determina por parte de MIRAY y UANATACA, a modo enunciativo y no limitativo
                                        supuestos bajo los cuales no será responsable en ningún caso:
                                    </p>
                                </li>
                            </ol>
                            <ol type="a">
                                <li>
                                    <p>
                                        Del uso fraudulento, negligente o abusivo del SERVICIO por parte del CLIENTE y/o
                                        de la información o documentos gestionados a través del mismo.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De la puesta a disposición en el SERVICIO de documentación que no sea adecuada
                                        para la firma y que por ello el destinatario no pueda firmar de forma válida el
                                        documento.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De cualquier perjuicio generado a terceros como consecuencia del uso de la
                                        información y/o documentación obtenida a través del SERVICIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De los problemas técnicos generados en el SERVICIO por parte del CLIENTE,
                                        siempre que estos deriven de actuaciones de este último, sin perjuicio de la
                                        obligación de MIRAY de mantener operativo el servicio conforme el acuerdo de
                                        nivel de servicio pactado.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De los posibles errores o daños producidos por la negligencia o mala fe en el
                                        uso del SERVICIO por parte del CLIENTE.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De los errores, retrasos en el acceso por parte del CLIENTE a la hora de
                                        utilizar el SERVICIO, ya sea en la introducción de datos, carga de documentos o
                                        firma de los mismos, así como de recepción por parte de los destinatarios del
                                        documento o cualquier demora, fallo o anomalía que pueda surgir cuando estas
                                        incidencias sean debidas a acciones del Usuario o Cliente, problemas de
                                        Internet, causas fortuitas o fuerza mayor u otra contingencia imprevisible.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De los fallos e incidencias que pudieran producirse en las comunicaciones,
                                        borrado o transmisiones incompletas ante caídas de la red u otro tipo de
                                        situaciones de las que MIRAY no es directamente responsable.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        De la producción de cualquier tipo de daño que el CLIENTE o terceros pudiesen
                                        ocasionar en el SERVICIO.
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Frente a tratamiento de datos que no hayan sido previamente informados o sobre
                                        los que no se hayan adoptado las medidas pertinentes cuando el responsable y/o
                                        encargado de dicho tratamiento sea un tercero.
                                    </p>
                                </li>
                            </ol>
                            <ol start={2} type="1">
                                <li>
                                    <p>
                                        Cuando se detecte cualquier situación en la que el Usuario y/o Cliente no hayan
                                        actuado diligentemente y/o se hayan aprovechado de las funcionalidades del
                                        SERVICIO de tal manera que haya generado un perjuicio a MIRAY o a terceros,
                                        MIRAY se reserva el derecho a retirar el acceso al SERVICIO sin previo aviso y
                                        de forma definitiva o temporal hasta el aseguramiento de la efectiva
                                        responsabilidad de los daños que pudieran producirse cuando se detecte cualquier
                                        situación en la que el Usuario y/o Cliente no hayan actuado diligentemente y/o
                                        se hayan aprovechado de las funcionalidades del SERVICIO de tal manera que haya
                                        generado un perjuicio a MIRAY o a terceros.
                                    </p>
                                </li>
                            </ol>
                        </li>
                    </ol>
                </CardContent>
            </Card>
        </Container>
    );
}
