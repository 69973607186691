import React, { useEffect, useState } from "react";
import {
    Container,
    Card,
    CardContent,
    Typography,
    Grid,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Button,
    Divider,
    Stepper,
    Step,
    StepLabel,
    Box,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Slide,
    Alert,
    AlertTitle,
} from "@mui/material";
import { AgendaClient, AgendaGroup, User } from "../api/interfaces";
import { Link, Navigate } from "react-router-dom";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import SignForm from "../components/forms/SignForm";
import api from "../api/client";
import * as Yup from "yup";
import { isMobile } from "react-device-detect";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import PHONE_PREFIXES from "../phone_prefixes";
import ClearIcon from "@mui/icons-material/Clear";
import SelectAgendaForm from "../components/forms/SelectAgendaForm";

enum State {
    SelectType,
    SelectMethod,
    SelectContacts,
    FillForm,
    Finished,
}

type Props = {
    onNextStep?: () => void;
    onPreviousStep?: () => void;
    step: State;
};

export type SendContact = {
    name: string;
    email: string;
    company_name: string | null;
    dni: string | null;
    dni_type: number | null;
    phone: string;
    group_name?: string;
    is_manual?: boolean;
};

function SignContainer(props: React.PropsWithChildren<Props>) {
    return (
        <Container>
            <Helmet>
                <title>Firmar - Miray Sign</title>
            </Helmet>
            <Card
                sx={{
                    maxWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    minWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    alignContent: "center",
                }}
            >
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Crear una solicitud de firma
                    </Typography>
                    <Grid container gap={2}>
                        {props.children}

                        <Grid item xs={12}>
                            <Divider sx={{ marginY: 2 }} />
                            <Stepper activeStep={props.step}>
                                <Step>
                                    <StepLabel>Seleccionar el tipo de envío</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Seleccionar el método de envío</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Seleccionar o añadir clientes</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Subir un documento y enviar</StepLabel>
                                </Step>
                                <Step>
                                    <StepLabel>Petición enviada</StepLabel>
                                </Step>
                            </Stepper>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex" }}>
                            {props.onPreviousStep && (
                                <Button variant="outlined" onClick={() => props.onPreviousStep!()}>
                                    Atrás
                                </Button>
                            )}
                            <Box sx={{ flexGrow: 1 }}></Box>
                            {props.onNextStep && (
                                <Button variant="outlined" onClick={() => props.onNextStep!()}>
                                    {props.step == State.Finished ? "Enviar otro documento" : "Siguiente"}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Container>
    );
}

export default function SignRequest() {
    const auth = useAuth()!;
    const [currentState, setCurrentState] = useState(State.SelectType);
    const [sendTogether, setSendTogether] = useState(true);
    const [sendViaEmail, setSendViaEmail] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    // All contacts
    const [contacts, setContacts] = useState<readonly AgendaClient[]>([]);
    // All groups
    const [groups, setGroups] = useState<readonly AgendaGroup[]>([]);
    // Selected contacts to send petition.
    const [sendContacts, setSendContacts] = useState<SendContact[]>([]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_all_clients({}, (res) => {
                if (active && !contacts) {
                    setContacts((i) => i.concat(res.result.data));
                }
            });
        }

        return () => {
            active = false;
        };
    }, [contacts, auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_groups_all({ all: true }, (res) => {
                if (active) {
                    setGroups((i) => i.concat(res.result.data));
                }
            });
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_all_clients({}, (res) => {
                if (active) {
                    setContacts((i) => i.concat(res.result.data));
                }
            });
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    if (auth.company && (!auth.company.logo_edited || !auth.company.email_edited)) {
        return (
            <Container>
                <Helmet>
                    <title>Firmar - Miray Sign</title>
                </Helmet>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                maxWidth: (theme) => ({
                                    [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                                }),
                                minWidth: (theme) => ({
                                    [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                                }),
                                alignContent: "center",
                            }}
                        >
                            <CardContent>
                                <Typography variant="h4" gutterBottom>
                                    Crear una solicitud de firma
                                </Typography>

                                {!auth.company.logo_edited && (
                                    <Alert severity="warning" sx={{ marginBottom: 2 }}>
                                        <AlertTitle>Falta subir el logo de la empresa</AlertTitle>
                                        Para subir el logo de la empresa, diríjase a este <Link to="/logo">enlace</Link>
                                        .
                                    </Alert>
                                )}
                                {!auth.company.email_edited && (
                                    <Alert severity="warning">
                                        <AlertTitle>Falta configurar el email</AlertTitle>
                                        Falta configurar el servidor SMTP para poder enviar emails, por favor diríjase a
                                        este <Link to="/smtp">enlace</Link> para completar la configuración.
                                    </Alert>
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
        );
    }

    switch (currentState) {
        case State.SelectType:
            return (
                <SignContainer step={currentState} onNextStep={() => setCurrentState(State.SelectMethod)}>
                    <Grid item xs={12}>
                        <Slide key={"selecttype"} in={true} direction="left" timeout={250}>
                            <div>
                                <Typography>
                                    Primero hay que seleccionar el tipo de envio, para ello tienes disponible dos formas
                                    de hacerlo:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography>
                                            <strong>Una única peticion de firma:</strong>
                                        </Typography>
                                        <Typography>
                                            Se envía solo una petición con un máximo de 6 firmantes.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            <strong>Una petición por cada firmante:</strong>
                                        </Typography>
                                        <Typography>
                                            Se envían varias peticiones independientes entre sí a cada firmante. No hay
                                            límite de firmantes.
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </Slide>
                    </Grid>
                    <Grid item xs={12}>
                        <Slide key={"selecttype2"} in={true} direction="left" timeout={250}>
                            <FormControl fullWidth>
                                <InputLabel id="select-type-label">Tipo de envio</InputLabel>
                                <Select
                                    labelId="select-type-label"
                                    value={sendTogether ? 1 : 0}
                                    label="Tipo de envio"
                                    onChange={(e) => setSendTogether(e.target.value == 1)}
                                >
                                    <MenuItem value={1}>Una única petición de firma (máximo 6 firmantes).</MenuItem>
                                    <MenuItem value={0}>Una petición de firma por cada firmante.</MenuItem>
                                </Select>
                            </FormControl>
                        </Slide>
                    </Grid>
                </SignContainer>
            );
            break;
        case State.SelectMethod:
            return (
                <SignContainer
                    step={currentState}
                    onNextStep={() => setCurrentState(State.SelectContacts)}
                    onPreviousStep={() => setCurrentState(State.SelectType)}
                >
                    <Grid item xs={12}>
                        <Slide key={"select-method"} in={true} direction="left" timeout={250} appear>
                            <div>
                                <Typography>Selecciona el método de envío:</Typography>
                                <ul>
                                    <li>
                                        <Typography>
                                            <strong>Mediante email</strong>
                                        </Typography>
                                        <Typography>
                                            Se envía la petición de firma mediante email y al acabar el proceso, el
                                            cliente recibirá una copia del documento firmado.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            <strong>Mediante SMS:</strong>
                                        </Typography>
                                        <Typography>
                                            Se envía la petición de firma mediante SMS. Al acabar el proceso, si no se
                                            especifica un email en el formulario final, no recibirá una copia del
                                            documento firmado.
                                        </Typography>
                                    </li>
                                </ul>
                            </div>
                        </Slide>
                    </Grid>
                    <Grid item xs={12}>
                        <Slide key={"select-method-form"} in={true} direction="left" timeout={250}>
                            <FormControl fullWidth>
                                <InputLabel id="select-type-label">Método de envío</InputLabel>
                                <Select
                                    labelId="select-type-label"
                                    value={sendViaEmail ? 1 : 0}
                                    label="Método de envío"
                                    onChange={(e) => setSendViaEmail(e.target.value == 1)}
                                >
                                    <MenuItem value={1}>Email</MenuItem>
                                    <MenuItem value={0}>SMS</MenuItem>
                                </Select>
                            </FormControl>
                        </Slide>
                    </Grid>
                </SignContainer>
            );
            break;
        case State.SelectContacts:
            return (
                <SignContainer
                    step={currentState}
                    onNextStep={() => {
                        if (sendContacts.length == 0) {
                            setErrorMessage("Tiene que haber al menos un firmante.");
                        } else if (sendTogether && sendContacts.length > 6) {
                            setErrorMessage(
                                "Solo puede haber un máximo de 6 firmantes al enviar una única petición de firma.",
                            );
                        } else {
                            setErrorMessage("");
                            setCurrentState(State.FillForm);
                        }
                    }}
                    onPreviousStep={() => setCurrentState(State.SelectMethod)}
                >
                    <Grid item xs={12}>
                        <Slide key={"add-contacts-accordions"} in={true} direction="left" timeout={250}>
                            <div>
                                <Accordion>
                                    <AccordionSummary>
                                        <Typography variant="h5">Añadir un cliente manualmente</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <CustomForm
                                            submitText="Añadir cliente"
                                            fields={{
                                                name: {
                                                    type: CustomFieldType.Text,
                                                    label: "Nombre*",
                                                    sm: 6,
                                                },
                                                surname: {
                                                    type: CustomFieldType.Text,
                                                    label: "Apellido*",
                                                    sm: 6,
                                                },
                                                company_name: {
                                                    type: CustomFieldType.Text,
                                                    label: "Empresa",
                                                },
                                                phone_prefix: {
                                                    type: CustomFieldType.Autocomplete,
                                                    label: "Prefijo",
                                                    autoComplete: PHONE_PREFIXES.countries,
                                                    getOptionLabel: (option) => {
                                                        return `${option.dial_code} - ${option.name_es}`;
                                                    },
                                                    sm: 4,
                                                    xs: 12,
                                                },
                                                phone: {
                                                    type: CustomFieldType.Text,
                                                    label: "Teléfono móvil*",
                                                    sm: 8,
                                                    xs: 12,
                                                },
                                                email: {
                                                    type: CustomFieldType.Email,
                                                    label: isMobile
                                                        ? "Email (si no se especifica no recibirá el documento firmado)"
                                                        : "Email*",
                                                },
                                                dni_type: {
                                                    type: CustomFieldType.Select,
                                                    label: "Tipo de documento identificativo",
                                                    sm: 4,
                                                    xs: 12,
                                                    options: [
                                                        {
                                                            label: "DNI",
                                                            value: 0,
                                                        },
                                                        {
                                                            label: "Otro",
                                                            value: 1,
                                                        },
                                                    ],
                                                },
                                                dni: {
                                                    type: CustomFieldType.Text,
                                                    label: "Valor del documento identificativo",
                                                    xs: 12,
                                                    sm: 8,
                                                },
                                            }}
                                            schema={Yup.object().shape({
                                                name: Yup.string().required("Requerido"),
                                                surname: Yup.string().required("Requerido"),
                                                phone: Yup.string()
                                                    .test({
                                                        name: "phone_correct_test",
                                                        test: (value: any) => {
                                                            return /^\d{9,10}$/.test(value);
                                                        },
                                                        message: "No es un teléfono válido",
                                                    })
                                                    .required("Requerido"),
                                                email: isMobile
                                                    ? Yup.string().email("No es un email válido").nullable().optional()
                                                    : Yup.string().email("No es un email válido").required("Requerido"),
                                                company_name: Yup.string().optional(),
                                                dni: Yup.string()
                                                    .optional()
                                                    .when(["dni_type"], {
                                                        is: (dni_type: number) => dni_type === 0,
                                                        then: Yup.string()
                                                            .optional()
                                                            .test({
                                                                name: "dni_test",
                                                                test: (value: any) => {
                                                                    if (!value || value === "") return true;
                                                                    const DNI_REGEX = /^(\d{8})([A-Z])$/;
                                                                    if (!DNI_REGEX.test(value)) return false;
                                                                    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                                                                    const letter = dni_letters.charAt(
                                                                        parseInt(value, 10) % 23,
                                                                    );
                                                                    return letter == value.charAt(8);
                                                                },
                                                                message: "No es un DNI válido",
                                                            }),
                                                    }),
                                                dni_type: Yup.number().min(0).max(1),
                                            })}
                                            initialValues={{
                                                name: "",
                                                surname: "",
                                                email: "",
                                                company_name: "",
                                                dni: "",
                                                dni_type: 0,
                                                phone: "",
                                                phone_prefix: PHONE_PREFIXES.countries[201],
                                            }}
                                            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                                values.name += " " + values.surname;
                                                values.phone = values.phone_prefix.dial_code + values.phone;
                                                const contact = values as SendContact;
                                                contact.is_manual = true;
                                                setSendContacts((c) => c.concat(contact));
                                                setSubmitting(false);
                                                resetForm();
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion>
                                    <AccordionSummary>
                                        <Typography variant="h5">Añadir un cliente desde la agenda</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <SelectAgendaForm
                                            contacts={contacts}
                                            groups={groups}
                                            addContactFunction={(agendaContacts) => {
                                                setSendContacts((c) => {
                                                    const manual_contacts = c.filter((x) => x.is_manual);
                                                    const newContacts = manual_contacts.concat(
                                                        agendaContacts.map((x) => ({
                                                            company_name: x.company_name || "",
                                                            dni: x.dni,
                                                            dni_type: x.dni_type,
                                                            name: x.name,
                                                            email: x.email,
                                                            phone: x.phone,
                                                        })),
                                                    );
                                                    return newContacts;
                                                });
                                            }}
                                        />
                                    </AccordionDetails>
                                </Accordion>

                                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                                <Typography variant="h5" gutterBottom sx={{ marginTop: 2 }}>
                                    Clientes a enviar
                                </Typography>
                                <Box sx={{ display: "flex", height: "100%" }}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <TableContainer component={Paper}>
                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Nombre</TableCell>
                                                        <TableCell align="left">Email</TableCell>
                                                        <TableCell align="left">Teléfono</TableCell>
                                                        <TableCell align="left">DNI</TableCell>
                                                        <TableCell align="left">Grupo Agenda</TableCell>
                                                        <TableCell>Borrar</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {sendContacts.map((row, i) => (
                                                        <TableRow key={i}>
                                                            <TableCell align="left">{row.name}</TableCell>
                                                            <TableCell align="left">{row.email}</TableCell>
                                                            <TableCell align="left">{row.phone}</TableCell>
                                                            <TableCell align="left">{row.dni}</TableCell>
                                                            <TableCell align="left">{row.group_name}</TableCell>
                                                            <TableCell padding="checkbox">
                                                                {row.is_manual && (
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setSendContacts((x) =>
                                                                                x.filter((_, ii) => ii !== i),
                                                                            );
                                                                        }}
                                                                    >
                                                                        <ClearIcon color="primary" />
                                                                    </IconButton>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Box>
                            </div>
                        </Slide>
                    </Grid>
                    {errorMessage.length > 0 && (
                        <Grid item xs={12}>
                            <Alert severity="error">
                                <Typography>{errorMessage}</Typography>
                            </Alert>
                        </Grid>
                    )}
                </SignContainer>
            );
        case State.FillForm:
            return (
                <SignContainer step={currentState} onPreviousStep={() => setCurrentState(State.SelectContacts)}>
                    <Slide key={"sign-form"} in={true} direction="left" timeout={250}>
                        <div>
                            <SignForm
                                user={auth.user as User}
                                isMobile={!sendViaEmail}
                                sendContacts={sendContacts}
                                sendTogether={sendTogether}
                                onSuccess={() => {
                                    setCurrentState(State.Finished);
                                }}
                            />
                        </div>
                    </Slide>
                </SignContainer>
            );
            break;
        case State.Finished:
            return (
                <SignContainer
                    step={currentState}
                    onNextStep={() => {
                        setCurrentState(State.SelectType);
                        setSendContacts([]);
                        setSendTogether(true);
                    }}
                >
                    <Grid item xs={12}>
                        <Slide key={"selecttype2"} in={true} direction="left" timeout={250}>
                            <div>
                                <Alert severity="success">Su petición de firma enviada correctamente.</Alert>
                            </div>
                        </Slide>
                    </Grid>
                </SignContainer>
            );
    }
}
