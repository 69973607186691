import { Button, CardContent, CircularProgress, Typography } from "@mui/material";
import { Container, Grid, Card } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Company, CompanyQuery } from "../../api/interfaces";
import { Navigate } from "react-router-dom";
import api, { QUERY_LIMIT } from "../../api/client";
import { useAuth } from "../../UseAuth";
import CompanyCard from "../../components/CompanyCard";

export default function ListCompanies() {
    const auth = useAuth()!;
    const [downloading, setDownloading] = useState(false);
    const [page, setPage] = useState(0);
    const [companies, setCompanies] = useState<Company[]>([]);

    useEffect(() => {
        if (auth.user && auth.isSuperUser) {
            api.list_companies({ page } as CompanyQuery).then((res) => {
                if (page === 0) setCompanies(res.result.data);
                else setCompanies((c) => c.concat(res.result.data));
                if (res.result.data.length >= QUERY_LIMIT) setPage((p) => p + 1);
            });
        }
    }, [auth.user, auth.isSuperUser, page]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Lista de empresas
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Descargar Reporte de empresas
                            </Typography>
                            <Button
                                sx={{ position: "relative" }}
                                color="primary"
                                variant="contained"
                                disabled={downloading}
                                onClick={() => {
                                    setDownloading(true);
                                    const date = new Date();
                                    api.download_report(date.getFullYear(), date.getMonth() + 1).finally(() => {
                                        setDownloading(false);
                                    });
                                }}
                            >
                                Descargar último mes
                                {downloading && (
                                    <CircularProgress
                                        sx={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            marginTop: -12,
                                            marginLeft: -12,
                                        }}
                                        size={24}
                                    />
                                )}
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {companies.map((company) => (
                    <Grid item key={company.id} xs={12} sm={6}>
                        <CompanyCard company={company} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
