import { Grid } from "@mui/material";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

interface Props<Item> {
    items: Item[];
    hasMore: boolean;
    renderItem: (item: Item) => JSX.Element;
    onLoadMoreItems: () => void;
}

export default function ItemList<Item>(props: Props<Item>) {
    return (
        <InfiniteScroll
            style={{ overflow: "hidden", padding: "2px" }}
            dataLength={props.items.length}
            next={() => {
                props.onLoadMoreItems();
            }}
            hasMore={props.hasMore}
            loader={<React.Fragment></React.Fragment>}
        >
            <Grid container spacing={2}>
                {props.items.map((item, i) => (
                    <Grid item xs={12} sm={6} md={4} key={i}>
                        {props.renderItem(item)}
                    </Grid>
                ))}
            </Grid>
        </InfiniteScroll>
    );
}
