import { Container, Card, CardContent, Typography, Grid, CardHeader } from "@mui/material";
import React from "react";
import contactImage from "../images/contacto3.webp";

import { Helmet } from "react-helmet-async";
import ContactForm from "../components/forms/ContactForm";

export default function ContactUs() {
    return (
        <Container sx={{ flexGrow: 1 }}>
            <Helmet>
                <title>Contactar - Miray Sign</title>
                <meta name="description" content="Aquí puedes ponerte en contacto con nosotros para cualquier duda." />
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Contacta con nosotros" titleTypographyProps={{ variant: "h4" }} />
                        <CardContent>
                            <Typography paragraph>
                                Aquí puedes ponerte en contacto con nosotros para cualquier duda.
                            </Typography>

                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <ContactForm />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <img width="100%" src={contactImage}></img>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
