import {
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../UseAuth";
import MUIRichTextEditor, { TAsyncAtomicBlockResponse, TMUIRichTextEditorRef } from "mui-rte";
import { convertToRaw } from "draft-js";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import * as Yup from "yup";
import { Box } from "@mui/system";
import BackupIcon from "@mui/icons-material/Backup";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AttachFileIcon from "@mui/icons-material/AttachFile";

interface IUploadImagePopoverProps {
    anchor: TAnchor;
    onSubmit: (data: TUploadImageData, insert: boolean) => void;
}

type TUploadImagePopoverState = {
    anchor: TAnchor;
    isCancelled: boolean;
};

type TUploadImageData = {
    file?: File;
};

type TAnchor = HTMLElement | null;

const uploadImageToServer = (file: File) => {
    return api.upload_blog_content(file).then((res) => `${api.getBaseUrl()}/blog/content/${res.result}`);
};

const uploadImage = (file: File) => {
    return new Promise<TAsyncAtomicBlockResponse>(async (resolve) => {
        const url = await uploadImageToServer(file);
        resolve({
            data: {
                url: url,
                width: 400,
                alignment: "left", // or "center", "right"
                type: "image", // or "video"
            },
        });
    });
};

const UploadImagePopover: React.FC<IUploadImagePopoverProps> = (props) => {
    const [state, setState] = useState<TUploadImagePopoverState>({
        anchor: null,
        isCancelled: false,
    });
    const [data, setData] = useState<TUploadImageData>({});

    useEffect(() => {
        setState({
            anchor: props.anchor,
            isCancelled: false,
        });
        setData({
            file: undefined,
        });
    }, [props.anchor]);

    return (
        <Popover
            anchorEl={state.anchor}
            open={state.anchor !== null}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
        >
            <Grid container spacing={1} sx={{ padding: "10px", maxWidth: 350 }}>
                <Grid item xs={10}>
                    <TextField
                        fullWidth
                        disabled
                        value={data.file?.name || ""}
                        placeholder="Click icon to attach image"
                    />
                </Grid>
                <Grid item xs={2}>
                    <Box
                        component="input"
                        accept="image/*"
                        sx={{ display: "none" }}
                        id="contained-button-file"
                        type="file"
                        onChange={(event: React.FormEvent<HTMLInputElement>) => {
                            setData({
                                ...data,
                                file: event.currentTarget.files![0],
                            });
                        }}
                    />
                    <label htmlFor="contained-button-file">
                        <IconButton color="primary" aria-label="upload image" component="span">
                            <AttachFileIcon />
                        </IconButton>
                    </label>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <Button
                        onClick={() => {
                            setState({
                                anchor: null,
                                isCancelled: true,
                            });
                        }}
                    >
                        <CloseIcon />
                    </Button>
                    <Button
                        onClick={() => {
                            setState({
                                anchor: null,
                                isCancelled: false,
                            });
                            props.onSubmit(data, !state.isCancelled);
                        }}
                    >
                        <DoneIcon />
                    </Button>
                </Grid>
            </Grid>
        </Popover>
    );
};

export default function BlogEditor() {
    const auth = useAuth()!;
    const { slug: pathSlug } = useParams();
    const [id, setId] = useState<string | null>(null);
    const [defaultData, setDefaultData] = useState<any | undefined>(undefined);
    const [initialValues, setInitialValues] = useState({
        title: "",
        description: "",
        slug: "",
        image: null as File | null,
    });
    const [data, setData] = useState<any | undefined>(undefined);
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const navigate = useNavigate();

    const ref = useRef<TMUIRichTextEditorRef>(null);

    const handleFileUpload = (file: File) => {
        ref.current?.insertAtomicBlockAsync("IMAGE", uploadImage(file), "Subiendo la imagen...");
    };

    useEffect(() => {
        let active = true;

        if (pathSlug && auth.user && auth.isSuperUser) {
            api.get_blog_post(pathSlug).then((res) => {
                if (active) {
                    setDefaultData(res.result.data);
                    setInitialValues({
                        ...res.result,
                        image: null,
                    });
                    setId(res.result.id);
                }
            });
        }

        return () => {
            active = false;
        };
    }, [pathSlug, auth.user, auth.isSuperUser]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container maxWidth="xl" sx={{ display: "flex" }}>
            <Card sx={{ width: "100%", minHeight: "80vh" }}>
                <CardContent>
                    <Typography variant="h2" gutterBottom>
                        {pathSlug ? "Editar" : "Crear"} una articulo en el blog
                    </Typography>
                    <CustomForm
                        fields={{
                            title: {
                                label: "Título*",
                                type: CustomFieldType.Text,
                            },
                            slug: {
                                label: "Slug (url)*",
                                type: CustomFieldType.Text,
                                InputProps: {
                                    startAdornment: <InputAdornment position="start">/blog/</InputAdornment>,
                                },
                            },
                            description: {
                                label: "Descripción*",
                                type: CustomFieldType.Text,
                                multiline: true,
                            },
                            image: {
                                type: CustomFieldType.File,
                                label: "Portada Imagen*",
                                accept: "image/*",
                            },
                        }}
                        initialValues={initialValues}
                        onSubmit={(values, setSubmitting, setFieldError, setMessage) => {
                            if (pathSlug && id) {
                                // update
                                api.update_blog_post(id, {
                                    title: values.title,
                                    slug: values.slug,
                                    description: values.description,
                                    data,
                                }).then(
                                    (res) => {
                                        api.upload_blog_image(res.result.id, {
                                            image: values.image!,
                                        }).then((res) => {
                                            navigate(`/blog/${res.result.slug}`, { replace: true });
                                        });
                                    },
                                    (err) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                );
                            } else {
                                api.publish_blog({
                                    title: values.title,
                                    slug: values.slug,
                                    description: values.description,
                                    data,
                                }).then(
                                    (res) => {
                                        if (values.image !== null) {
                                            api.upload_blog_image(res.result.id, {
                                                image: values.image,
                                            }).then((res) => {
                                                navigate(`/blog/${res.result.slug}`, { replace: true });
                                            });
                                        } else {
                                            navigate(`/blog/${res.result.slug}`, { replace: true });
                                        }
                                    },
                                    (err) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                );
                            }
                        }}
                        schema={Yup.object().shape({
                            title: Yup.string().required("Requerido"),
                            description: Yup.string().required("Requerido"),
                            slug: Yup.string().required("Requerido"),
                            image:
                                id === null
                                    ? Yup.mixed().nullable().required("Requerido")
                                    : Yup.mixed().nullable().optional(),
                        })}
                        submitText={pathSlug ? "Editar" : "Publicar"}
                    />
                    <UploadImagePopover
                        anchor={anchor}
                        onSubmit={(data, insert) => {
                            if (insert && data.file) {
                                handleFileUpload(data.file);
                            }
                            setAnchor(null);
                        }}
                    />
                    <MUIRichTextEditor
                        label="Empieza a escribir aquí..."
                        defaultValue={defaultData}
                        onSave={(data) => {
                            setData(data);
                        }}
                        onChange={(state) => {
                            setData(JSON.stringify(convertToRaw(state.getCurrentContent())));
                        }}
                        controls={[
                            "title",
                            "bold",
                            "italic",
                            "underline",
                            "link",
                            "numberList",
                            "bulletList",
                            "quote",
                            "code",
                            "clear",
                            "media",
                            "upload-image",
                            "strikethrough",
                            "highlight",
                        ]}
                        customControls={[
                            {
                                name: "upload-image",
                                icon: <BackupIcon />,
                                type: "callback",
                                onClick: (_editorState, _name, anchor) => {
                                    setAnchor(anchor);
                                },
                            },
                        ]}
                        draftEditorProps={{
                            handleDroppedFiles: (_selectionState, files) => {
                                if (files.length && (files[0] as File).name !== undefined) {
                                    handleFileUpload(files[0] as File);
                                    return "handled";
                                }
                                return "not-handled";
                            },
                        }}
                        ref={ref}
                    />
                </CardContent>
            </Card>
        </Container>
    );
}
