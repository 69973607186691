import { Accordion, AccordionDetails, AccordionSummary, Box, CardHeader } from "@mui/material";
import { Container, Grid, Card, CardContent, Typography, Divider, Chip, Button, CardActions } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Department, SignDocument, SignDocumentClient, SignProgress, User } from "../api/interfaces";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserCard from "../components/UserCard";
import DepartmentCard from "../components/DepartmentCard";
import { Alert, Skeleton } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@mui/lab";
import api from "../api/client";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";

function is_x_days_old(d1: Date, days: number) {
    const diff = Math.abs(new Date().getTime() - d1.getTime());
    return diff > 1000 * 60 * 60 * 24 * days;
}

export default function DocumentDetail() {
    const auth = useAuth()!;
    const [loading, setloading] = useState(true);
    const [doc, setDoc] = useState<SignDocument | null>(null);
    const [docClients, setDocClients] = useState<SignDocumentClient[]>([]);
    const [user, setUser] = useState<User | null>(null);
    const [department, setDepartment] = useState<Department | null>(null);
    const [signProgress, setSignProgress] = useState<SignProgress | null>(null);
    const { id } = useParams();

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser) {
            api.get_document(id!).then((res) => {
                setloading(false);
                if (!active) return;
                setDoc(res.result);
            });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser) {
            api.list_document_clients(id!).then((res) => {
                if (!active) return;
                setDocClients(res.result.data);
            });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser) {
            api.get_doc_progress(id!).then((res) => {
                if (!active) return;
                setSignProgress(res.result);
            });
        }

        return () => {
            active = false;
        };
    }, [id, auth.user, auth.isSuperUser]);

    useEffect(() => {
        if (doc == null || doc.user_id == null) return;
        let active = true;

        api.get_user(doc.user_id).then((res) => {
            if (!active) return;
            setUser(res.result);
        });

        return () => {
            active = false;
        };
    }, [doc]);

    useEffect(() => {
        if (doc == null || doc.department_id == null) return;
        let active = true;
        api.get_department(doc.department_id).then((res) => {
            if (!active) return;
            setDepartment(res.result);
        });

        return () => {
            active = false;
        };
    }, [doc]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    if (loading) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="60%" />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                {doc !== null && (
                    <React.Fragment>
                        <Helmet>
                            <title>Documento - Miray Sign</title>
                        </Helmet>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader
                                    title={doc.title}
                                    titleTypographyProps={{ variant: "h3" }}
                                    subheader={
                                        <React.Fragment>
                                            <Box marginBottom="5px">
                                                {"Creado " + new Date(doc.created_at).toLocaleString()}
                                            </Box>
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    "& > *": {
                                                        margin: (theme) => theme.spacing(0.5),
                                                    },
                                                }}
                                            >
                                                {doc.is_signed && <Chip size="small" color="primary" label="Firmado" />}
                                                {!doc.is_signed && !doc.canceled_at && (
                                                    <Chip size="small" color="warning" label="No Firmado" />
                                                )}
                                                {!doc.is_via_email && (
                                                    <Chip size="small" color="primary" label="Via SMS" />
                                                )}
                                                {doc.is_via_email && (
                                                    <Chip size="small" color="primary" label="Via Email" />
                                                )}
                                                {doc.canceled_at !== null && (
                                                    <Chip size="small" color="error" label="Cancelado" />
                                                )}
                                            </Box>
                                        </React.Fragment>
                                    }
                                ></CardHeader>
                                <CardContent>
                                    {doc.comment && doc.comment.length > 0 && (
                                        <React.Fragment>
                                            <Typography>{doc.comment}</Typography>
                                            <br />
                                        </React.Fragment>
                                    )}

                                    <Typography>
                                        <br />
                                        {doc.signed_at && (
                                            <React.Fragment>
                                                <b>Firmado el </b> {new Date(doc.signed_at).toLocaleString()}
                                            </React.Fragment>
                                        )}
                                    </Typography>

                                    <br />

                                    {docClients.map((client) => (
                                        <Accordion key={client.idx}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel-client-content"
                                                id="panel-client-header"
                                            >
                                                <Typography>
                                                    Información del cliente <b>{client.name}</b>
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Typography>
                                                    <b>Teléfono móvil: </b> {client.phone}
                                                    <br />
                                                    {client.company_name && (
                                                        <React.Fragment>
                                                            <b>Empresa: </b> {client.company_name}
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {client.email && (
                                                        <React.Fragment>
                                                            <b>Email: </b> {client.email}
                                                            <br />
                                                        </React.Fragment>
                                                    )}
                                                    {client.dni && client.dni.length > 0 && (
                                                        <React.Fragment>
                                                            <b>Tipo de documento identificativo: </b>{" "}
                                                            {["DNI", "Otro"][client.dni_type || 0]}
                                                            <br />
                                                            <b>Documento Identificativo: </b> {client.dni}
                                                        </React.Fragment>
                                                    )}
                                                    {client.signed && (
                                                        <React.Fragment>
                                                            <br />
                                                            <Chip size="small" color="primary" label="Ha firmado" />
                                                        </React.Fragment>
                                                    )}
                                                </Typography>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}

                                    <br />

                                    {(user !== null || department !== null) && (
                                        <React.Fragment>
                                            <Divider />
                                            <br />
                                        </React.Fragment>
                                    )}

                                    <Grid container spacing={2}>
                                        {user !== null && (
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Creado por</Typography>
                                                <UserCard user={user} />
                                            </Grid>
                                        )}
                                        {department !== null && (
                                            <Grid item xs={12} sm={6}>
                                                <Typography variant="h6">Departamento</Typography>
                                                <DepartmentCard dep={department} />
                                            </Grid>
                                        )}
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ marginTop: "20px" }}>
                                    {!is_x_days_old(new Date(doc.created_at), 7) && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    api.download_document_original(doc.id, doc.file_name);
                                                }}
                                            >
                                                Descargar Documento
                                            </Button>

                                            {doc.is_signed && (
                                                <React.Fragment>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            api.download_document_evidence(
                                                                doc.id,
                                                                "evidence-" + doc.file_name,
                                                            );
                                                        }}
                                                    >
                                                        Descargar Evidencia
                                                    </Button>
                                                    <Button
                                                        color="primary"
                                                        onClick={() => {
                                                            api.download_document_signed(
                                                                doc.id,
                                                                "signed-" + doc.file_name,
                                                            );
                                                        }}
                                                    >
                                                        Descargar Documento Firmado
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    )}
                                    {is_x_days_old(new Date(doc.created_at), 7) && !doc.is_signed && (
                                        <Alert severity="warning">
                                            Ya no se puede descargar el documento porque han pasado 7 días.
                                        </Alert>
                                    )}
                                    {is_x_days_old(new Date(doc.created_at), 7) && doc.is_signed && (
                                        <React.Fragment>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    api.download_document_evidence(doc.id, "evidence-" + doc.file_name);
                                                }}
                                            >
                                                Descargar Evidencia
                                            </Button>
                                            <Alert severity="warning">
                                                Ya no se puede descargar el documento original y el firmado porque han
                                                pasado 7 días.
                                            </Alert>
                                        </React.Fragment>
                                    )}
                                    {!doc.is_signed && doc.canceled_at !== null && (
                                        <Alert severity="warning">La petición ha sido cancelada.</Alert>
                                    )}
                                    {!doc.is_signed && doc.canceled_at === null && (
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                api.cancel_sign(doc.id).then((res) => {
                                                    setDoc(res.result);
                                                });
                                            }}
                                        >
                                            Cancelar Petición
                                        </Button>
                                    )}
                                </CardActions>
                            </Card>
                        </Grid>
                        {doc && signProgress && (
                            <Grid item xs={12}>
                                <Card>
                                    <CardHeader
                                        title="Progreso de la firma"
                                        titleTypographyProps={{ variant: "h4" }}
                                    ></CardHeader>
                                    <CardContent>
                                        <Timeline position="alternate">
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineDot
                                                        color={signProgress.sign_request_created ? "primary" : "grey"}
                                                    />
                                                    <TimelineConnector />
                                                </TimelineSeparator>
                                                <TimelineContent>Petición de firma creada.</TimelineContent>
                                            </TimelineItem>
                                            {doc.is_via_email &&
                                                docClients.map((client, i) => (
                                                    <React.Fragment key={i}>
                                                        <TimelineItem>
                                                            <TimelineSeparator>
                                                                <TimelineDot
                                                                    color={
                                                                        signProgress.emails_sent[i] ? "primary" : "grey"
                                                                    }
                                                                />
                                                                <TimelineConnector />
                                                            </TimelineSeparator>
                                                            <TimelineContent>
                                                                El cliente {client.name} ha recibido el email.
                                                            </TimelineContent>
                                                        </TimelineItem>
                                                        <TimelineItem>
                                                            <TimelineSeparator>
                                                                <TimelineDot
                                                                    color={
                                                                        signProgress.clients_signed[i]
                                                                            ? "primary"
                                                                            : "grey"
                                                                    }
                                                                />
                                                                <TimelineConnector />
                                                            </TimelineSeparator>
                                                            <TimelineContent>
                                                                El cliente {client.name} ha firmado.
                                                                <br />
                                                                {client.signed_at
                                                                    ? new Date(client.signed_at).toLocaleString()
                                                                    : ""}
                                                            </TimelineContent>
                                                        </TimelineItem>
                                                    </React.Fragment>
                                                ))}
                                            {!doc.is_via_email &&
                                                signProgress.clients_signed.map((value, i) => (
                                                    <TimelineItem key={i}>
                                                        <TimelineSeparator>
                                                            <TimelineDot color={value ? "primary" : "grey"} />
                                                            <TimelineConnector />
                                                        </TimelineSeparator>
                                                        <TimelineContent>
                                                            El cliente{" "}
                                                            {(docClients.length - 1 >= i && docClients[i].name) ||
                                                                `número ${i + 1}`}{" "}
                                                            ha firmado.
                                                        </TimelineContent>
                                                    </TimelineItem>
                                                ))}
                                            <TimelineItem>
                                                <TimelineSeparator>
                                                    <TimelineDot color={doc.is_signed ? "primary" : "grey"} />
                                                </TimelineSeparator>
                                                <TimelineContent>Proceso de firma completado</TimelineContent>
                                            </TimelineItem>
                                        </Timeline>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )}
                    </React.Fragment>
                )}
            </Grid>
        </Container>
    );
}
