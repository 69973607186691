import { FieldErrors } from "./interfaces";

export class ApiError extends Error {
    public code: number;
    public error: string;
    public field_errors: FieldErrors | null;

    constructor(data: { code: number; field_errors: FieldErrors | null; error: string }) {
        super(data.error);
        this.name = "ApiError";
        this.code = data.code;
        this.error = data.error;
        this.field_errors = data.field_errors;
    }

    get_error_fields() {
        if (!this.field_errors) return "";
        const fields = [];

        for (const field in this.field_errors) {
            fields.push(field);
        }

        return fields.join(", ");
    }

    get_error_msg() {
        return {
            message: `${this.error} : ${this.get_error_fields()}`,
            type: "error" as const,
        };
    }
}
