import React from "react";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

type Props = {
    settings: {
        email: string;
        daily: boolean;
        weekly: boolean;
        monthly: boolean;
        yearly: boolean;
    };
    onUpdate: (settings: { email: string; daily: boolean; weekly: boolean; monthly: boolean; yearly: boolean }) => void;
};

export default function UpdateReportsIntervalForm(props: Props) {
    return (
        <CustomForm
            fields={{
                email: {
                    type: CustomFieldType.Email,
                    label: "Email",
                },
                daily: {
                    type: CustomFieldType.Switch,
                    label: "Enviar diariamente",
                },
                weekly: {
                    type: CustomFieldType.Switch,
                    label: "Enviar semanalmente",
                },
                monthly: {
                    type: CustomFieldType.Switch,
                    label: "Enviar mensualmente",
                },
                yearly: {
                    type: CustomFieldType.Switch,
                    label: "Enviar anualmente",
                },
            }}
            initialValues={props.settings}
            schema={Yup.object().shape({
                email: Yup.string().email().required("Requerido"),
                daily: Yup.boolean(),
                weekly: Yup.boolean(),
                monthly: Yup.boolean(),
                yearly: Yup.boolean(),
            })}
            submitText="Actualizar"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.update_reports_settings(values)
                    .then(
                        (report_settings) => {
                            resetForm();
                            setMessage({
                                message: "Configuración actualizada",
                                type: "success",
                            });
                            props.onUpdate(report_settings.result);
                        },
                        (err) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
