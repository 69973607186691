import { Container, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import ForgotPasswordForm from "../components/forms/ForgotPasswordForm";

export default function ForgotPassword() {
    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Recupar cuenta - Miray Sign</title>
            </Helmet>
            <Card
                sx={{
                    maxWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    minWidth: (theme) => ({
                        [theme.breakpoints.up("md")]: theme.breakpoints.values.md,
                    }),
                    alignContent: "center",
                }}
            >
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Recuperar la cuenta de usuario
                    </Typography>

                    <ForgotPasswordForm />
                </CardContent>
            </Card>
        </Container>
    );
}
