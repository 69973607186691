import React from "react";
import PHONE_PREFIXES from "../../phone_prefixes";
import { AccordionForm, CustomFieldType } from "./CustomForm";
import * as Yup from "yup";
import structuredClone from "@ungap/structured-clone";
import api from "../../api/client";
import { AgendaClient, ApiError } from "../../api/interfaces";

type Props = {
    group_id: string;
    onNewContact: (contact: AgendaClient) => void;
};

export default function AgendaContactForm(props: Props) {
    return (
        <AccordionForm
            title="Añadir contacto"
            fields={{
                name: {
                    type: CustomFieldType.Text,
                    label: "Nombre*",
                    sm: 6,
                },
                surname: {
                    type: CustomFieldType.Text,
                    label: "Apellido*",
                    sm: 6,
                },
                company_name: {
                    type: CustomFieldType.Text,
                    label: "Empresa",
                },
                phone_prefix: {
                    type: CustomFieldType.Autocomplete,
                    label: "Prefijo",
                    autoComplete: PHONE_PREFIXES.countries,
                    getOptionLabel: (option) => {
                        return `${option.dial_code} - ${option.name_es}`;
                    },
                    sm: 4,
                    xs: 12,
                },
                phone: {
                    type: CustomFieldType.Text,
                    label: "Teléfono móvil*",
                    sm: 8,
                    xs: 12,
                },
                email: {
                    type: CustomFieldType.Email,
                    label: "Email*",
                },
                dni_type: {
                    type: CustomFieldType.Select,
                    label: "Tipo de documento identificativo",
                    sm: 4,
                    xs: 12,
                    options: [
                        {
                            label: "DNI",
                            value: 0,
                        },
                        {
                            label: "Otro",
                            value: 1,
                        },
                    ],
                },
                dni: {
                    type: CustomFieldType.Text,
                    label: "Valor del documento identificativo",
                    xs: 12,
                    sm: 8,
                },
            }}
            initialValues={{
                name: "",
                surname: "",
                email: "",
                company_name: "",
                dni: "",
                dni_type: 0,
                phone: "",
                phone_prefix: PHONE_PREFIXES.countries[201],
            }}
            schema={Yup.object().shape({
                name: Yup.string().required("Requerido"),
                surname: Yup.string().required("Requerido"),
                phone: Yup.string()
                    .test({
                        name: "phone_correct_test",
                        test: (value: any) => {
                            return /^\d{9,10}$/.test(value);
                        },
                        message: "No es un teléfono válido",
                    })
                    .required("Requerido"),
                email: Yup.string().email("No es un email válido").required("Requerido"),
                company_name: Yup.string().optional(),
                dni: Yup.string()
                    .optional()
                    .when(["dni_type"], {
                        is: (dni_type: number) => dni_type === 0,
                        then: Yup.string()
                            .optional()
                            .test({
                                name: "dni_test",
                                test: (value: any) => {
                                    if (!value || value === "") return true;
                                    const DNI_REGEX = /^(\d{8})([A-Z])$/;
                                    if (!DNI_REGEX.test(value)) return false;
                                    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                                    const letter = dni_letters.charAt(parseInt(value, 10) % 23);
                                    return letter == value.charAt(8);
                                },
                                message: "No es un DNI válido",
                            }),
                    }),
                dni_type: Yup.number().min(0).max(1),
            })}
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                const client = structuredClone(values);
                client.name += " " + client.surname;
                client.phone = client.phone_prefix.dial_code + client.phone;

                api.create_client(props.group_id, client)
                    .then(
                        (res) => {
                            resetForm();
                            setMessage({
                                message: `Contacto creado`,
                                type: "success",
                            });
                            props.onNewContact(res.result);
                        },
                        (err: ApiError) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                    });
            }}
            submitText="Crear"
        />
    );
}
