import { FormControl, FormHelperText, Button } from "@mui/material";
import React, { useState } from "react";

interface Props {
    error: boolean | undefined;
    helperText: any;
    label: string;
    name: string;
    value?: string | number | readonly string[] | undefined;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
    accept?: string | undefined;
    disabled?: boolean;
}

export default function FileInput(props: Props) {
    const [fileName, setFileName] = useState<string | null>(null);
    const uploadInputRef = React.createRef<HTMLInputElement>();

    return (
        <FormControl fullWidth>
            <input
                ref={uploadInputRef}
                type="file"
                hidden
                id={props.name}
                name={props.name}
                accept={props.accept}
                onChange={(e: any) => {
                    props.setFieldValue(props.name, e.currentTarget.files[0]);
                    setFileName(e.currentTarget.files[0].name);
                }}
                onBlur={props.onBlur}
            />
            <Button
                disabled={props.disabled}
                variant="outlined"
                onClick={() => {
                    uploadInputRef.current && uploadInputRef.current.click();
                }}
            >
                {fileName ? `Cargado '${fileName}'` : `Cargar ${props.label}`}
            </Button>
            <FormHelperText error={props.error}>{props.helperText}</FormHelperText>
        </FormControl>
    );
}
