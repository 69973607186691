import { Alert, AlertTitle, CardHeader, Grid } from "@mui/material";
import { Container, Card, CardContent, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import api from "../api/client";
import { Balance, CompanyStatistic, GeneralCompanyStats, User, UserNotification } from "../api/interfaces";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import logoBlue from "../images/logo_blue.webp";
import { useAuth } from "../UseAuth";
import { format } from "date-fns";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function HomeAuthed() {
    const auth = useAuth()!;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [logo, setLogo] = useState<Blob | null>(null);
    const [statistics, setStatistics] = useState<CompanyStatistic[]>([]);
    const [generalStatistics, setGeneralStatistics] = useState<GeneralCompanyStats | null>(null);
    const [notifications, setNotifications] = useState<UserNotification[]>([]);
    const [balances, setBalances] = useState<Balance[]>([]);

    useEffect(() => {
        if (auth.user && !auth.isSuperUser) {
            api.get_notifications(0)
                .then((res) => {
                    setNotifications(res.result.data.filter((x) => x.global && !x.seen));
                })
                .catch(() => {});
        }
    }, [auth.user, auth.isSuperUser]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_all_balances({ min_credits: 0, expired: true }, (res) => {
                if (active) {
                    setBalances((i) => i.concat(res.result.data));
                }
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    useEffect(() => {
        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.get_document_statistics()
                .then((res) => {
                    // the api returns a string for dates.
                    setStatistics(
                        res.result.map((x) => ({
                            ...x,
                            month: new Date(x.month),
                        })),
                    );
                })
                .catch(() => {});
        }
    }, [auth.user, auth.isSuperUser]);

    useEffect(() => {
        if (auth.user && !auth.isSuperUser) {
            api.get_statistics()
                .then((res) => {
                    setGeneralStatistics(res.result);
                })
                .catch(() => {});
        }
    }, [auth.user, auth.isSuperUser]);

    const totalCredits = useMemo(() => {
        return balances
            .filter((x) => {
                const date = new Date(x.expires);
                return date >= new Date();
            })
            .map((x) => x.credits)
            .reduce((a, b) => a + b, 0);
    }, [balances]);

    const totalHeldCredits = useMemo(() => {
        return balances.map((x) => x.held_credits).reduce((a, b) => a + b, 0);
    }, [balances]);

    if (!auth.user || auth.isSuperUser) {
        return null;
    }

    return (
        <Container>
            <Helmet>
                <title>Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                {notifications.map((notif) => (
                    <Grid item key={notif.id} xs={12}>
                        <Alert
                            severity="info"
                            onClose={() => {
                                api.mark_notification_seen(notif.id).then(() => {
                                    setNotifications((notifications) => notifications.filter((x) => x.id !== notif.id));
                                });
                            }}
                        >
                            {notif.message}
                        </Alert>
                    </Grid>
                ))}
                <Grid item xs={12} sm={logo ? 8 : 12}>
                    <Card sx={{ height: "100%" }}>
                        <CardContent sx={{ height: "100%" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={4}>
                                    <Typography
                                        variant="h3"
                                        gutterBottom
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                        }}
                                    >
                                        <img width="100%" src={logoBlue} alt="Miray Sign"></img>
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={8}>
                                    <Typography
                                        variant="h5"
                                        fontWeight="bold"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            height: "100%",
                                        }}
                                        align="center"
                                    >
                                        El servicio más sencillo para recoger firmas de tus usuarios
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                {auth.company && (
                    <React.Fragment>
                        {!auth.company.logo_edited && (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle>Falta subir el logo de la empresa</AlertTitle>
                                    Para subir el logo de la empresa, diríjase a este <Link to="/logo">enlace</Link>.
                                </Alert>
                            </Grid>
                        )}
                        {!auth.company.email_edited && (
                            <Grid item xs={12}>
                                <Alert severity="warning">
                                    <AlertTitle>Falta configurar el email</AlertTitle>
                                    Falta configurar el servidor SMTP para poder enviar emails, por favor diríjase a
                                    este <Link to="/smtp">enlace</Link> para completar la configuración.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12} md={6} sm={6}>
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom align="center">
                                        Firmas restantes
                                    </Typography>
                                    <Typography variant="h3" fontWeight="bold" align="center" color="#4caf50">
                                        {totalCredits}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom align="center">
                                        Firmas en espera
                                    </Typography>
                                    <Typography variant="h3" fontWeight="bold" align="center" color="#3f51b5">
                                        {totalHeldCredits}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/*<Grid item xs={12} md={6} sm={6}>
                            <Card sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography variant="h5" gutterBottom align="center">
                                        Firmas restantes expiran el
                                    </Typography>
                                    <Typography variant="h4" fontWeight="bold" align="center" color="#3f51b5">
                                        {new Date(auth.company.balance_expire_date).toLocaleString()}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid> */}
                        {generalStatistics && (
                            <React.Fragment>
                                <Grid item xs={12} md={4} sm={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom align="center">
                                                Documentos creados
                                            </Typography>
                                            <Typography variant="h4" fontWeight="bold" align="center" color="#3f51b5">
                                                {generalStatistics.documents}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} md={4} sm={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom align="center">
                                                Documentos Firmados
                                            </Typography>
                                            <Typography variant="h4" fontWeight="bold" align="center" color="#3fb559">
                                                {generalStatistics.total_signed_documents}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={4} sm={4}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom align="center">
                                                Firmas realizadas
                                            </Typography>
                                            <Typography variant="h4" fontWeight="bold" align="center" color="#3fb559">
                                                {generalStatistics.total_signatures}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom align="center">
                                                Documentos cancelados
                                            </Typography>
                                            <Typography variant="h4" fontWeight="bold" align="center" color="#b53f3f">
                                                {generalStatistics.total_canceled}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid item xs={12} md={6} sm={6}>
                                    <Card sx={{ height: "100%" }}>
                                        <CardContent>
                                            <Typography variant="h5" gutterBottom align="center">
                                                Usuarios Registrados
                                            </Typography>
                                            <Typography variant="h4" fontWeight="bold" align="center" color="#3f51b5">
                                                {generalStatistics.users}
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}

                {statistics && statistics.length > 0 && (
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Firmas por mes" titleTypographyProps={{ variant: "h4" }} />
                            <CardContent sx={{ overflowX: "auto" }}>
                                <ResponsiveContainer height={300}>
                                    <BarChart data={statistics.map((x) => ({ Mes: x.month, Firmas: x.value }))}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis
                                            dataKey="Mes"
                                            tickFormatter={(date) => {
                                                return format(new Date(date), "MM/yyyy");
                                            }}
                                        />
                                        <YAxis />
                                        <Tooltip labelFormatter={(value) => format(new Date(value), "MM/yyyy")} />
                                        <Legend />
                                        <Bar dataKey="Firmas" fill="#1565c0" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
