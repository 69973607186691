import React from "react";
import { Container } from "@mui/material";
import { Card, CardContent } from "@mui/material";
import { Navigate } from "react-router-dom";
import { ApiError } from "./api/interfaces";
import * as Yup from "yup";
import CustomForm, { CustomFieldType } from "./components/forms/CustomForm";
import { useAuth } from "./UseAuth";
import api from "./api/client";
import { Helmet } from "react-helmet-async";

const LoginSchema = Yup.object().shape({
    email: Yup.string().email("Email inválido").required("Requerido"),
    password: Yup.string().required("Requerido"),
});

export default function LoginSuper() {
    const auth = useAuth()!;

    if (auth.user) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Entrar - Miray Sign</title>
            </Helmet>
            <Card
                sx={{
                    maxWidth: (theme) => ({
                        sm: theme.breakpoints.values.sm,
                    }),
                    alignContent: "center",
                }}
            >
                <CardContent>
                    <CustomForm
                        fields={{
                            email: {
                                type: CustomFieldType.Email,
                                label: "Email",
                            },
                            password: {
                                type: CustomFieldType.Password,
                                label: "Contraseña",
                            },
                        }}
                        onSubmit={(values, setSubmitting, setFieldError, setMessage) => {
                            api.login(values.email, values.password, true)
                                .then(
                                    (res) => {
                                        setMessage({
                                            message: `Bienvenido ${res.result.name}!`,
                                            type: "success",
                                        });
                                        setTimeout(() => {
                                            // This will trigger a re-render so redirection is handled.
                                            auth.login();
                                        }, 1000);
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        schema={LoginSchema}
                        submitText="Entrar como superadmin"
                    />
                </CardContent>
            </Card>
        </Container>
    );
}
