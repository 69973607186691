import { Card, CardContent, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { useAuth } from "../UseAuth";
import { User } from "../api/interfaces";

interface Props {
    title: React.ReactNode;
    links: {
        title: string;
        href?: string;
        onClick?: () => void;
        icon: React.ReactNode;
        admin?: boolean;
    }[];
    md?: number;
    superuser?: boolean;
}

export default function MenuConfig(props: Props) {
    const auth = useAuth()!;
    const navigate = useNavigate();

    if (!auth.user || (props.superuser && !auth.isSuperUser)) {
        return <Navigate to="/" />;
    }

    const user = auth.user as User;

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Typography align="center" variant="h3">
                {props.title}
            </Typography>
            <Grid container spacing={2} sx={{ marginTop: "1rem" }} justifyContent="center">
                {props.links
                    .filter((x) => user.admin || !x.admin)
                    .map((x, i) => (
                        <Grid item xs={12} sm={6} md={(props.md as any) || 4} key={i}>
                            <Card
                                onClick={
                                    typeof x.href !== "undefined"
                                        ? () => navigate(x.href!, { replace: true })
                                        : () => x.onClick!()
                                }
                                sx={{ ":hover": { cursor: "pointer" } }}
                            >
                                <CardContent>
                                    <Typography align="center" variant="h5">
                                        {x.title}
                                    </Typography>
                                    <Box justifyContent="center" alignItems="center" display="flex" marginTop="2rem">
                                        {x.icon}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
            </Grid>
        </Container>
    );
}
