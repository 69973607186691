import React from "react";
import api from "../../api/client";
import { CompanySMTPSettings, SmtpRelayMode } from "../../api/interfaces";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

type Props = {
    settings: CompanySMTPSettings;
    onEdited: (settings: CompanySMTPSettings) => void;
};

export default function UpdateSMTPForm(props: Props) {
    return (
        <CustomForm
            fields={{
                domain: {
                    type: CustomFieldType.Text,
                    label: "Dominio*",
                },
                secure_mode: {
                    type: CustomFieldType.Select,
                    label: "Modo TLS/SSL",
                    options: [
                        {
                            value: SmtpRelayMode.Tls,
                            label: "TLS",
                        },
                        {
                            value: SmtpRelayMode.StartTls,
                            label: "StartTLS",
                        },
                        {
                            value: SmtpRelayMode.None,
                            label: "No",
                        },
                    ],
                    sm: 6,
                },
                port: {
                    type: CustomFieldType.Number,
                    label: "Puerto*",
                    sm: 6,
                },
                username: {
                    type: CustomFieldType.Text,
                    label: "Usuario*",
                    sm: 6,
                },
                password: {
                    type: CustomFieldType.Password,
                    label: "Contraseña*",
                    sm: 6,
                },
                from_email: {
                    type: CustomFieldType.Email,
                    label: "Enviar desde este email*",
                },
            }}
            initialValues={props.settings}
            schema={Yup.object().shape({
                domain: Yup.string().required("Requerido"),
                secure_mode: Yup.string().required("Requerido"),
                port: Yup.number().required("Requerido"),
                username: Yup.string().required("Requerido"),
                password: Yup.string().required("Requerido"),
                from_email: Yup.string().email().required("Requerido"),
            })}
            submitText="Actualizar"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.update_smtp_settings(values)
                    .then(
                        (settings) => {
                            resetForm();
                            setMessage({
                                message: "Configuración actualizada",
                                type: "success",
                            });
                            props.onEdited(settings.result);
                        },
                        (err) => {
                            if (err.field_errors !== null) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
