import { Box, Button, Card, CardContent, Container, Grid, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import LinkedInTag from "react-linkedin-insight";
import { Navigate, useParams } from "react-router-dom";
import api from "../api/client";
import { Price, User } from "../api/interfaces";
import PriceCard from "../components/PriceCard";
import { useAuth } from "../UseAuth";

export default function BuyResume() {
    const auth = useAuth()!;
    const { id } = useParams();
    const [price, setPrice] = useState<Price | null>(null);
    const [loading, setLoading] = useState(true);
    const [invoiceData, setInvoiceData] = useState<{
        invoice_id: string;
        url: string;
    } | null>(null);
    const [waitingForPayment, setWaitingForPayment] = useState(false);
    const [paymentComplete, setPaymentComplete] = useState<boolean>(false);
    const [billing, setBilling] = useState<any>(null);

    useEffect(() => {
        if (auth.user && !auth.isSuperUser && (auth.user as User).admin && !billing) {
            api.billing_info().then(
                (res) => {
                    setBilling(res.result);
                },
                () => {
                    setBilling(null);
                },
            );
        }
    }, [auth.user, auth.isSuperUser, billing]);

    useEffect(() => {
        let active = true;

        if (id && auth.user) {
            api.get_price(id).then(
                (res) => {
                    if (active) {
                        setPrice(res.result);
                    }
                },
                () => {
                    setLoading(false);
                },
            );
        }

        return () => {
            active = false;
        };
    }, [id, auth.user]);

    useEffect(() => {
        let active = true;

        if (active && price && auth.user && !invoiceData) {
            api.checkout(price.id)
                .then((res) => {
                    setInvoiceData({
                        invoice_id: res.result.invoice_id,
                        url: res.result.invoice_url,
                    });
                })
                .finally(() => setLoading(false));
        }

        return () => {
            active = false;
        };
    }, [price, auth.user, invoiceData]);

    useEffect(() => {
        if (auth.user && invoiceData && waitingForPayment && !paymentComplete) {
            const timer = setInterval(() => {
                api.invoice_status(invoiceData.invoice_id).then(
                    (res) => {
                        if (res.result.paid) {
                            if (process.env.REACT_APP_ENVIROMENT !== "development") LinkedInTag.track("6422292");
                            if (price) {
                                if (process.env.REACT_APP_ENVIROMENT !== "development") {
                                    gtag("event", "purchase", {
                                        currency: "EUR",
                                        value: price.price,
                                        transaction_id: res.result.id,
                                        items: [
                                            {
                                                item_id: price.id,
                                                item_name: price.title,
                                                currency: "EUR",
                                                price: price.price,
                                                quantity: 1,
                                            },
                                        ],
                                    });
                                }
                            }
                            setPaymentComplete(true);
                        }
                    },
                    () => {},
                );
            }, 5000);

            return () => {
                clearInterval(timer);
            };
        }
    }, [auth, auth.user, waitingForPayment, paymentComplete, invoiceData, price]);

    useEffect(() => {
        if (paymentComplete) {
            // Reload credit info and redirect
            auth.updateCompany();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentComplete]);

    if (!auth.user) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return (
            <Container>
                <Box sx={{ width: "100%" }}>
                    <Skeleton sx={{ height: "100px" }} />
                    <Skeleton animation="wave" sx={{ height: "200px" }} />
                    <Skeleton />
                </Box>
            </Container>
        );
    }

    return (
        <Container>
            <Helmet>
                <title>Confirmar Compra - Miray Sign</title>
            </Helmet>
            {!paymentComplete && !waitingForPayment && (
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Card sx={{ height: "100%" }}>
                            <CardContent>
                                <Typography variant="h3" gutterBottom>
                                    Tus datos
                                </Typography>
                                <Typography variant="body1">Empresa / Nombre: {billing.name}</Typography>
                                <Typography variant="body1">CIF/DNI: {billing.tax_ids.data[0].value}</Typography>
                                <Typography variant="body1">Ciudad: {billing.address.city}</Typography>
                                <Typography variant="body1">Dirección: {billing.address.line1}</Typography>
                                <Typography variant="body1">Código postal: {billing.address.postal_code}</Typography>

                                <Button
                                    sx={{ marginTop: "20px" }}
                                    variant="contained"
                                    size="large"
                                    href={invoiceData!.url}
                                    target="_blank"
                                    rel="noopener, noreferrer"
                                    onClick={() => {
                                        setWaitingForPayment(true);
                                    }}
                                >
                                    Finalizar
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <PriceCard price={price!} />
                    </Grid>
                </Grid>
            )}
            {waitingForPayment && !paymentComplete && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">Esperando a que se complete el pago...</Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {paymentComplete && (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h4">Pago completado!</Typography>
                                <Typography variant="body1">
                                    Ya puedes usar tus créditos para realizar firmas.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}
