import React from "react";
// import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CookiesProvider } from "react-cookie";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { HelmetProvider } from "react-helmet-async";
import ReactDOM from "react-dom";

/*
const container = document.getElementById("root")!;

const root = createRoot(container);

root.render(
    <React.StrictMode>
        <HelmetProvider>
            <CookiesProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                </LocalizationProvider>
            </CookiesProvider>
        </HelmetProvider>
    </React.StrictMode>,
);
*/

ReactDOM.render(
    <React.StrictMode>
        <HelmetProvider>
            <CookiesProvider>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                </LocalizationProvider>
            </CookiesProvider>
        </HelmetProvider>
    </React.StrictMode>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
