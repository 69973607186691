import { Typography, Link, Alert, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import api from "../../api/client";
import { ApiError } from "../../api/error";
import { Department, Balance, SignPayload, User } from "../../api/interfaces";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";
import { SendContact } from "../../routes/SignRequest";

type SignFormProps = {
    user: User;
    isMobile?: boolean;
    sendContacts: SendContact[];
    sendTogether: boolean;
    onSuccess: () => void;
};

interface FormValues {
    title: string;
    department_id: string;
    comment: string | null;
    pdf: File | undefined;
}

export default function SignForm(props: SignFormProps) {
    const { user, isMobile } = props;
    const [departments, setUserDepartments] = useState<Department[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [balances, setBalances] = useState<Balance[]>([]);

    useEffect(() => {
        let active = true;

        api.list_user_deps(user.id).then((res) => {
            if (active) {
                setUserDepartments(res.result.data);
                setLoading(false);
            }
        });

        return () => {
            active = false;
        };
    }, [user.id]);

    useEffect(() => {
        let active = true;

        api.list_all_balances({ min_credits: 1 }, (res) => {
            if (active) {
                setBalances((i) => i.concat(res.result.data));
            }
        });

        return () => {
            active = false;
        };
    }, []);

    if (loading) {
        return (
            <>
                <Skeleton variant="rectangular" height={400} />
                <Skeleton variant="text" height={100} />
            </>
        );
    }

    if (departments.length === 0) {
        return (
            <>
                <Typography variant="h4" gutterBottom>
                    Crear una solicitud de firma vía email
                </Typography>
                <Alert severity="error">Para crear una solicitud de firma debes estar asignado a un departamento</Alert>
            </>
        );
    }

    return (
        <>
            <CustomForm
                fields={{
                    title: {
                        type: CustomFieldType.Text,
                        label: "Titulo*",
                    },
                    comment: {
                        type: CustomFieldType.Text,
                        label: "Comentario",
                        multiline: true,
                        rows: 2,
                    },
                    pdf: {
                        type: CustomFieldType.File,
                        label: "PDF*",
                        accept: "application/pdf",
                    },
                    department_id: {
                        type: CustomFieldType.Select,
                        label: "Departamento",
                        options: departments.map((dep) => ({
                            label: dep.name,
                            value: dep.id,
                        })),
                    },
                    balance_id: {
                        type: CustomFieldType.Select,
                        label: "Cartera de créditos a usar",
                        options: balances.map((bal) => ({
                            label: `Créditos: ${bal.credits}, expira el ${new Date(bal.expires).toLocaleString()}`,
                            value: bal.id,
                        })),
                    },
                }}
                schema={Yup.object().shape({
                    title: Yup.string().required("Requerido"),
                    department_id: Yup.string().required("Requerido"),
                    balance_id: Yup.string()
                        .required("Requerido")
                        .test(
                            "min-credit-balance",
                            "Esta cartera no tiene suficientes créditos.",
                            function (balance_id) {
                                const balance = balances.filter((x) => x.id == balance_id)[0];
                                return balance && balance.credits >= props.sendContacts.length;
                            },
                        ),
                    comment: Yup.string().optional(),
                    pdf: Yup.mixed()
                        .required("Requerido")
                        .test("fileFormat", "Solo PDFs", (value) => {
                            return value && ["application/pdf"].includes(value.type);
                        })
                        .test("fileSize", "El archivo es demasiado grande, máximo 25mb.", (file) => {
                            if (!file) return false;
                            const size = file.size / 1024 / 1024;
                            return size <= 25;
                        }),
                })}
                initialValues={
                    {
                        title: "",
                        company_name: "",
                        department_id: departments.length > 0 ? departments[0].id : "",
                        balance_id: balances.length > 0 ? balances[0].id : "",
                        comment: "",
                        pdf: undefined,
                    } as FormValues
                }
                submitText="Enviar solicitud de firma"
                onSubmit={(
                    values,
                    setSubmitting,
                    setFieldError,
                    setMessage,
                    resetForm,
                    setEnableProgress,
                    setProgress,
                ) => {
                    if (props.sendContacts.length == 0) {
                        setMessage({
                            message: `Tiene que haber al menos un firmante.`,
                            type: "error",
                        });
                        setSubmitting(false);
                        return;
                    }
                    if (!props.sendTogether) {
                        Promise.all(
                            props.sendContacts.map((contact) => {
                                setEnableProgress(true);
                                setProgress(0);

                                let return_promise;

                                const payload = {
                                    ...values,
                                    clients: [contact],
                                } as SignPayload;

                                if (isMobile === true) {
                                    return_promise = api.create_sign_mobile(payload);
                                } else {
                                    return_promise = api.create_sign_email(payload);
                                }

                                return return_promise;
                            }),
                        )
                            .then(() => {
                                resetForm();
                                setMessage({
                                    message: `Peticiones de firma creadas.`,
                                    type: "success",
                                });
                                props.onSuccess();
                            })
                            .catch((err: ApiError) => {
                                if (err.field_errors !== null) {
                                    api.parse_field_errors(err.field_errors, setFieldError);
                                    setMessage({
                                        message: "Hay errores en el formulario.",
                                        type: "error",
                                    });
                                }
                                if (err.error === "smtp_not_configured") {
                                    setMessage({
                                        message: (
                                            <React.Fragment>
                                                No tienes configurado un servidor smtp.{" "}
                                                <Link component={RouterLink} to="/smtp" color="inherit">
                                                    Configuralo aquí.
                                                </Link>
                                            </React.Fragment>
                                        ),
                                        type: "error",
                                    });
                                } else {
                                    setMessage({
                                        message: err.error,
                                        type: "error",
                                    });
                                }
                            })
                            .finally(() => {
                                setEnableProgress(false);
                                setProgress(0);
                                setSubmitting(false);
                            });
                    } else {
                        if (props.sendContacts.length > 6) {
                            setMessage({
                                message: `Al enviar una única peticion solo puede haber un máximo de 6 firmantes.`,
                                type: "error",
                            });
                            setSubmitting(false);
                            return;
                        }

                        setEnableProgress(true);
                        setProgress(0);

                        const payload = {
                            ...values,
                            clients: props.sendContacts,
                        } as SignPayload;

                        let return_promise;

                        if (isMobile === true) {
                            return_promise = api.create_sign_mobile(payload, {
                                onUploadProgress: (progressEvent) => {
                                    const percentCompleted = Math.round(
                                        (progressEvent.loaded * 100) / progressEvent.total,
                                    );
                                    setProgress(percentCompleted);
                                },
                            });
                        } else {
                            return_promise = api.create_sign_email(payload, {
                                onUploadProgress: (progressEvent) => {
                                    const percentCompleted = Math.round(
                                        (progressEvent.loaded * 100) / progressEvent.total,
                                    );
                                    setProgress(percentCompleted);
                                },
                            });
                        }

                        return_promise
                            .then(() => {
                                resetForm();
                                setMessage({
                                    message: "Peticion de firma creada",
                                    type: "success",
                                });
                                props.onSuccess();
                            })
                            .catch((err: ApiError) => {
                                if (err.field_errors !== null) {
                                    api.parse_field_errors(err.field_errors, setFieldError);
                                    setMessage({
                                        message: "Hay errores en el formulario.",
                                        type: "error",
                                    });
                                }
                                if (err.error === "smtp_not_configured") {
                                    setMessage({
                                        message: (
                                            <React.Fragment>
                                                No tienes configurado un servidor smtp.{" "}
                                                <Link component={RouterLink} to="/smtp" color="inherit">
                                                    Configuralo aquí.
                                                </Link>
                                            </React.Fragment>
                                        ),
                                        type: "error",
                                    });
                                } else {
                                    setMessage({
                                        message: err.error,
                                        type: "error",
                                    });
                                }
                            })
                            .finally(() => {
                                setEnableProgress(false);
                                setProgress(0);
                                setSubmitting(false);
                            });
                    }
                }}
            />
        </>
    );
}
