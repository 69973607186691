import { Card, CardHeader, CardMedia, Typography, CardContent, CardActionArea } from "@mui/material";
import React from "react";
import { BlogPost } from "../api/interfaces";
import { Link as RouterLink } from "react-router-dom";
import api from "../api/client";

type Props = {
    post: BlogPost;
};

export default function BlogCard(props: Props) {
    const post = props.post;

    return (
        <Card>
            <React.Fragment>
                <CardActionArea component={RouterLink} to={`/blog/${post.slug}`}>
                    <CardMedia component="img" height="300" image={api.get_blog_post_cover(post.id)} alt={post.title} />
                    <CardHeader title={post.title} titleTypographyProps={{ variant: "h4" }}></CardHeader>
                    <CardContent>
                        <Typography variant="body1">{post.description}</Typography>
                    </CardContent>
                </CardActionArea>
            </React.Fragment>
        </Card>
    );
}
