import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    FormControl,
    GridSize,
    InputLabel,
    MenuItem,
    Typography,
    TextField,
    Switch,
    Select,
    FormHelperText,
    LinearProgress,
    IconButton,
    InputAdornment,
    Card,
    CardHeader,
    CardContent,
    OutlinedInputProps,
    Autocomplete,
    Checkbox,
} from "@mui/material";
import { Grid, FormControlLabel, Button } from "@mui/material";
import { Alert } from "@mui/material";
import { AlertColor } from "@mui/lab";
import { Form, FormikProps, FormikState, getIn } from "formik";
import { Formik, FormikValues } from "formik";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
import DatePicker from "@mui/lab/DatePicker";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FileInput from "../FileInput";

export enum CustomFieldType {
    Text,
    Number,
    Email,
    Switch,
    File,
    Select,
    Password,
    Array,
    DatePicker,
    Autocomplete,
    CheckBox,
}

export interface CustomField<Values extends FormikValues = FormikValues, AutoComplete = any> {
    type: CustomFieldType;
    label: string;
    labelNode?: React.ReactElement;
    xs?: GridSize;
    sm?: GridSize;
    accept?: string;
    hide?: boolean;
    multiline?: boolean;
    InputProps?: Partial<OutlinedInputProps>;
    defaultValues?: () => { [key: string]: any };
    rows?: number;
    variant?: "filled" | "outlined" | "standard";
    options?: {
        value: string | number | undefined;
        label: React.ReactNode;
    }[];
    autoComplete?: AutoComplete[];
    getOptionLabel?: (option: AutoComplete) => string;
    addRowText?: string;
    onChange?: (formProps: FormikProps<Values>, value: string) => void;
    fields?: { [name: string]: CustomField<Values> };
    onAddNewToArray?: (addValues: (data: any) => void) => void;
    missingMinFields?: React.ReactNode;
    minFields?: number;
    maxFields?: number;
    disabled?: boolean;
}

export type CustomFields<Values extends FormikValues = FormikValues, AutoComplete = any> = {
    [key: string]: CustomField<Values, AutoComplete>;
};

interface Props<Values extends FormikValues = FormikValues, AutoComplete = any> {
    fields: CustomFields<Values, AutoComplete>;
    hasUploadProgress?: boolean;
    initialValues: Values;
    onSubmit: (
        values: Values,
        setSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string | undefined) => void,
        setMessage: React.Dispatch<
            React.SetStateAction<{
                message: React.ReactNode;
                type: AlertColor;
            } | null>
        >,
        resetForm: (nextState?: Partial<FormikState<Values>> | undefined) => void,
        setEnableProgress: (isEnabled: boolean) => void,
        setProgress: (progress: number) => void,
    ) => void;
    schema: Yup.AnyObjectSchema;
    submitText: string;
}

export default function CustomForm<Values extends FormikValues = FormikValues, AutoComplete = any>(
    props: Props<Values, AutoComplete>,
) {
    const [message, setMessage] = useState<{ message: React.ReactNode; type: AlertColor } | null>(null);
    const [enableProgress, setEnableProgress] = useState<boolean>(false);
    const [progress, setProgress] = useState(0);
    const [showPassword, setShowPassword] = useState<{ [key: string]: boolean }>({});

    const renderField = (name: string, field: CustomField<Values>, formikProps: FormikProps<Values>) => {
        if (field.hide === true) return null;
        switch (field.type) {
            case CustomFieldType.Text: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <TextField
                            type="text"
                            name={name}
                            label={field.label}
                            variant={field.variant || "outlined"}
                            disabled={formikProps.isSubmitting || field.disabled}
                            fullWidth
                            multiline={field.multiline}
                            rows={field.rows}
                            value={getIn(formikProps.values, name)}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            InputProps={field.InputProps}
                            error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                            helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.Number: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <TextField
                            type="number"
                            name={name}
                            label={field.label}
                            variant={field.variant || "outlined"}
                            fullWidth
                            value={getIn(formikProps.values, name)}
                            disabled={formikProps.isSubmitting}
                            onChange={(e) => {
                                formikProps.handleChange(name)(e);
                                if (field.onChange) {
                                    field.onChange(formikProps, e.target.value);
                                }
                            }}
                            onBlur={formikProps.handleBlur}
                            error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                            helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.Password: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <TextField
                            type={showPassword[name] ? "text" : "password"}
                            name={name}
                            label={field.label}
                            variant={field.variant || "outlined"}
                            disabled={formikProps.isSubmitting}
                            fullWidth
                            value={getIn(formikProps.values, name)}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                            helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => {
                                                setShowPassword({
                                                    ...showPassword,
                                                    [name]:
                                                        typeof showPassword[name] === "undefined"
                                                            ? true
                                                            : !showPassword[name],
                                                });
                                            }}
                                            size="large"
                                        >
                                            {showPassword[name] ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.Email: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <TextField
                            type="email"
                            name={name}
                            label={field.label}
                            variant={field.variant || "outlined"}
                            disabled={formikProps.isSubmitting}
                            fullWidth
                            value={getIn(formikProps.values, name)}
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                            helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.DatePicker: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <DatePicker
                            renderInput={(props) => (
                                <TextField
                                    {...props}
                                    name={name}
                                    label={field.label}
                                    helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                                    onBlur={formikProps.handleBlur}
                                    fullWidth
                                    error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                                />
                            )}
                            value={formikProps.values[name]}
                            inputFormat="yyyy/MM/dd"
                            disabled={formikProps.isSubmitting}
                            onChange={(date) => formikProps.setFieldValue(name, date)}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.Switch: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Switch
                                        name={name}
                                        checked={getIn(formikProps.values, name)}
                                        disabled={formikProps.isSubmitting}
                                        value={getIn(formikProps.values, name)}
                                        onChange={() => {
                                            formikProps.setFieldValue(name, !getIn(formikProps.values, name));
                                        }}
                                        onBlur={formikProps.handleBlur}
                                    />
                                }
                                label={field.labelNode ? field.labelNode : field.label}
                            />
                            {getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name)) && (
                                <FormHelperText error>{getIn(formikProps.errors, name)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                );
            }
            case CustomFieldType.CheckBox: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <FormControl fullWidth>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name={name}
                                        checked={getIn(formikProps.values, name) === 1}
                                        indeterminate={getIn(formikProps.values, name) === 2}
                                        disabled={formikProps.isSubmitting}
                                        value={getIn(formikProps.values, name)}
                                        onChange={() => {
                                            formikProps.setFieldValue(
                                                name,
                                                ((getIn(formikProps.values, name) || 0) + 1) % 3,
                                            );
                                        }}
                                        onBlur={formikProps.handleBlur}
                                    />
                                }
                                label={field.labelNode ? field.labelNode : field.label}
                            />
                            {getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name)) && (
                                <FormHelperText error>{getIn(formikProps.errors, name)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                );
            }
            case CustomFieldType.Select: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor={name}>{field.label}</InputLabel>
                            <Select
                                id={name}
                                name={name}
                                onChange={formikProps.handleChange}
                                onBlur={formikProps.handleBlur}
                                disabled={formikProps.isSubmitting}
                                label={field.label}
                                error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                                value={getIn(formikProps.values, name)}
                            >
                                {field.options &&
                                    field.options.map((option) => (
                                        <MenuItem value={option.value} key={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            {getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name)) && (
                                <FormHelperText error>{getIn(formikProps.errors, name)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                );
            }
            case CustomFieldType.Autocomplete: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <FormControl fullWidth>
                            <Autocomplete
                                id={name}
                                onChange={(a, v) => {
                                    formikProps.setFieldValue(name, v);
                                }}
                                onBlur={formikProps.handleBlur}
                                disabled={formikProps.isSubmitting}
                                options={field.autoComplete!}
                                getOptionLabel={field.getOptionLabel}
                                value={getIn(formikProps.values, name)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={field.label}
                                        name={name}
                                        error={
                                            getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))
                                        }
                                    />
                                )}
                            ></Autocomplete>
                            {getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name)) && (
                                <FormHelperText error>{getIn(formikProps.errors, name)}</FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                );
            }
            case CustomFieldType.File: {
                return (
                    <Grid item xs={field.xs || 12} sm={field.sm} key={name}>
                        <FileInput
                            name={name}
                            helperText={getIn(formikProps.touched, name) && getIn(formikProps.errors, name)}
                            label={field.label}
                            error={getIn(formikProps.touched, name) && Boolean(getIn(formikProps.errors, name))}
                            disabled={formikProps.isSubmitting}
                            value={getIn(formikProps.values, name)}
                            setFieldValue={formikProps.setFieldValue}
                            accept={field.accept}
                            onBlur={formikProps.handleBlur}
                        />
                    </Grid>
                );
            }
            case CustomFieldType.Array: {
                const values = getIn(formikProps.values, name) as Values[];

                if (values.length === 0) {
                    return (
                        <Grid item xs={12} key={name}>
                            {field.minFields !== undefined &&
                                field.minFields >= 0 &&
                                (field.missingMinFields || <Typography color="error">Faltan datos.</Typography>)}
                            <Button
                                color="secondary"
                                variant="contained"
                                sx={{ color: "white" }}
                                disabled={formikProps.isSubmitting}
                                onClick={() => {
                                    if (field.onAddNewToArray !== undefined) {
                                        field.onAddNewToArray((data) => {
                                            if (data === null) return;
                                            if (data !== undefined) {
                                                let newValues;
                                                if (Array.isArray(data))
                                                    newValues = [...getIn(formikProps.values, name), ...data];
                                                else newValues = [...getIn(formikProps.values, name), data];
                                                formikProps.setFieldValue(name, newValues);
                                            } else {
                                                const newValues = [
                                                    ...getIn(formikProps.values, name),
                                                    field.defaultValues!(),
                                                ];
                                                formikProps.setFieldValue(name, newValues);
                                            }
                                        });
                                    } else {
                                        const newValues = [...getIn(formikProps.values, name), field.defaultValues!()];
                                        formikProps.setFieldValue(name, newValues);
                                    }
                                }}
                            >
                                {field.addRowText || "Añadir"}
                            </Button>
                        </Grid>
                    );
                }

                return values.map((_value, i) => {
                    return (
                        <React.Fragment key={name + "fragment" + i}>
                            <Grid item xs={field.xs || 12} sm={field.sm} key={name + i}>
                                <Card>
                                    <CardHeader
                                        title={`${field.label} ${i + 1}`}
                                        titleTypographyProps={{ variant: "h5" }}
                                    ></CardHeader>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            {Object.entries(field.fields!).map(([subname, subfield]) => {
                                                const final_name = `${name}[${i}].${subname}`;
                                                return renderField(final_name, subfield, formikProps);
                                            })}
                                            <Grid item xs={12} sm={6}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => {
                                                        formikProps.setFieldValue(
                                                            name,
                                                            (formikProps.values[name] as any[]).filter(
                                                                (x, ii) => ii !== i,
                                                            ),
                                                        );
                                                    }}
                                                >
                                                    Eliminar
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            {i + 1 == values.length && i + 1 < (field.maxFields || 999) && (
                                <Grid item xs={12} key={name + "button" + i}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        sx={{ color: "white" }}
                                        disabled={formikProps.isSubmitting}
                                        onClick={() => {
                                            if (field.onAddNewToArray !== undefined) {
                                                field.onAddNewToArray((data) => {
                                                    if (data === null) return;
                                                    if (data !== undefined) {
                                                        let newValues;
                                                        if (Array.isArray(data))
                                                            newValues = [...getIn(formikProps.values, name), ...data];
                                                        else newValues = [...getIn(formikProps.values, name), data];
                                                        formikProps.setFieldValue(name, newValues);
                                                    } else {
                                                        const newValues = [
                                                            ...getIn(formikProps.values, name),
                                                            field.defaultValues!(),
                                                        ];
                                                        formikProps.setFieldValue(name, newValues);
                                                    }
                                                });
                                            } else {
                                                const newValues = [
                                                    ...getIn(formikProps.values, name),
                                                    field.defaultValues!(),
                                                ];
                                                formikProps.setFieldValue(name, newValues);
                                            }
                                        }}
                                    >
                                        {field.addRowText || "Añadir"}
                                    </Button>
                                </Grid>
                            )}
                        </React.Fragment>
                    );
                });
            }
        }
    };

    function renderFields(fields: CustomFields<Values>, formikProps: FormikProps<Values>) {
        return Object.entries(fields).map(([name, field]) => {
            return renderField(name, field, formikProps);
        });
    }

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={props.schema}
            enableReinitialize
            onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
                props.onSubmit(
                    values,
                    setSubmitting,
                    setFieldError,
                    setMessage,
                    resetForm,
                    setEnableProgress,
                    setProgress,
                );
            }}
        >
            {(formikProps) => (
                <Form style={{ display: "flex", flexWrap: "wrap" }}>
                    <Grid container spacing={2}>
                        {renderFields(props.fields, formikProps)}

                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={formikProps.isSubmitting}
                                onClick={() => {
                                    formikProps.submitForm();
                                }}
                            >
                                {props.submitText}
                            </Button>
                        </Grid>

                        {enableProgress && (
                            <Grid item xs={12}>
                                <LinearProgress variant="determinate" value={progress} />
                            </Grid>
                        )}

                        {message !== null && (
                            <Grid item xs={12}>
                                <Alert
                                    severity={message.type}
                                    onClose={() => {
                                        setMessage(null);
                                    }}
                                >
                                    {message.type == "error" && !message.message && "Error interno del servidor."}
                                    {message.message}
                                </Alert>
                            </Grid>
                        )}
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}

interface AccordionProps<Values extends FormikValues = FormikValues, AutoComplete = any>
    extends Props<Values, AutoComplete> {
    title: string;
    defaultExpanded?: boolean;
}

export function AccordionForm<Values extends FormikValues = FormikValues, AutoComplete = any>(
    props: AccordionProps<Values, AutoComplete>,
) {
    return (
        <Grid item xs={12}>
            <Accordion defaultExpanded={props.defaultExpanded}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">{props.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <CustomForm {...props} />
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
}
