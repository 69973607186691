import { Card, CardContent, Container, Typography } from "@mui/material";
import React from "react";
import * as Yup from "yup";
import { ApiError } from "../../api/interfaces";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import { useAuth } from "../../UseAuth";
import { Navigate } from "react-router";

const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Requerido"),
    description: Yup.string(),
    website: Yup.string().url("Debe ser un url válido"),
});

export default function RegisterCompany() {
    const auth = useAuth()!;

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Card
                sx={{
                    maxWidth: (theme) => ({
                        [theme.breakpoints.up("sm")]: theme.breakpoints.values.sm,
                    }),
                    alignContent: "center",
                }}
            >
                <CardContent>
                    <Typography variant="h4" gutterBottom>
                        Registrar una empresa
                    </Typography>

                    <CustomForm
                        fields={{
                            name: {
                                label: "Nombre",
                                type: CustomFieldType.Text,
                            },
                            description: {
                                label: "Descripción",
                                type: CustomFieldType.Text,
                            },
                            website: {
                                label: "Página web",
                                type: CustomFieldType.Text,
                            },
                        }}
                        initialValues={{
                            name: "",
                            description: "",
                            website: "",
                        }}
                        submitText="Registrar Empresa"
                        schema={RegisterSchema}
                        onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                            api.create_company(values.name, values.description, values.website)
                                .then(
                                    (res) => {
                                        setMessage({
                                            message: `Empresa ${res.result.name} creada`,
                                            type: "success",
                                        });
                                        resetForm();
                                    },
                                    (err: ApiError) => {
                                        if (err.field_errors !== null) {
                                            api.parse_field_errors(err.field_errors, setFieldError);
                                        }
                                        setMessage({
                                            message: err.error,
                                            type: "error",
                                        });
                                    },
                                )
                                .finally(() => setSubmitting(false));
                        }}
                    />
                </CardContent>
            </Card>
        </Container>
    );
}
