import { Card, CardContent, Container, Typography, Grid, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import {
    Timeline,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    TimelineContent,
} from "@mui/lab";
import React, { useState } from "react";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import api, { QUERY_LIMIT } from "../api/client";
import { User, UserAction } from "../api/interfaces";
import { useAuth } from "../UseAuth";
import { Navigate } from "react-router-dom";

export default function UserActions() {
    const auth = useAuth()!;
    const [items, setItems] = useState<UserAction[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [page, setPage] = useState(0);
    const [pageActions, setPageActions] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.list_users({}, page).then((res) => {
                if (!active) return;
                if (page === 0) setUsers(res.result.data);
                else setUsers((users) => users.concat(res.result.data));
                if (res.result.data.length >= QUERY_LIMIT) setPage((p) => p + 1);
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, page]);

    useEffect(() => {
        if (selectedUser == null) return;
        let active = true;
        api.list_actions(selectedUser, pageActions).then((res) => {
            if (!active) return;
            if (pageActions === 0) setItems(res.result.data);
            else setItems((items) => items.concat(res.result.data));
            setHasMore(res.result.data.length >= QUERY_LIMIT);
        });
        return () => {
            active = false;
        };
    }, [selectedUser, pageActions]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedUser(event.target.value);
        setPageActions(0);
    };

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Registro de auditoria
                            </Typography>
                            <Typography>Aquí puedes ver las acciones que ha hecho un usuario.</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Selección de usuario
                            </Typography>
                            <Select
                                value={selectedUser ? selectedUser : ""}
                                onChange={handleChange}
                                style={{ minWidth: "25%" }}
                            >
                                {users.map((x) => (
                                    <MenuItem key={x.id} value={x.id}>
                                        {x.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    {selectedUser != null && (
                        <InfiniteScroll
                            style={{ overflow: "hidden", padding: "5px" }}
                            dataLength={items.length}
                            next={() => {
                                setPageActions((p) => p + 1);
                            }}
                            hasMore={hasMore}
                            loader={<React.Fragment></React.Fragment>}
                        >
                            <Grid container spacing={2}>
                                <Timeline position="alternate">
                                    {items.map((item) => (
                                        <TimelineItem key={item.id}>
                                            <TimelineOppositeContent>
                                                <Typography color="textSecondary">
                                                    {new Date(item.created_at).toLocaleString()}
                                                </Typography>
                                            </TimelineOppositeContent>
                                            <TimelineSeparator>
                                                <TimelineDot />
                                                <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>
                                                <Typography color="primary">{item.name}</Typography>
                                                <Typography>{item.data}</Typography>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                            </Grid>
                        </InfiniteScroll>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
}
