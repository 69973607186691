import { LoadingButton } from "@mui/lab";
import { Card, CardContent, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import { Price } from "../api/interfaces";
import { SxProps } from "@mui/system";

const paragraphStyle: SxProps = {
    fontSize: {
        xs: "1.1rem",
        md: "1.3rem",
        "@media (min-width:600px)": "1.2rem",
    },
} as const;

interface Props {
    onBuy?: (setLoading: React.Dispatch<React.SetStateAction<boolean>>) => void;
    price: Price;
    savePercent?: number;
}

export default function PriceCard(props: Props) {
    const price = props.price;
    const [loading, setLoading] = useState(false);

    return (
        <Card sx={{ height: "100%" }}>
            <CardContent>
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: {
                            xs: "1.5rem",
                            md: "2.2rem",
                            "@media (min-width:600px)": "2rem",
                        },
                        fontWeight: 1000,
                        color: "primary.main",
                    }}
                    align="center"
                >
                    Bono {price.title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: {
                            xs: "1.5rem",
                            md: "2.2rem",
                            "@media (min-width:600px)": "2rem",
                        },
                        fontWeight: 1000,
                        color: "secondary.main",
                    }}
                    align="center"
                >
                    {price.price / 100}&euro;
                </Typography>
                <Typography
                    sx={{
                        fontSize: {
                            xs: "0.8rem",
                            md: "1.2rem",
                            "@media (min-width:600px)": "1rem",
                        },
                        color: "#616161",
                    }}
                    align="center"
                >
                    + IVA
                </Typography>
                <Typography
                    sx={{
                        fontSize: {
                            xs: "1.1rem",
                            "@media (min-width:600px)": "1.3rem",
                            md: "1.5rem",
                        },
                    }}
                    align="center"
                    paragraph
                >
                    Incluye {price.quantity} firmas{" "}
                    {props.savePercent && (
                        <>
                            y ahorras un{" "}
                            <span style={{ color: "green" }}>
                                <b>{Math.round(props.savePercent)}%</b>
                            </span>
                        </>
                    )}
                </Typography>
                {price.description && (
                    <Typography
                        variant="body1"
                        paragraph
                        align="center"
                        sx={{
                            fontSize: {
                                xs: "1.2rem",
                                "@media (min-width:600px)": "1.4rem",
                                md: "1.6rem",
                            },
                            color: "#616161",
                        }}
                    >
                        {price.description}
                    </Typography>
                )}
                <Typography paragraph align="center" sx={paragraphStyle}>
                    Sin límite de usuarios
                </Typography>
                <Typography paragraph align="center" sx={paragraphStyle}>
                    Sin límite de envíos
                </Typography>
                <Typography paragraph align="center" sx={paragraphStyle}>
                    Sin límite de SMS
                </Typography>
                <Typography paragraph align="center" sx={paragraphStyle}>
                    ¡Solo pagas por firma realizada! 
                </Typography>
                {typeof props.onBuy !== "undefined" && (
                    <Box sx={{ justifyContent: "center" }} textAlign="center">
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            loading={loading}
                            onClick={() => {
                                props.onBuy!(setLoading);
                            }}
                        >
                            Comprar
                        </LoadingButton>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
}
