import api from "../api/client";
import { Alert, Button, Card, CardContent, Grid, Link, Snackbar, Typography } from "@mui/material";
import { Container } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Price } from "../api/interfaces";
import PriceCard from "../components/PriceCard";
import { Link as RouterLink, Navigate } from "react-router-dom";
import LinkedInTag from "react-linkedin-insight";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";

export default function Shop() {
    const auth = useAuth()!;
    const [prices, setPrices] = useState<Price[]>([]);
    const [error, setError] = React.useState<React.ReactNode | null>(null);
    const [selectedPrice, setSelectedPrice] = useState<Price | null>(null);

    useEffect(() => {
        let active = true;

        api.list_prices().then(
            (res) => {
                if (!active) return;
                setPrices(res.result.data);
            },
            () => {},
        );

        return () => {
            active = false;
        };
    }, []);

    // Using common sense: lowest price will be the most costly option. So we base the save percentage based on it.
    const lowestPrice = useMemo(() => {
        if (prices.length === 0) return undefined;

        const lowestPrice = prices.reduce((p, c) => {
            if (p.price < c.price) {
                return p;
            }
            return c;
        });

        return lowestPrice;
    }, [prices]);

    if (auth.loading) return null;

    if (!auth.user || !auth.company) {
        return <Navigate to="/" />;
    }

    if (auth.user && selectedPrice) {
        return <Navigate to={`/buy/${selectedPrice.id}`} />;
    }

    return (
        <Container>
            <Helmet>
                <title>Comprar Créditos - Miray Sign</title>
            </Helmet>
            <Snackbar open={error !== null} autoHideDuration={6000} onClose={() => setError(null)}>
                <Alert onClose={() => setError(null)} severity="error" sx={{ width: "100%" }}>
                    {error}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3" gutterBottom>
                                Comprar Créditos
                            </Typography>
                            <Typography paragraph>
                                Al comprar un bono, pierdes los creditos que tienes actualmente sin usar.
                            </Typography>
                            <Typography paragraph>Los créditos expiran al cabo de un año.</Typography>
                            <Typography paragraph>
                                Al realizar una compra, aceptas nuestros{" "}
                                <Link color="primary" component={RouterLink} to="/terms-conditions">
                                    términos y condiciones.
                                </Link>
                            </Typography>
                            {!auth.company.customer_data_edited && (
                                <Alert severity="warning">
                                    Antes de poder realizar un pago debe actualizar la información de facturación en la
                                    configuración.
                                </Alert>
                            )}
                            <Button sx={{ marginTop: "20px" }} component={RouterLink} to={`/edit-invoices`}>
                                Editar información de facturación
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {prices.length > 0 && (
                <Grid
                    container
                    spacing={2}
                    sx={{
                        marginTop: "20px",
                    }}
                    justifyContent="center"
                >
                    {prices.map((price) => (
                        <Grid item xs={12} sm={6} md={4} key={price.id}>
                            <PriceCard
                                price={price}
                                onBuy={(setLoading) => {
                                    if (!auth.company!.customer_data_edited) {
                                        setError(
                                            <>
                                                <p>
                                                    Antes de poder realizar un pago debe actualizar la información de
                                                    facturación en la configuración.
                                                </p>
                                                <Link component={RouterLink} to={`/edit-invoices`}>
                                                    Puede hacerlo aquí
                                                </Link>
                                            </>,
                                        );
                                        return;
                                    }
                                    setLoading(true);
                                    if (process.env.REACT_APP_ENVIROMENT !== "development") {
                                        LinkedInTag.track("6422300");
                                        gtag("event", "select_item", {
                                            item_list_id: "bono_tarifas",
                                            item_list_name: "Tarifas",
                                            items: [
                                                {
                                                    item_id: price.id,
                                                    item_name: price.title,
                                                    currency: "EUR",
                                                    price: price.price,
                                                    quantity: 1,
                                                },
                                            ],
                                        });
                                    }
                                    setSelectedPrice(price);
                                }}
                                savePercent={
                                    lowestPrice && price.id !== lowestPrice.id
                                        ? Math.abs(
                                              lowestPrice.price / lowestPrice.quantity - price.price / price.quantity,
                                          )
                                        : undefined
                                }
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </Container>
    );
}
