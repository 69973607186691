import React from "react";
import api from "../../api/client";
import CustomForm, { CustomFieldType } from "./CustomForm";
import * as Yup from "yup";

export default function DownloadReportsForm() {
    return (
        <CustomForm
            fields={{
                from: {
                    type: CustomFieldType.DatePicker,
                    label: "Fecha de inicio",
                    xs: 6,
                },
                to: {
                    type: CustomFieldType.DatePicker,
                    label: "Fecha final",
                    xs: 6,
                },
            }}
            schema={Yup.object().shape({
                from: Yup.date().required("Requerido"),
                to: Yup.date().required("Requerido"),
            })}
            initialValues={{
                from: new Date(),
                to: new Date(),
            }}
            submitText="Descargar"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm, setEnableProgress, setProgress) => {
                setEnableProgress(true);
                setProgress(0);

                const date_from = values.from;
                date_from.setHours(0, 0, 0, 0);
                const date_to = values.to;
                date_to.setHours(23, 59, 59);

                api.download_custom_report(date_from, date_to, {
                    onDownloadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(percentCompleted);
                    },
                })
                    .then(
                        () => {
                            resetForm();
                        },
                        () => {
                            setFieldError("from", "Tiene que ser anterior a la fecha final.");
                        },
                    )
                    .finally(() => {
                        setSubmitting(false);
                        setEnableProgress(false);
                        setProgress(0);
                    });
            }}
        />
    );
}
