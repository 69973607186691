import { Box, Button, Card, CardContent, Container, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import api from "../api/client";
import { AgendaGroup } from "../api/interfaces";
import { useAuth } from "../UseAuth";
import { DataGrid, esES, GridApi, GridCellValue, GridColDef } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import AgendaGroupForm from "../components/forms/AgendaGroupForm";

export const AgendaGroupColumns: GridColDef[] = [
    {
        field: "ver",
        headerName: "Ver",
        sortable: false,
        renderCell: (params) => {
            const api: GridApi = params.api;
            const thisRow: Record<string, GridCellValue> = {};

            api.getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

            return (
                <Button color="primary" component={RouterLink} to={`/agenda/groups/${thisRow.id}`}>
                    Ver
                </Button>
            );
        },
    },
    {
        field: "id",
        headerName: "ID",
        hide: true,
    },
    {
        field: "name",
        headerName: "Nombre",
        editable: false,
        resizable: true,
        flex: 1,
    },
    {
        field: "description",
        headerName: "Descripción",
        editable: false,
        resizable: true,
        flex: 1,
    },
    {
        field: "created_at",
        headerName: "Creado el",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
];

export default function Agenda() {
    const auth = useAuth()!;
    const [groups, setGroups] = useState<AgendaGroup[]>([]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_groups_all({}, (res) => {
                if (active) {
                    setGroups((i) => i.concat(res.result.data));
                }
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Agenda - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Agenda
                            </Typography>
                            <Typography>
                                Aquí puedes crear grupos y añadir contactos para agilizar las peticiones de firma.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AgendaGroupForm
                        onNewGroup={(group) =>
                            setGroups((g) => g.concat(group).sort((a, b) => a.name.localeCompare(b.name)))
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2, height: "500px" }}>
                        <Box sx={{ display: "flex", height: "100%" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={AgendaGroupColumns}
                                    rows={groups.map((x) => ({
                                        ...x,
                                        created_at: new Date(x.created_at),
                                    }))}
                                    disableSelectionOnClick
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
