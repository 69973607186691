import { Card, CardContent, Container, Typography, Grid, Paper, Button } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import api, { QUERY_LIMIT } from "../api/client";
import { SignDocument, SignDocumentQuery } from "../api/interfaces";
import SearchCard from "../components/SearchCard";
import { useAuth } from "../UseAuth";
import { DataGrid, GridApi, GridCellValue, GridColDef, esES } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const columns: GridColDef[] = [
    {
        field: "ver",
        headerName: "Ver",
        sortable: false,
        renderCell: (params) => {
            const api: GridApi = params.api;
            const thisRow: Record<string, GridCellValue> = {};

            api.getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

            return (
                <Button color="primary" component={RouterLink} to={`/docs/${thisRow.id}`}>
                    Ver
                </Button>
            );
        },
    },
    {
        field: "id",
        headerName: "ID",
        hide: true,
    },
    {
        field: "title",
        headerName: "Título",
        editable: false,
        flex: 1,
    },
    {
        field: "file_name",
        headerName: "Archivo",
        editable: false,
        flex: 1,
    },
    {
        field: "is_signed",
        headerName: "Firmado",
        sortable: true,
        type: "boolean",
        editable: false,
        flex: 0.2,
    },
    {
        field: "is_via_email",
        headerName: "Vía",
        sortable: true,
        type: "string",
        editable: false,
        flex: 0.2,
        valueFormatter: (params) => {
            return params.value === true ? "Email" : "SMS";
        },
    },
    {
        field: "created_at",
        headerName: "Creado el",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
    {
        field: "signed_at",
        headerName: "Firmado el",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
    {
        field: "canceled_at",
        headerName: "Cancelado el",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
];

export default function Documents() {
    const auth = useAuth()!;
    const [items, setItems] = useState<SignDocument[]>([]);
    const [currentQuery, setCurrentQuery] = useState<SignDocumentQuery>({ page: 0 });

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser) {
            api.list_documents(currentQuery).then((res) => {
                if (!active) return;
                if (currentQuery.page === 0) {
                    setItems(res.result.data);
                } else {
                    setItems((items) => items.concat(res.result.data));
                }
                if (res.result.data.length == QUERY_LIMIT) {
                    // TODO: add a timeout?
                    setCurrentQuery((c) => ({
                        ...currentQuery,
                        page: c.page + 1,
                    }));
                }
            });
        }

        return () => {
            active = false;
        };
    }, [currentQuery, auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    return (
        <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Documentos - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Documentos
                            </Typography>
                            <Typography>
                                Aquí puedes buscar documentos, solo puedes ver los documentos que pertenecen a tú
                                departamento.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <SearchCard
                        onSearch={(values, setSubmitting) => {
                            setCurrentQuery({
                                ...values,
                                page: 0,
                            });
                            setSubmitting(false);
                        }}
                        queries={[
                            {
                                name: "title",
                                type: "boolean",
                                label: "Título",
                            },
                            {
                                name: "file_name",
                                type: "boolean",
                                label: "Nombre Archivo",
                            },
                            {
                                name: "signed",
                                type: "checkbox",
                                label: "Firmado",
                            },
                            {
                                name: "email",
                                type: "checkbox",
                                label: "Vía email",
                            },
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ padding: 2, height: "80vh" }}>
                        <Box sx={{ display: "flex", height: "100%" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={columns}
                                    rows={items.map((x) => ({
                                        ...x,
                                        created_at: new Date(x.created_at),
                                        canceled_at: x.canceled_at !== null ? new Date(x.canceled_at) : null,
                                        signed_at: x.signed_at !== null ? new Date(x.signed_at) : null,
                                    }))}
                                    disableSelectionOnClick
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
