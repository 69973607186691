import { Grow, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export default function FeatureList(props: React.PropsWithChildren<Record<never, any>>) {
    return (
        <Box
            sx={{
                height: { xs: 450, sm: 420 },
                position: "relative",
                overflowX: "auto",
                overflowY: "hidden",
                backgroundColor: "transparent",
            }}
        >
            <Box
                sx={{
                    padding: "30px",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    backgroundColor: "rgba(237, 239, 243, 0.7)",
                }}
            >
                {props.children}
            </Box>
        </Box>
    );
}

interface FeatureProps {
    title: React.ReactNode;
    children?: React.ReactNode;
}

function InternalFeature(props: FeatureProps, ref?: React.Ref<HTMLDivElement>) {
    return (
        <Grow in={true} style={{ transformOrigin: "0 0 0", marginRight: "2rem" }} timeout={2000}>
            <Box
                sx={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingBottom: "30px",
                    display: "inline-table",
                    height: { xs: 400, sm: 350 },
                    width: { xs: 330, sm: 450 },
                    backgroundColor: "#ffffff",
                    borderRadius: "30px",
                    textAlign: "left",
                    whiteSpace: "normal",
                }}
                ref={ref}
            >
                <Box
                    component="h4"
                    sx={{
                        color: "secondary.main",
                        fontSize: "1.5rem",
                    }}
                >
                    {props.title}
                </Box>
                <Typography
                    align="left"
                    variant="body1"
                    sx={{
                        fontSize: "1.3rem",
                    }}
                >
                    {props.children}
                </Typography>
            </Box>
        </Grow>
    );
}

export const Feature = React.forwardRef(InternalFeature);
