import { Divider, Typography } from "@mui/material";
import { Container, Grid } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import api from "../api/client";
import { BlogPost } from "../api/interfaces";
import BlogCard from "../components/BlogCard";

export default function BlogList() {
    const [posts, setPosts] = useState<BlogPost[]>([]);

    useEffect(() => {
        api.get_blog_posts().then((res) => {
            setPosts(res.result.data);
        });
    }, []);

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Helmet>
                <title>Blog - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom align="center">
                        Bienvenido al blog de Miray Sign
                    </Typography>
                    <Divider />
                </Grid>

                {posts.map((post) => (
                    <Grid item key={post.id} xs={12} sm={6}>
                        <BlogCard post={post} />
                    </Grid>
                ))}
            </Grid>
            <img
                height="1"
                width="1"
                style={{ display: "none" }}
                alt=""
                src="https://px.ads.linkedin.com/collect/?pid=3728532&conversionId=6422516&fmt=gif"
            />
        </Container>
    );
}
