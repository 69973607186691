import React, { useState } from "react";
import Navbar from "./components/Navbar";
import CssBaseline from "@mui/material/CssBaseline";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
    AppBar,
    Button,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    Snackbar,
    SnackbarContent,
    Typography,
    IconButton,
    Stack,
    Skeleton,
} from "@mui/material";
import Login from "./Login";
import { useEffect } from "react";
import RegisterCompany from "./routes/superuser/RegisterCompany";
import ListCompanies from "./routes/superuser/ListCompanies";
import CompanyDetail from "./routes/superuser/CompanyDetail";
import HomeAuthed from "./routes/HomeAuthed";
import SignRequest from "./routes/SignRequest";
import Departments from "./routes/Departments";
import DepartmentDetail from "./routes/DepartmentDetail";
import Documents from "./routes/Documents";
import UserDetail from "./routes/UserDetail";
import DocumentDetail from "./routes/DocumentDetail";
import Users from "./routes/Users";
import ChangePassword from "./routes/ChangePassword";
import ForgotPassword from "./routes/ForgotPassword";
import Recover from "./routes/Recover";
import UserActions from "./routes/UserActions";
import LoginSuper from "./LoginSuper";
import UpdateLogo from "./routes/UpdateLogo";
import UpdateReportsSettings from "./routes/UpdateReportsSettings";
import UpdateSMTPSettings from "./routes/UpdateSMTPSettings";
import CreditLogs from "./routes/CreditsLog";
import CookiePolicy from "./routes/CookiePolicy";
import AboutUs from "./routes/AboutUs";
import { useCookies } from "react-cookie";
import { Link as RouterLink } from "react-router-dom";
import Pricing from "./routes/superuser/Pricing";
import Home from "./routes/Home";
import Shop from "./routes/Shop";
import ContactUs from "./routes/ContactUs";
import FreeTest from "./routes/FreeTest";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import TermsAndConditions from "./routes/TermsAndConditions";
import MenuConfig from "./routes/MenuConfig";
import PeopleIcon from "@mui/icons-material/People";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import EmailIcon from "@mui/icons-material/Email";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AutorenewIcon from "@mui/icons-material/Autorenew";
//import logo from "./images/logo.webp";
import logo from "./images/logo.webp";
import "./footer.css";
import LinkedInTag from "react-linkedin-insight";
import Notifications from "./routes/superuser/Notifications";
import UserConfig from "./routes/UserConfig";
import fondo from "./images/footer.webp";
import uanataca from "./images/uanataca.webp";
import BuyAndRegister from "./routes/BuyAndRegister";
import { Box } from "@mui/system";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import Facturacion from "./routes/Facturacion";
import { AuthContext, ProvideAuth } from "./UseAuth";
import BuyResume from "./routes/BuyResume";
import BlogEditor from "./routes/superuser/BlogEditor";
import BlogEntry from "./routes/BlogEntry";
import BlogListAdmin from "./routes/superuser/BlogListAdmin";
import BlogList from "./routes/BlogList";
import Agenda from "./routes/Agenda";
import AgendaGroupDetail from "./routes/AgendaGroupDetail";
import AgendaClientDetail from "./routes/AgendaClientDetail";
import CreditBalances from "./routes/CreditBalances";
import AdminHome from "./routes/AdminHome";
import EditPrice from "./routes/superuser/EditPrice";
import FAQS from "./routes/FAQS";

declare module "@mui/material/styles" {}

const theme = createTheme({
    palette: {
        primary: {
            main: "#243a8d",
        },
        secondary: {
            main: "#ef7f01",
        },
    },
    typography: {
        fontFamily: "Avenir LT Std",
    },
});

function App() {
    const [cookieConsentOpen, setCookieConsentOpen] = useState(true);
    const [cookies, setCookie] = useCookies(["cookie_consent"]);

    /*
    useEffect(() => {
        const disabled = cookies.cookie_consent === true ? false : true;

        LinkedInTag.init("3728532", "dc", disabled);
    }, [cookies.cookie_consent]);
    */

    useEffect(() => {
        if (process.env.NODE_ENV === "production") {
            LinkedInTag.init("3728532");
        }
    }, []);

    return (
        <React.Fragment>
            <CssBaseline />

            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ProvideAuth>
                        <BrowserRouter>
                            <header>
                                <Navbar />
                            </header>

                            <Box component="main" sx={{ marginBottom: "20px", marginTop: "5em" }}>
                                <AuthContext.Consumer>
                                    {(auth) => (
                                        <React.Fragment>
                                            {auth?.loading && (
                                                <Container>
                                                    <Box sx={{ width: "100%" }}>
                                                        <Skeleton sx={{ height: "100px" }} />
                                                        <Skeleton animation="wave" sx={{ height: "200px" }} />
                                                        <Skeleton />
                                                    </Box>
                                                </Container>
                                            )}
                                            {!auth?.loading && (
                                                <Routes>
                                                    <Route path="/">
                                                        <Route
                                                            index
                                                            element={
                                                                <React.Fragment>
                                                                    <HomeAuthed />
                                                                    <AdminHome />
                                                                    <Home />
                                                                </React.Fragment>
                                                            }
                                                        ></Route>
                                                        <Route path="login" element={<Login />}></Route>
                                                        <Route path="superlogin" element={<LoginSuper />}></Route>

                                                        <Route path="superuser">
                                                            <Route
                                                                path="register"
                                                                element={<RegisterCompany />}
                                                            ></Route>
                                                            <Route path="blog">
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <MenuConfig
                                                                            title="Blog"
                                                                            links={[
                                                                                {
                                                                                    title: "Nuevo post",
                                                                                    href: "/superuser/blog/new",
                                                                                    icon: (
                                                                                        <EmailIcon fontSize="large" />
                                                                                    ),
                                                                                },
                                                                                {
                                                                                    title: "Editar un post",
                                                                                    href: "/superuser/blog/edit",
                                                                                    icon: (
                                                                                        <EmailIcon fontSize="large" />
                                                                                    ),
                                                                                },
                                                                            ]}
                                                                            md={6}
                                                                        />
                                                                    }
                                                                />
                                                                <Route path="new" element={<BlogEditor />}></Route>
                                                                <Route path="edit">
                                                                    <Route index element={<BlogListAdmin />}></Route>
                                                                    <Route
                                                                        path=":slug"
                                                                        element={<BlogEditor />}
                                                                    ></Route>
                                                                </Route>
                                                            </Route>
                                                            <Route path="companies">
                                                                <Route index element={<ListCompanies />} />
                                                                <Route path=":id" element={<CompanyDetail />}></Route>
                                                            </Route>
                                                            <Route path="pricing">
                                                                <Route index element={<Pricing />} />
                                                                <Route path=":id" element={<EditPrice />}></Route>
                                                            </Route>
                                                            <Route
                                                                path="notifications"
                                                                element={<Notifications />}
                                                            ></Route>
                                                        </Route>

                                                        <Route path="account">
                                                            <Route
                                                                path="change_pass"
                                                                element={<ChangePassword />}
                                                            ></Route>
                                                            <Route path="config" element={<UserConfig />}></Route>
                                                            <Route path="recover">
                                                                <Route index element={<ForgotPassword />} />
                                                                <Route path=":token" element={<Recover />}></Route>
                                                            </Route>
                                                        </Route>

                                                        <Route path="logo" element={<UpdateLogo />}></Route>
                                                        <Route path="smtp" element={<UpdateSMTPSettings />}></Route>
                                                        <Route
                                                            path="reportes"
                                                            element={<UpdateReportsSettings />}
                                                        ></Route>
                                                        <Route path="sign" element={<SignRequest />}></Route>

                                                        <Route path="deps">
                                                            <Route index element={<Departments />}></Route>
                                                            <Route path=":id" element={<DepartmentDetail />}></Route>
                                                        </Route>

                                                        <Route path="docs">
                                                            <Route index element={<Documents />} />
                                                            <Route path=":id" element={<DocumentDetail />}></Route>
                                                        </Route>

                                                        <Route path="users">
                                                            <Route index element={<Users />} />
                                                            <Route path=":id" element={<UserDetail />}></Route>
                                                        </Route>

                                                        <Route path="agenda">
                                                            <Route index element={<Agenda />}></Route>
                                                            <Route path="groups">
                                                                <Route
                                                                    path=":id"
                                                                    element={<AgendaGroupDetail />}
                                                                ></Route>
                                                            </Route>
                                                            <Route path="clients">
                                                                <Route
                                                                    path=":id"
                                                                    element={<AgendaClientDetail />}
                                                                ></Route>
                                                            </Route>
                                                        </Route>

                                                        <Route path="credits">
                                                            <Route index element={<CreditBalances />} />
                                                        </Route>

                                                        <Route path="shop" element={<Shop />}></Route>
                                                        <Route path="faqs" element={<FAQS />}></Route>

                                                        <Route path="blog">
                                                            <Route index element={<BlogList />}></Route>
                                                            <Route path=":slug" element={<BlogEntry />}></Route>
                                                        </Route>

                                                        <Route path="menu">
                                                            <Route
                                                                path="config"
                                                                element={
                                                                    <MenuConfig
                                                                        title="Configuración"
                                                                        links={[
                                                                            {
                                                                                title: "Usuarios",
                                                                                href: "/users",
                                                                                icon: <PeopleIcon fontSize="large" />,
                                                                            },
                                                                            {
                                                                                title: "Departamentos",
                                                                                href: "/deps",
                                                                                icon: (
                                                                                    <FolderSharedIcon fontSize="large" />
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: "Agenda",
                                                                                href: "/agenda",
                                                                                icon: (
                                                                                    <PermContactCalendarIcon fontSize="large" />
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: "Correo",
                                                                                href: "/smtp",
                                                                                icon: <EmailIcon fontSize="large" />,
                                                                                admin: true,
                                                                            },
                                                                            {
                                                                                title: "Logo",
                                                                                href: "/logo",
                                                                                icon: (
                                                                                    <BrandingWatermarkIcon fontSize="large" />
                                                                                ),
                                                                                admin: true,
                                                                            },
                                                                            {
                                                                                title: "Facturación",
                                                                                href: "/edit-invoices",
                                                                                icon: (
                                                                                    <BrandingWatermarkIcon fontSize="large" />
                                                                                ),
                                                                                admin: true,
                                                                            },
                                                                        ]}
                                                                        md={6}
                                                                    />
                                                                }
                                                            ></Route>

                                                            <Route
                                                                path="audit"
                                                                element={
                                                                    <MenuConfig
                                                                        title="Auditoria"
                                                                        links={[
                                                                            {
                                                                                title: "Usuarios",
                                                                                href: "/actions",
                                                                                icon: (
                                                                                    <AssignmentIcon fontSize="large" />
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: "Créditos",
                                                                                href: "/credits/logs",
                                                                                icon: (
                                                                                    <CreditCardIcon fontSize="large" />
                                                                                ),
                                                                            },
                                                                            {
                                                                                title: "Automática",
                                                                                href: "/reportes",
                                                                                icon: (
                                                                                    <AutorenewIcon fontSize="large" />
                                                                                ),
                                                                            },
                                                                        ]}
                                                                        md={4}
                                                                    />
                                                                }
                                                            ></Route>
                                                        </Route>

                                                        <Route path="edit-invoices" element={<Facturacion />}></Route>

                                                        <Route path="actions" element={<UserActions />}></Route>
                                                        <Route path="cookie-policy" element={<CookiePolicy />}></Route>
                                                        <Route
                                                            path="terms-conditions"
                                                            element={<TermsAndConditions />}
                                                        ></Route>
                                                        <Route path="AboutUs" element={<AboutUs />}></Route>
                                                        <Route path="credits/logs" element={<CreditLogs />}></Route>

                                                        <Route path="contact" element={<ContactUs />}></Route>
                                                        <Route path="freetest" element={<FreeTest />}></Route>
                                                        <Route path="buy">
                                                            <Route index element={<BuyAndRegister />} />
                                                            <Route path=":id" element={<BuyResume />} />
                                                        </Route>
                                                    </Route>
                                                </Routes>
                                            )}
                                        </React.Fragment>
                                    )}
                                </AuthContext.Consumer>
                            </Box>
                            {!cookies.cookie_consent && (
                                <Snackbar
                                    open={cookieConsentOpen}
                                    onClose={() => {
                                        setCookieConsentOpen(false);
                                    }}
                                >
                                    <SnackbarContent
                                        message={
                                            <Typography>
                                                Nuestro sitio web utiliza las cookies necesarias para gestionarla,
                                                mostrar contenidos personalizados y evaluar el rendimiento con el fin de
                                                mejorar tu experiencia como usuario. <br />
                                                Si desea más información visite nuestra &nbsp;
                                                <Link
                                                    component={RouterLink}
                                                    to="/cookie-policy"
                                                    style={{ color: "#ef7f01" }}
                                                >
                                                    Política de Cookies
                                                </Link>
                                                .
                                                <br /> Puedes ACEPTAR todas las cookies o configurarlas rechazando su
                                                uso.
                                            </Typography>
                                        }
                                        action={
                                            <Button
                                                style={{ color: "#ef7f01" }}
                                                onClick={() => {
                                                    setCookie("cookie_consent", true, {
                                                        maxAge: 60 * 60 * 24 * 365,
                                                    });
                                                    setCookieConsentOpen(false);
                                                }}
                                            >
                                                Aceptar
                                            </Button>
                                        }
                                    />
                                </Snackbar>
                            )}
                            <Box
                                component="footer"
                                sx={{
                                    width: "100%",
                                    backgroundImage: `url(${fondo})`,
                                    backgroundSize: "cover",
                                }}
                            >
                                <AppBar
                                    position="static"
                                    color="transparent"
                                    sx={{ bottom: "0", padding: "1rem", marginTop: "1rem" }}
                                >
                                    <Container>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Grid item xs={6} sm={6} md={4}>
                                                <List>
                                                    <ListItem>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.8rem",
                                                                "@media (min-width:600px)": {
                                                                    fontSize: "0.9rem",
                                                                },
                                                                [theme.breakpoints.up("md")]: {
                                                                    fontSize: "1rem",
                                                                },
                                                            }}
                                                        >
                                                            <Link component={RouterLink} to="/contact" color="#ffffff">
                                                                Contactar
                                                            </Link>
                                                        </Typography>
                                                    </ListItem>
                                                    {/*
                                                    <ListItem>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.8rem",
                                                                "@media (min-width:600px)": {
                                                                    fontSize: "0.9rem",
                                                                },
                                                                [theme.breakpoints.up("md")]: {
                                                                    fontSize: "1rem",
                                                                },
                                                            }}
                                                        >
                                                            <Link href="/help" color="#ffffff">
                                                                Documentación
                                                            </Link>
                                                        </Typography>
                                                    </ListItem>
                                                        */}
                                                    <ListItem>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.8rem",
                                                                "@media (min-width:600px)": {
                                                                    fontSize: "0.9rem",
                                                                },
                                                                [theme.breakpoints.up("md")]: {
                                                                    fontSize: "1rem",
                                                                },
                                                            }}
                                                        >
                                                            <Link
                                                                component={RouterLink}
                                                                to="/cookie-policy"
                                                                color="#ffffff"
                                                            >
                                                                Política de Cookies
                                                            </Link>
                                                        </Typography>
                                                    </ListItem>
                                                    <ListItem>
                                                        <Typography
                                                            sx={{
                                                                fontSize: "0.8rem",
                                                                "@media (min-width:600px)": {
                                                                    fontSize: "0.9rem",
                                                                },
                                                                [theme.breakpoints.up("md")]: {
                                                                    fontSize: "1rem",
                                                                },
                                                            }}
                                                        >
                                                            <Link
                                                                component={RouterLink}
                                                                to="/terms-conditions"
                                                                color="#ffffff"
                                                            >
                                                                Términos y Condiciones
                                                            </Link>
                                                        </Typography>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            <Grid item xs={4} sm={4} container spacing={2} sx={{ display: "block" }}>
                                                <Stack direction="row" spacing={2} marginTop="10px">
                                                    <IconButton
                                                        aria-label="twitter"
                                                        color="secondary"
                                                        size="large"
                                                        href="https://twitter.com/mirayconsulting"
                                                        sx={{ margin: "5px", color: "white" }}
                                                    >
                                                        <TwitterIcon sx={{ fontSize: 35 }} />
                                                    </IconButton>
                                                    <IconButton
                                                        aria-label="twitter"
                                                        href="https://www.linkedin.com/company/miray-consulting"
                                                        color="secondary"
                                                        size="large"
                                                        sx={{ margin: "5px", color: "white" }}
                                                    >
                                                        <LinkedInIcon sx={{ fontSize: 35 }} />
                                                    </IconButton>
                                                </Stack>
                                            </Grid>
                                            <Grid item container xs={4}>
                                                <Grid item xs={12} textAlign="right">
                                                    <img height="100px" src={logo}></img>
                                                </Grid>
                                                <Grid item xs={12} textAlign="right">
                                                    <img height="35px" src={uanataca}></img>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </AppBar>
                            </Box>
                        </BrowserRouter>
                    </ProvideAuth>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.Fragment>
    );
}

export default App;
