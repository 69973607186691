import { Container, Grid, CardHeader, Card, CardContent, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/client";
import { ApiError, Price } from "../../api/interfaces";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import * as Yup from "yup";
import { useAuth } from "../../UseAuth";
import { Navigate, useParams } from "react-router-dom";

export default function EditPrice() {
    const auth = useAuth()!;
    const { id } = useParams();
    const [price, setPrice] = useState<Price | null>(null);

    useEffect(() => {
        let active = true;

        if (auth.user && auth.isSuperUser) {
            api.get_price(id!).then(
                (res) => {
                    if (!active) return;
                    setPrice(res.result);
                },
                () => {},
            );
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, id]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    if (!price) {
        return (
            <>
                <Skeleton variant="rectangular" height={400} />
                <Skeleton variant="text" height={100} />
            </>
        );
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Añadir un precio" titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <CustomForm
                                fields={{
                                    title: {
                                        label: "Nombre*",
                                        type: CustomFieldType.Text,
                                    },
                                    description: {
                                        label: "Descripción",
                                        type: CustomFieldType.Text,
                                    },
                                    quantity: {
                                        label: "Cantidad",
                                        type: CustomFieldType.Number,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            let total = value * formProps.values.price;
                                            if (total === NaN) total = 0;
                                            formProps.setFieldValue("total_price", total);
                                        },
                                    },
                                    price: {
                                        label: "Precio por firma",
                                        type: CustomFieldType.Number,
                                        xs: 6,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            let total = formProps.values.quantity * value;
                                            if (total === NaN) total = 0;

                                            if (formProps.values.total_price !== total) {
                                                formProps.setFieldValue("total_price", total);
                                            }
                                        },
                                    },
                                    total_price: {
                                        label: "Precio Total",
                                        type: CustomFieldType.Number,
                                        xs: 6,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            if (formProps.values.quantity == 0) return;
                                            let price = value / formProps.values.quantity;
                                            if (price === NaN) price = 0;
                                            if (formProps.values.price !== price) {
                                                formProps.setFieldValue("price", price);
                                            }
                                        },
                                    },
                                }}
                                schema={Yup.object().shape({
                                    title: Yup.string().required("Requerido"),
                                    description: Yup.string().nullable().optional(),
                                    quantity: Yup.number()
                                        .min(0)
                                        .integer("Debe ser un numero entero")
                                        .required("Requerido"),
                                    price: Yup.number().min(0).required("Requerido"),
                                    total_price: Yup.number().min(0).required("Requerido"),
                                })}
                                initialValues={{
                                    title: price.title,
                                    description: price.description || "",
                                    quantity: price.quantity,
                                    price: Math.floor(price.price / 100) / price.quantity,
                                    total_price: Math.floor(price.price / 100),
                                }}
                                submitText="Actualizar"
                                onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                    api.update_price(
                                        price.id,
                                        values.title,
                                        values.description,
                                        values.quantity,
                                        Math.round(values.total_price * 100),
                                    )
                                        .then(
                                            (res) => {
                                                setMessage({
                                                    message: `Precio actualizado`,
                                                    type: "success",
                                                });
                                                setPrice(res.result);
                                                resetForm();
                                            },
                                            (err: ApiError) => {
                                                if (err.field_errors) {
                                                    api.parse_field_errors(err.field_errors, setFieldError);
                                                }
                                                setMessage({
                                                    message: err.error,
                                                    type: "error",
                                                });
                                            },
                                        )
                                        .finally(() => setSubmitting(false));
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
