import { Card, CardContent, CardHeader, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../api/client";

import { CompanySMTPSettings, SmtpRelayMode, User } from "../api/interfaces";
import { Navigate } from "react-router-dom";
import { useAuth } from "../UseAuth";
import SendTestMailForm from "../components/forms/SendTestMailForm";
import UpdateSMTPForm from "../components/forms/UpdateSMTPForm";

export default function UpdateSMTPSettings() {
    const auth = useAuth()!;

    const [settings, setSettings] = useState<CompanySMTPSettings>({
        domain: "",
        secure_mode: SmtpRelayMode.Tls,
        port: 465,
        username: "",
        password: "",
        from_email: "",
    });

    useEffect(() => {
        let active = true;

        if (auth.user && !auth.isSuperUser && (auth.user as User).admin) {
            api.get_smtp_settings().then(
                (settings) => {
                    if (active) setSettings(settings.result);
                },
                () => {
                    // do nothing on error
                },
            );
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser || !(auth.user as User).admin) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={`Configurar Correo`} titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <Typography>Configurar acceso al servidor SMTP (emails)</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <UpdateSMTPForm settings={settings} onEdited={(s) => setSettings(s)} />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4">Enviar un email de prueba</Typography>
                            <br />
                            <SendTestMailForm />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
