import { Card, CardContent, Container, Typography, Grid } from "@mui/material";
import React, { useState } from "react";
import { ApiError, Department, DepartmentQuery, User } from "../api/interfaces";
import { useEffect } from "react";
import * as Yup from "yup";
import DepartmentCard from "../components/DepartmentCard";
import SearchCard from "../components/SearchCard";
import ItemList from "../components/ItemList";
import { AccordionForm, CustomFieldType } from "../components/forms/CustomForm";
import api, { QUERY_LIMIT } from "../api/client";
import { Navigate } from "react-router-dom";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import usePaginate from "../components/UsePage";

export default function Departments() {
    const auth = useAuth()!;
    const [users, setUsers] = useState<User[]>([]);
    const [usersPage, setUsersPage] = useState(0);
    const {
        items,
        nextPage,
        hasMore,
        reset: resetDeps,
        setQuery,
    } = usePaginate<Department, DepartmentQuery>(0, {}, (query, page) => api.list_departments(query, page), true);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_users({}, usersPage).then((res) => {
                if (!active) return;

                if (usersPage === 0) setUsers(res.result.data);
                else setUsers((currentItems) => currentItems.concat(res.result.data));
                if (res.result.data.length >= QUERY_LIMIT) setUsersPage((p) => p + 1);
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, usersPage]);

    if (auth.loading) {
        return null;
    }

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    const user = auth.user as User;

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Departamentos - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Departamentos
                            </Typography>
                            <Typography>Aquí puedes ver y administrar los departamentos.</Typography>
                            <Typography>
                                Los departamentos permiten separar a los usuarios en grupos y tener confidencialidad.
                                <br />
                                Solo los usuarios de un mismo departamento pueden ver los documentos subidos (a no ser
                                que el usuario sea administrador)
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {user.admin && (
                    <Grid item xs={12}>
                        <AccordionForm
                            title="Crear un departamento"
                            fields={{
                                name: {
                                    label: "Nombre",
                                    type: CustomFieldType.Text,
                                },
                                description: {
                                    label: "Descripción",
                                    type: CustomFieldType.Text,
                                },
                            }}
                            schema={Yup.object().shape({
                                name: Yup.string().required("Requerido"),
                                description: Yup.string(),
                            })}
                            initialValues={{
                                name: "",
                                description: "",
                            }}
                            submitText="Crear"
                            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                api.create_department(values.name, values.description)
                                    .then(
                                        () => {
                                            setMessage({
                                                message: `Departamento creado`,
                                                type: "success",
                                            });
                                            resetDeps();
                                            resetForm();
                                        },
                                        (err: ApiError) => {
                                            if (err.field_errors !== null) {
                                                api.parse_field_errors(err.field_errors, setFieldError);
                                            }
                                            setMessage({
                                                message: err.error,
                                                type: "error",
                                            });
                                        },
                                    )
                                    .finally(() => setSubmitting(false));
                            }}
                        />
                    </Grid>
                )}
                {user.admin && (
                    <Grid item xs={12}>
                        <AccordionForm
                            title="Añadir usuario a un departamento"
                            fields={{
                                user: {
                                    label: "Usuario",
                                    type: CustomFieldType.Select,
                                    options: users.map((user) => ({ label: user.name, value: user.id })),
                                },
                                department: {
                                    label: "Departamento",
                                    type: CustomFieldType.Select,
                                    options: items.map((dep) => ({ label: dep.name, value: dep.id })),
                                },
                            }}
                            schema={Yup.object().shape({
                                department: Yup.string().required("Requerido"),
                                user: Yup.string().required("Requerido"),
                            })}
                            initialValues={{
                                department: "",
                                user: "",
                            }}
                            submitText="Añadir"
                            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                api.assign_user_department(values.department!, values.user!)
                                    .then(
                                        () => {
                                            setMessage({
                                                message: `Usuario actualizado`,
                                                type: "success",
                                            });
                                            resetForm();
                                        },
                                        (err: ApiError) => {
                                            if (err.field_errors !== null) {
                                                api.parse_field_errors(err.field_errors, setFieldError);
                                            }
                                            setMessage({
                                                message: err.error,
                                                type: "error",
                                            });
                                        },
                                    )
                                    .finally(() => {
                                        setSubmitting(false);
                                    });
                            }}
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <SearchCard
                        onSearch={(values, setSubmitting) => {
                            setQuery(values);
                            resetDeps();
                            setSubmitting(false);
                        }}
                        queries={[
                            {
                                name: "name",
                                type: "boolean",
                                label: "Nombre",
                            },
                            {
                                name: "description",
                                type: "boolean",
                                label: "Descripción",
                            },
                        ]}
                    />
                </Grid>

                <Grid item xs={12}>
                    <ItemList
                        items={items}
                        hasMore={hasMore}
                        onLoadMoreItems={() => {
                            nextPage();
                        }}
                        renderItem={(item: Department) => {
                            if (user.admin) {
                                return <DepartmentCard dep={item} />;
                            }
                            return <DepartmentCard dep={item} />;
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
