import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    AlertTitle,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    Link,
    Paper,
    Skeleton,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import api from "../api/client";
import { AddClientsCsv, AgendaClient, AgendaGroup, ApiError } from "../api/interfaces";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import { useAuth } from "../UseAuth";
import * as Yup from "yup";
import { DataGrid, esES, GridApi, GridColDef } from "@mui/x-data-grid";
import { Link as RouterLink } from "react-router-dom";
import { AgendaGroupColumns } from "./Agenda";
import ConfirmButton from "../components/ConfirmButton";
import { Helmet } from "react-helmet-async";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AgendaGroupForm from "../components/forms/AgendaGroupForm";
import AgendaContactForm from "../components/forms/AgendaContactForm";
import AgendaEditGroupForm from "../components/forms/AgendaEditGroupForm";

export const AgendaClientColumns: GridColDef[] = [
    {
        field: "ver",
        headerName: "Ver",
        sortable: false,
        renderCell: (params) => {
            const api: GridApi = params.api;
            const thisRow: Record<string, any> = {};

            api.getAllColumns()
                .filter((c) => c.field !== "__check__" && !!c)
                .forEach((c) => (thisRow[c.field] = params.getValue(params.id, c.field)));

            return (
                <Button color="primary" component={RouterLink} to={`/agenda/clients/${thisRow.id}`}>
                    Ver
                </Button>
            );
        },
    },
    {
        field: "id",
        headerName: "ID",
        hide: true,
    },
    {
        field: "name",
        headerName: "Nombre",
        editable: false,
        resizable: true,
        flex: 1,
    },
    {
        field: "phone",
        headerName: "Teléfono",
        editable: false,
        resizable: true,
        flex: 1,
    },
    {
        field: "email",
        headerName: "Email",
        editable: false,
        resizable: true,
        flex: 1,
    },
    {
        field: "dni",
        headerName: "DNI",
        editable: false,
        resizable: true,
        flex: 1,
    },
];

export default function AgendaGroupDetail() {
    const auth = useAuth()!;
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [group, setGroup] = useState<AgendaGroup | null>(null);
    const [parentGroups, setParentGroups] = useState<AgendaGroup[]>([]);
    const [groups, setGroups] = useState<AgendaGroup[]>([]);
    const [contacts, setContacts] = useState<AgendaClient[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser && id) {
            api.group_detail(id).then((res) => {
                if (!active) return;
                setGroup(res.result);
                setContacts([]);
                setGroups([]);
                setParentGroups([]);
                api.group_subgroups_all(id, (res) => {
                    if (active) {
                        setGroups((i) => i.concat(res.result.data));
                    }
                });
                api.group_clients_all(id, (res) => {
                    if (active) {
                        setContacts((i) => i.concat(res.result.data));
                    }
                });
                if (res.result.parent_id) {
                    api.find_all_parent_groups(res.result.parent_id).then((res) => {
                        setParentGroups(res);
                    });
                }
                setLoading(false);
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser, id]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    if (loading) {
        return (
            <Container sx={{ flexGrow: 1 }}>
                <Card>
                    <CardContent>
                        <Skeleton variant="rectangular" height={200} />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" width="60%" />
                    </CardContent>
                </Card>
            </Container>
        );
    }

    if (!group) return null;

    return (
        <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>{group.name} - Agenda - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Agenda: Grupo {group.name}
                            </Typography>
                            {parentGroups.length > 0 && (
                                <Breadcrumbs aria-label="breadcrumb">
                                    {parentGroups.map((x) => (
                                        <Link
                                            underline="hover"
                                            color="inherit"
                                            key={x.id}
                                            component={RouterLink}
                                            to={`/agenda/groups/${x.id}`}
                                        >
                                            {x.name}
                                        </Link>
                                    ))}
                                    <Typography color="text.primary">{group.name}</Typography>
                                </Breadcrumbs>
                            )}
                            <Typography>{group.description}</Typography>
                        </CardContent>
                        <CardActions>
                            <ConfirmButton
                                onDelete={() => {
                                    api.delete_group(group.id).then(() => {
                                        navigate("/agenda", { replace: true });
                                    });
                                }}
                            >
                                Eliminar
                            </ConfirmButton>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <AgendaEditGroupForm group={group} onEdited={(g) => setGroup(g)} />
                </Grid>
                <Grid item xs={12}>
                    <AgendaGroupForm
                        onNewGroup={(new_group) =>
                            setGroups((g) => g.concat(new_group).sort((a, b) => a.name.localeCompare(b.name)))
                        }
                        parent_id={group.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AgendaContactForm
                        onNewContact={(contact) =>
                            setContacts((g) => g.concat(contact).sort((a, b) => a.name.localeCompare(b.name)))
                        }
                        group_id={group.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">Añadir contactos desde un archivo CSV</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Alert severity="info" sx={{ marginBottom: "10px" }}>
                                <AlertTitle>Importante</AlertTitle>
                                <ul>
                                    <li>
                                        <Typography>
                                            El archivo csv debe tener los siguientes campos: name, phone, dni, email.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            El campo phone (teléfono) debe contener al principio el prefijo, por ejemplo
                                            +34: +34666666666.
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>El campo name contiene el nombre y apellidos.</Typography>
                                    </li>
                                </ul>
                                <Typography>
                                    Ejemplo:
                                    <pre>
                                        <code>
                                            name,phone,dni,email
                                            <br />
                                            John Doe,+34666666666,123456789A,user@example.com
                                        </code>
                                    </pre>
                                </Typography>
                            </Alert>
                            <CustomForm
                                fields={{
                                    clients: {
                                        type: CustomFieldType.File,
                                        label: "Archivo CSV*",
                                        accept: "text/csv",
                                        sm: 6,
                                    },
                                }}
                                initialValues={{
                                    clients: undefined as File | undefined,
                                }}
                                schema={Yup.object().shape({
                                    clients: Yup.mixed()
                                        .required("Requerido")
                                        .test("fileFormat", "Solo CSV", (value) => {
                                            return value && ["text/csv"].includes(value.type);
                                        })
                                        .test("fileSize", "El archivo es demasiado grande, máximo 25mb.", (file) => {
                                            if (!file) return false;
                                            const size = file.size / 1024 / 1024;
                                            return size <= 25;
                                        }),
                                })}
                                onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                    api.add_clients_csv(group.id, values as AddClientsCsv)
                                        .then(
                                            () => {
                                                resetForm();
                                                setMessage({
                                                    message: `Contactos añadidos`,
                                                    type: "success",
                                                });
                                                setContacts([]);
                                                api.group_clients_all(id!, (res) => {
                                                    setContacts((i) => i.concat(res.result.data));
                                                });
                                            },
                                            (err: ApiError) => {
                                                if (err.field_errors !== null) {
                                                    api.parse_field_errors(err.field_errors, setFieldError);
                                                }
                                                setMessage(err.get_error_msg());
                                            },
                                        )
                                        .finally(() => {
                                            setSubmitting(false);
                                        });
                                }}
                                submitText="Subir"
                            />
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Subgrupos
                        </Typography>
                        <Box sx={{ height: "250px" }}>
                            <Box sx={{ display: "flex", height: "100%" }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={AgendaGroupColumns}
                                        rows={groups.map((x) => ({
                                            ...x,
                                            created_at: new Date(x.created_at),
                                        }))}
                                        disableSelectionOnClick
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h5" gutterBottom>
                            Contactos
                        </Typography>
                        <Box sx={{ height: "400px" }}>
                            <Box sx={{ display: "flex", height: "100%" }}>
                                <Box sx={{ flexGrow: 1 }}>
                                    <DataGrid
                                        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                        columns={AgendaClientColumns}
                                        rows={contacts}
                                        disableSelectionOnClick
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
