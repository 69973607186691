import React from "react";
import PHONE_PREFIXES from "../../phone_prefixes";
import CustomForm, { CustomFieldType } from "./CustomForm";
import { ApiError } from "../../api/interfaces";
import api from "../../api/client";
import * as Yup from "yup";

export default function ContactForm() {
    return (
        <CustomForm
            fields={{
                name: {
                    label: "Nombre y apellido*",
                    type: CustomFieldType.Text,
                    sm: 6,
                },
                email: {
                    label: "Email de contacto*",
                    type: CustomFieldType.Email,
                    sm: 6,
                },
                company: {
                    label: "Empresa*",
                    type: CustomFieldType.Text,
                },
                phone_prefix: {
                    type: CustomFieldType.Select,
                    label: "Prefijo",
                    options: PHONE_PREFIXES.countries.map((x) => ({
                        label: `${x.dial_code} - ${x.name_es}`,
                        value: x.dial_code,
                    })),
                    sm: 4,
                    xs: 12,
                },
                phone: {
                    type: CustomFieldType.Text,
                    label: "Teléfono móvil*",
                    sm: 8,
                    xs: 12,
                },
                comment: {
                    label: "Comentario",
                    type: CustomFieldType.Text,
                    multiline: true,
                    rows: 3,
                },
            }}
            schema={Yup.object().shape({
                name: Yup.string().required("Requerido"),
                email: Yup.string().email("Tiene que ser un email válido.").required("Requerido"),
                company: Yup.string().required("Requerido"),
                comment: Yup.string().max(4000, "Máximo 4000 carácteres.").optional(),
                phone: Yup.string()
                    .test({
                        name: "phone_correct_test",
                        test: (value: any) => {
                            return /^\d{9,10}$/.test(value);
                        },
                        message: "No es un teléfono válido",
                    })
                    .required("Requerido"),
            })}
            initialValues={{
                name: "",
                email: "",
                company: "",
                comment: "",
                phone: "",
                phone_prefix: PHONE_PREFIXES.countries[201].dial_code,
            }}
            submitText="Enviar"
            onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                api.contact(values)
                    .then(
                        () => {
                            if (process.env.REACT_APP_ENVIROMENT !== "development") gtag("event", "contact_us_form");
                            setMessage({
                                message: `Petición enviada, pronto nos pondremos en contacto.`,
                                type: "success",
                            });
                            resetForm();
                        },
                        (err: ApiError) => {
                            if (err.field_errors) {
                                api.parse_field_errors(err.field_errors, setFieldError);
                            }
                            setMessage({
                                message: err.error,
                                type: "error",
                            });
                        },
                    )
                    .finally(() => setSubmitting(false));
            }}
        />
    );
}
