import { Checkbox, FormControlLabel, Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import React, { useState } from "react";
import { AgendaClient, AgendaGroup } from "../../api/interfaces";

type Props = {
    groups: readonly AgendaGroup[];
    contacts: readonly AgendaClient[];
    addContactFunction: (data: AgendaClient[]) => void;
};

type CheckboxRowProps = {
    depth: number;
    checkedMap: Map<string, boolean>;
    updateMap: (k: string, v: boolean) => void;
    groups: readonly AgendaGroup[];
    contacts: readonly AgendaClient[];
    parent_group: string | null;
    checkAllChildren: (parent_id: string, value: boolean) => void;
    checkParent: (group_id: string) => void;
};

function CheckboxRow(props: CheckboxRowProps) {
    return (
        <Box
            sx={{
                display: "flex",
                flexWrap: "wrap",
                ml: 1,
                flexDirection: "column",
                borderLeft: props.depth === 0 ? "" : "2px solid #243a8d",
                paddingLeft: props.depth === 0 ? "" : "10px",
            }}
        >
            {props.contacts
                .filter((c) => c.group_id === props.parent_group)
                .map((contact) => (
                    <FormControlLabel
                        key={contact.id}
                        label={`Contacto: ${contact.name}`}
                        control={
                            <Checkbox
                                checked={props.checkedMap.get(contact.id)}
                                onChange={() => {
                                    props.updateMap(contact.id, !props.checkedMap.get(contact.id));
                                    props.checkParent(contact.group_id);
                                }}
                            />
                        }
                    />
                ))}
            {props.groups
                .filter((g) => g.parent_id === props.parent_group)
                .sort((a, b) => {
                    return (
                        props.contacts.filter((x) => x.group_id === b.id).length -
                        props.contacts.filter((x) => x.group_id === a.id).length
                    );
                })
                .map((group) => {
                    if (props.depth === 0) {
                        return (
                            <Accordion key={`accordion-${group.id}`} onClickCapture={() => {}}>
                                <AccordionSummary>
                                    <FormControlLabel
                                        label={`Grupo: ${group.name}`}
                                        control={
                                            <Checkbox
                                                checked={props.checkedMap.get(group.id)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    const value = !props.checkedMap.get(group.id);
                                                    props.updateMap(group.id, value);
                                                    props.checkAllChildren(group.id, value);
                                                    if (group.parent_id !== null) {
                                                        props.checkParent(group.parent_id);
                                                    }
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                            />
                                        }
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CheckboxRow
                                        updateMap={props.updateMap}
                                        checkedMap={props.checkedMap}
                                        depth={props.depth + 1}
                                        groups={props.groups}
                                        contacts={props.contacts}
                                        parent_group={group.id}
                                        checkAllChildren={props.checkAllChildren}
                                        checkParent={props.checkParent}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        );
                    } else {
                        return (
                            <Box key={`box-${group.id}`}>
                                <FormControlLabel
                                    label={`Grupo: ${group.name}`}
                                    control={
                                        <Checkbox
                                            checked={props.checkedMap.get(group.id)}
                                            onChange={() => {
                                                const value = !props.checkedMap.get(group.id);
                                                props.updateMap(group.id, value);
                                                props.checkAllChildren(group.id, value);
                                                if (group.parent_id !== null) {
                                                    props.checkParent(group.parent_id);
                                                }
                                            }}
                                        />
                                    }
                                />
                                <CheckboxRow
                                    updateMap={props.updateMap}
                                    checkedMap={props.checkedMap}
                                    depth={props.depth + 1}
                                    groups={props.groups}
                                    contacts={props.contacts}
                                    parent_group={group.id}
                                    checkAllChildren={props.checkAllChildren}
                                    checkParent={props.checkParent}
                                />
                            </Box>
                        );
                    }
                })}
        </Box>
    );
}

export default function SelectAgendaForm(props: Props) {
    const { groups, contacts } = props;
    const [checkedMap, setCheckedMap] = useState<Map<string, boolean>>(() => {
        const checkedMap = new Map<string, boolean>();
        for (const group of groups) {
            checkedMap.set(group.id, false);
        }
        for (const contact of contacts) {
            checkedMap.set(contact.id, false);
        }
        return checkedMap;
    });

    const resetMap = () => {
        setCheckedMap(() => {
            const checkedMap = new Map<string, boolean>();
            for (const group of groups) {
                checkedMap.set(group.id, false);
            }
            for (const contact of contacts) {
                checkedMap.set(contact.id, false);
            }
            return checkedMap;
        });
    };

    const updateMap = (k: string, v: boolean) => {
        const newMap = new Map(checkedMap.set(k, v));
        setCheckedMap(newMap);
        props.addContactFunction(contacts.filter((contact) => newMap.get(contact.id) === true));
    };

    function checkAllChildren(parent_id: string, value: boolean) {
        checkAllChildrenImpl(parent_id, value);
        const newMap = new Map(checkedMap);
        setCheckedMap(newMap);
        props.addContactFunction(contacts.filter((contact) => newMap.get(contact.id) === true));
    }

    function checkAllChildrenImpl(parent_id: string, value: boolean) {
        // Check all contacts of this group.
        for (const contact of contacts) {
            if (contact.group_id === parent_id) {
                checkedMap.set(contact.id, value);
            }
        }

        // Recursively check all sub groups of this group.
        for (const group of groups) {
            if (group.parent_id === parent_id) {
                checkedMap.set(group.id, value);
                checkAllChildrenImpl(group.id, value);
            }
        }
    }

    // Check if parent group should be checked due to all children being checked.
    function checkParent(group_id: string) {
        for (const contact of contacts) {
            if (contact.group_id === group_id) {
                if (!checkedMap.get(contact.id)) return;
            }
        }

        // Recursively check all sub groups of this group.
        for (const group of groups) {
            if (group.parent_id === group_id) {
                if (!checkedMap.get(group.id)) return;
            }
        }

        updateMap(group_id, true);

        const group = groups.find((g) => g.id === group_id)!;
        if (group.parent_id !== null) {
            checkParent(group.parent_id);
        }
    }

    return (
        <Box>
            <Box
                sx={{
                    maxHeight: "500px",
                    overflow: "auto",
                    padding: "5px",
                }}
            >
                <CheckboxRow
                    updateMap={updateMap}
                    checkedMap={checkedMap}
                    depth={0}
                    groups={groups}
                    contacts={contacts}
                    parent_group={null}
                    checkAllChildren={checkAllChildren}
                    checkParent={checkParent}
                />
            </Box>
        </Box>
    );
}
