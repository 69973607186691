import { Card, CardContent, Container, Grid, Link, Slide, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import api from "../api/client";
import { Price } from "../api/interfaces";
import CustomForm, { CustomFieldType } from "../components/forms/CustomForm";
import PriceCard from "../components/PriceCard";
import * as Yup from "yup";
import { Link as RouterLink, Navigate } from "react-router-dom";
import { Box } from "@mui/system";
import LinkedInTag from "react-linkedin-insight";
import { useAuth } from "../UseAuth";
import { Helmet } from "react-helmet-async";
import { countries } from "../countries";

export default function BuyAndRegister() {
    const auth = useAuth()!;
    const [prices, setPrices] = useState<Price[]>([]);
    const [selectedPrice, setSelectedPrice] = useState<Price | null>(null);
    const [formComplete, setFormComplete] = useState(false);

    useEffect(() => {
        let active = true;

        if (!auth.user) {
            api.list_prices().then(
                (res) => {
                    if (!active) return;
                    setPrices(res.result.data);
                },
                () => {},
            );
        }

        return () => {
            active = false;
        };
    }, [auth.user]);

    // Using common sense: lowest price will be the most costly option. So we base the save percentage based on it.
    const lowestPrice = useMemo(() => {
        if (prices.length === 0) return undefined;

        const lowestPrice = prices.reduce((p, c) => {
            if (p.price < c.price) {
                return p;
            }
            return c;
        });

        return lowestPrice;
    }, [prices]);

    if (auth.user && selectedPrice && formComplete) {
        return <Navigate to={`/buy/${selectedPrice.id}`} />;
    }

    return (
        <Container
            style={{
                maxWidth: "90%",
            }}
        >
            <Helmet>
                <title>Comprar - Miray Sign</title>
            </Helmet>
            {selectedPrice === null && (
                <React.Fragment>
                    <Grid container spacing={2} justifyContent="center">
                        {prices.map((price, i) => (
                            <Slide in={true} key={price.id} direction="left" timeout={250 + i * 250}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <PriceCard
                                        price={price}
                                        onBuy={(setLoading: React.Dispatch<React.SetStateAction<boolean>>) => {
                                            if (process.env.REACT_APP_ENVIROMENT !== "development") {
                                                gtag("event", "select_item", {
                                                    item_list_id: "bono_tarifas",
                                                    item_list_name: "Tarifas",
                                                    items: [
                                                        {
                                                            item_id: price.id,
                                                            item_name: price.title,
                                                            currency: "EUR",
                                                            price: price.price,
                                                            quantity: 1,
                                                        },
                                                    ],
                                                });
                                            }
                                            setSelectedPrice(price);
                                            setLoading(false);
                                        }}
                                        savePercent={
                                            lowestPrice && price.id !== lowestPrice.id
                                                ? Math.abs(
                                                      lowestPrice.price / lowestPrice.quantity -
                                                          price.price / price.quantity,
                                                  )
                                                : undefined
                                        }
                                    />
                                </Grid>
                            </Slide>
                        ))}
                    </Grid>
                </React.Fragment>
            )}
            {selectedPrice !== null && (
                <Slide in={true} direction="left">
                    <Card>
                        <CardContent>
                            <Typography variant="h2" gutterBottom>
                                Registrarse y comprar
                            </Typography>
                            <CustomForm
                                fields={{
                                    name: {
                                        type: CustomFieldType.Text,
                                        label: "Nombre y apellido*",
                                        sm: 6,
                                    },
                                    email: {
                                        type: CustomFieldType.Email,
                                        label: "Email*",
                                        sm: 6,
                                    },
                                    password: {
                                        type: CustomFieldType.Password,
                                        label: "Contraseña*",
                                        sm: 12,
                                    },
                                    company_name: {
                                        type: CustomFieldType.Text,
                                        label: "Nombre de la empresa*",
                                        sm: 6,
                                    },
                                    company_cif: {
                                        type: CustomFieldType.Text,
                                        label: "CIF/DNI*",
                                        sm: 6,
                                    },

                                    country: {
                                        type: CustomFieldType.Select,
                                        options: countries.map((x) => ({
                                            label: (
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                    }}
                                                >
                                                    <img
                                                        loading="lazy"
                                                        style={{
                                                            marginRight: 2,
                                                        }}
                                                        src={`https://flagcdn.com/${x.alpha2}.svg`}
                                                        width="30"
                                                    />
                                                    {x.name}
                                                </Box>
                                            ),
                                            value: x.alpha2,
                                        })),
                                        label: "País*",
                                        sm: 3,
                                    },
                                    city: {
                                        type: CustomFieldType.Text,
                                        label: "Ciudad*",
                                        sm: 6,
                                    },
                                    postal_code: {
                                        type: CustomFieldType.Text,
                                        label: "Código postal*",
                                        sm: 3,
                                    },
                                    address_line1: {
                                        type: CustomFieldType.Text,
                                        label: "Dirección 1*",
                                    },
                                    address_line2: {
                                        type: CustomFieldType.Text,
                                        label: "Dirección 2",
                                    },

                                    terms: {
                                        label: "He leído y acepto los términos y condiciones",
                                        labelNode: (
                                            <React.Fragment>
                                                He leído y acepto los{" "}
                                                <Link color="primary" component={RouterLink} to="/terms-conditions">
                                                    términos y condiciones
                                                </Link>
                                            </React.Fragment>
                                        ),
                                        type: CustomFieldType.Switch,
                                    },
                                }}
                                initialValues={{
                                    email: "",
                                    company_name: "",
                                    password: "",
                                    name: "",
                                    company_cif: "",
                                    city: "",
                                    address_line1: "",
                                    terms: false,
                                    postal_code: "",
                                    country: "es",
                                }}
                                schema={Yup.object().shape({
                                    company_name: Yup.string().min(3).max(100).required("Requerido."),
                                    name: Yup.string().required("Requerido."),
                                    password: Yup.string().min(8, "Mínimo 8 carácteres.").required("Requerido."),
                                    company_cif: Yup.string()
                                        .required("Requerido.")
                                        .test({
                                            name: "dni_test",
                                            test: (value: any) => {
                                                if (!value || value === "") return true;
                                                const DNI_REGEX = /^(\d{8})([A-Z])$/;
                                                const CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
                                                const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
                                                if (DNI_REGEX.test(value)) {
                                                    const dni_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
                                                    const letter = dni_letters.charAt(parseInt(value, 10) % 23);
                                                    return letter == value.charAt(8);
                                                }
                                                if (CIF_REGEX.test(value)) {
                                                    return true;
                                                }
                                                if (NIE_REGEX.test(value)) {
                                                    return true;
                                                }
                                                return false;
                                            },
                                            message: "No es un CIF/DNI válido",
                                        }),
                                    postal_code: Yup.string().required("Requerido."),
                                    country: Yup.string().required("Requerido."),
                                    city: Yup.string().required("Requerido."),
                                    address_line1: Yup.string().required("Requerido."),
                                    email: Yup.string().email("Tiene que ser un email válido.").required("Requerido."),
                                    terms: Yup.boolean()
                                        .isTrue("Debe ser aceptado para proceder.")
                                        .required("Requerido."),
                                })}
                                onSubmit={(values, setSubmitting, setFieldError, setMessage) => {
                                    api.create_company_account(
                                        values.company_name,
                                        values.name,
                                        values.email,
                                        values.password,
                                    )
                                        .then((res) => res.result)
                                        .then(
                                            () => {
                                                api.login(values.email, values.password).then(() => {
                                                    if (process.env.REACT_APP_ENVIROMENT !== "development") {
                                                        gtag("event", "sign_up", {
                                                            method: "BuyAndRegister",
                                                        });
                                                        LinkedInTag.track("6422300");
                                                    }
                                                    // Refresh token
                                                    api.refresh().then(() => {
                                                        // Update billig info
                                                        api.billing(values).then(() => {
                                                            if (process.env.REACT_APP_ENVIROMENT !== "development") {
                                                                gtag("event", "add_payment_info", {
                                                                    currency: "EUR",
                                                                    value: selectedPrice.price,
                                                                    items: [
                                                                        {
                                                                            item_id: selectedPrice.id,
                                                                            item_name: selectedPrice.title,
                                                                            currency: "EUR",
                                                                            price: selectedPrice.price,
                                                                            quantity: 1,
                                                                        },
                                                                    ],
                                                                });
                                                            }
                                                            setSubmitting(false);
                                                            setFormComplete(true);
                                                            auth.login();
                                                        });
                                                    });
                                                });
                                            },
                                            (err) => {
                                                if (err.field_errors !== null) {
                                                    api.parse_field_errors(err.field_errors, setFieldError);
                                                }
                                                setMessage({
                                                    message: err.error,
                                                    type: "error",
                                                });
                                                setSubmitting(false);
                                            },
                                        );
                                }}
                                submitText="Siguiente"
                            />
                        </CardContent>
                    </Card>
                </Slide>
            )}
        </Container>
    );
}
