import { Box, Button, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { Container, Grid, Card } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { Link as RouterLink, Navigate } from "react-router-dom";
import api from "../../api/client";
import { BlogPost } from "../../api/interfaces";
import ConfirmButton from "../../components/ConfirmButton";
import { useAuth } from "../../UseAuth";

export default function BlogListAdmin() {
    const auth = useAuth()!;
    const [posts, setPosts] = useState<BlogPost[]>([]);

    useEffect(() => {
        if (auth.user && auth.isSuperUser) {
            api.get_blog_posts().then((res) => {
                setPosts(res.result.data);
            });
        }
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Lista de entradas del blog
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {posts.map((post) => (
                    <Grid item key={post.id} xs={12} sm={6}>
                        <Card>
                            <CardHeader
                                title={post.title}
                                titleTypographyProps={{ variant: "h4" }}
                                subheader={
                                    <React.Fragment>
                                        <Box marginBottom="5px">{new Date(post.created_at).toLocaleString()}</Box>
                                    </React.Fragment>
                                }
                            ></CardHeader>
                            <CardActions>
                                <Button color="primary" component={RouterLink} to={`/blog/${post.slug}`}>
                                    Ver
                                </Button>
                                <Button color="primary" component={RouterLink} to={`/superuser/blog/edit/${post.slug}`}>
                                    Editar
                                </Button>
                                <ConfirmButton
                                    onDelete={() => {
                                        api.delete_blog_post(post.id).then(
                                            () => {
                                                setPosts((p) => p.filter((x) => x.id !== post.id));
                                            },
                                            () => {},
                                        );
                                    }}
                                >
                                    Eliminar
                                </ConfirmButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
