import { Container, Grid, CardHeader, Card, CardContent, Typography, CardActions, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import api from "../../api/client";
import { ApiError, Price } from "../../api/interfaces";
import CustomForm, { CustomFieldType } from "../../components/forms/CustomForm";
import * as Yup from "yup";
import { useAuth } from "../../UseAuth";
import { Navigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import ConfirmButton from "../../components/ConfirmButton";

export default function Pricing() {
    const auth = useAuth()!;
    const [prices, setPrices] = useState<Price[]>([]);

    useEffect(() => {
        let active = true;

        if (auth.user && auth.isSuperUser) {
            api.list_prices().then(
                (res) => {
                    if (!active) return;
                    setPrices(res.result.data);
                },
                () => {},
            );
        }

        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || !auth.isSuperUser) {
        return <Navigate to="/" />;
    }

    return (
        <Container sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Añadir un precio" titleTypographyProps={{ variant: "h3" }}></CardHeader>
                        <CardContent>
                            <CustomForm
                                fields={{
                                    title: {
                                        label: "Nombre*",
                                        type: CustomFieldType.Text,
                                    },
                                    description: {
                                        label: "Descripción",
                                        type: CustomFieldType.Text,
                                    },
                                    quantity: {
                                        label: "Cantidad",
                                        type: CustomFieldType.Number,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            let total = value * formProps.values.price;
                                            if (total === NaN) total = 0;
                                            formProps.setFieldValue("total_price", total);
                                        },
                                    },
                                    price: {
                                        label: "Precio por firma",
                                        type: CustomFieldType.Number,
                                        xs: 6,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            let total = formProps.values.quantity * value;
                                            if (total === NaN) total = 0;

                                            if (formProps.values.total_price !== total) {
                                                formProps.setFieldValue("total_price", total);
                                            }
                                        },
                                    },
                                    total_price: {
                                        label: "Precio Total",
                                        type: CustomFieldType.Number,
                                        xs: 6,
                                        onChange: (formProps, valueString) => {
                                            let value = 0;
                                            if (valueString !== "") {
                                                value = parseFloat(valueString);
                                                if (isNaN(value)) value = 0;
                                            }
                                            if (formProps.values.quantity == 0) return;
                                            let price = value / formProps.values.quantity;
                                            if (price === NaN) price = 0;
                                            if (formProps.values.price !== price) {
                                                formProps.setFieldValue("price", price);
                                            }
                                        },
                                    },
                                }}
                                schema={Yup.object().shape({
                                    title: Yup.string().required("Requerido"),
                                    description: Yup.string().nullable().optional(),
                                    quantity: Yup.number()
                                        .min(0)
                                        .integer("Debe ser un numero entero")
                                        .required("Requerido"),
                                    price: Yup.number().min(0).required("Requerido"),
                                    total_price: Yup.number().min(0).required("Requerido"),
                                })}
                                initialValues={{
                                    title: "",
                                    description: null,
                                    quantity: 0,
                                    price: 0,
                                    total_price: 0,
                                }}
                                submitText="Crear"
                                onSubmit={(values, setSubmitting, setFieldError, setMessage, resetForm) => {
                                    api.create_price(
                                        values.title,
                                        values.description,
                                        values.quantity,
                                        Math.round(values.total_price * 100),
                                    )
                                        .then(
                                            (res) => {
                                                setMessage({
                                                    message: `Precio creado`,
                                                    type: "success",
                                                });
                                                setPrices((prevPrices) => {
                                                    const newPrices = [...prevPrices, res.result];
                                                    newPrices.sort((a, b) => a.quantity - b.quantity);
                                                    return newPrices;
                                                });
                                                resetForm();
                                            },
                                            (err: ApiError) => {
                                                if (err.field_errors) {
                                                    api.parse_field_errors(err.field_errors, setFieldError);
                                                }
                                                setMessage({
                                                    message: err.error,
                                                    type: "error",
                                                });
                                            },
                                        )
                                        .finally(() => setSubmitting(false));
                                }}
                            />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Lista de precios" titleTypographyProps={{ variant: "h3" }}></CardHeader>
                    </Card>
                </Grid>
                {prices.map((price) => (
                    <Grid item xs={6} key={price.id}>
                        <Card>
                            <CardHeader title={price.title} titleTypographyProps={{ variant: "h4" }}></CardHeader>
                            <CardContent>
                                {price.description && <Typography>{price.description}</Typography>}
                                <Typography>Cantidad: {price.quantity}</Typography>
                                <Typography>Precio: {price.price / 100}&euro;</Typography>
                            </CardContent>
                            <CardActions>
                                <Button color="primary" component={RouterLink} to={`/superuser/pricing/${price.id}`}>
                                    Editar
                                </Button>
                                <ConfirmButton
                                    onDelete={() => {
                                        api.delete_price(price.id).then(() => {
                                            setPrices(prices.filter((p) => p.id !== price.id));
                                        });
                                    }}
                                >
                                    Borrar
                                </ConfirmButton>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
