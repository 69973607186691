import { Card, CardContent, Container, Typography, Grid, Paper } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import api from "../api/client";
import { Balance } from "../api/interfaces";
import { useAuth } from "../UseAuth";
import { DataGrid, GridColDef, esES } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        editable: false,
        flex: 1,
    },
    {
        field: "total_credits",
        headerName: "Créditos Iniciales",
        editable: false,
        flex: 1,
    },
    {
        field: "credits",
        headerName: "Créditos Actuales",
        editable: false,
        flex: 1,
    },
    {
        field: "held_credits",
        headerName: "Créditos en espera",
        editable: false,
        flex: 1,
    },
    {
        field: "expires",
        headerName: "Expira",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
    {
        field: "created_at",
        headerName: "Creado el",
        sortable: true,
        type: "dateTime",
        editable: false,
        flex: 1,
    },
];

export default function CreditBalances() {
    const auth = useAuth()!;
    const [balances, setBalances] = useState<Balance[]>([]);

    useEffect(() => {
        let active = true;
        if (auth.user && !auth.isSuperUser) {
            api.list_all_balances({ min_credits: 0, expired: true }, (res) => {
                if (active) {
                    setBalances((i) => i.concat(res.result.data));
                }
            });
        }
        return () => {
            active = false;
        };
    }, [auth.user, auth.isSuperUser]);

    if (!auth.user || auth.isSuperUser) {
        return <Navigate to="/login" />;
    }

    return (
        <Container maxWidth="xl" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Helmet>
                <title>Créditos - Miray Sign</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h4" gutterBottom>
                                Carteras de Créditos
                            </Typography>
                            <Typography>Aquí puedes ver tus carteras de créditos.</Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12}>
                    <Paper sx={{ padding: 2, height: "80vh" }}>
                        <Box sx={{ display: "flex", height: "100%" }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <DataGrid
                                    localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                                    columns={columns}
                                    rows={balances.map((x) => ({
                                        ...x,
                                        created_at: new Date(x.created_at),
                                        expires: new Date(x.expires),
                                    }))}
                                    disableSelectionOnClick
                                />
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}
