import React, { useContext, useState, useEffect } from "react";
import LinkedInTag from "react-linkedin-insight";
import api from "./api/client";
import { Company, SuperUser, User } from "./api/interfaces";

export interface IAuthContext {
    user: SuperUser | User | null;
    company: Company | null;
    isSuperUser: boolean;
    loading: boolean;
    login: () => void;
    logout: () => Promise<void>;
    updateUser: () => Promise<void>;
    updateCompany: () => Promise<void>;
    refreshToken: (refreshAllData?: boolean) => Promise<void>;
}

export const AuthContext = React.createContext<IAuthContext | null>(null);

export const useAuth = () => {
    return useContext(AuthContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState<User | SuperUser | null>(null);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState<Company | null>(null);
    const [isSuperUser, setIsSuperUser] = useState(false);

    // TODO: load user from storage if x seconds have not passed.

    useEffect(() => {
        LinkedInTag.init("3728532", "dc");
    }, []);

    const login = async () => {
        LinkedInTag.track("6422276");
        localStorage.setItem("authed", "true");
        localStorage.setItem("authed-date", new Date().toString());
        refreshToken(true);
    };

    const logout = async () => {
        await api.logout();
        localStorage.setItem("authed", "false");
        localStorage.removeItem("authed-date");
        localStorage.removeItem("last-refresh");
        setUser(null);
        setIsSuperUser(false);
    };

    const updateUser = async () => {
        try {
            const res = await api.me();
            const superuser = !("admin" in res.result);
            const user = res.result;
            localStorage.setItem("isSuperUser", superuser ? "true" : "false");
            setIsSuperUser(superuser);
            setUser(user);
        } catch (e) {
            setUser(null);
            setIsSuperUser(false);
        }
    };

    const updateCompany = async () => {
        try {
            if (!isSuperUser && user !== null) {
                const res = await api.me_company();
                setCompany(res.result);
            }
        } catch (e) {
            setCompany(null);
        }
    };

    const refreshToken = async (refreshAllData = false) => {
        try {
            const authed = localStorage.getItem("authed") === "true";
            if (authed) {
                const lastRefresh = parseInt(localStorage.getItem("last-refresh") || "0");
                const now = new Date().getTime();
                // 10 minutes
                if (lastRefresh < now - 1000 * 60 * 10 || refreshAllData) {
                    await api.refresh();
                    localStorage.setItem("last-refresh", new Date().getTime().toString());
                }

                if (user === null || refreshAllData) {
                    await updateUser();
                }

                if (company === null || refreshAllData) {
                    await updateCompany();
                }
            }
        } catch (e) {
            // TODO: check error status and update storage accordingly to prevent useless requests.
            setUser(null);
            setCompany(null);
            setIsSuperUser(false);
        }

        if (loading) setLoading(false);
    };

    useEffect(() => {
        // Call once to initialize.
        refreshToken();
        const interval = setInterval(() => {
            refreshToken();
        }, 5000 * 60);

        return () => {
            window.clearInterval(interval);
        };
    });

    return {
        user,
        company,
        isSuperUser,
        loading,
        login,
        logout,
        updateUser,
        updateCompany,
        refreshToken,
    };
}

export function ProvideAuth(props: { children?: React.ReactNode }) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
}
